/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SemesterTypeEnum,
    SemesterTypeEnumFromJSON,
    SemesterTypeEnumFromJSONTyped,
    SemesterTypeEnumToJSON,
} from './SemesterTypeEnum';

/**
 * 
 * @export
 * @interface SemesterSubjectStats
 */
export interface SemesterSubjectStats {
    /**
     * 
     * @type {SemesterTypeEnum}
     * @memberof SemesterSubjectStats
     */
    semester?: SemesterTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SemesterSubjectStats
     */
    fundamentalWeeklyHours?: number;
    /**
     * 
     * @type {number}
     * @memberof SemesterSubjectStats
     */
    domainWeeklyHours?: number;
    /**
     * 
     * @type {number}
     * @memberof SemesterSubjectStats
     */
    specialtyWeeklyHours?: number;
    /**
     * 
     * @type {number}
     * @memberof SemesterSubjectStats
     */
    complemetaryWeeklyHours?: number;
    /**
     * 
     * @type {number}
     * @memberof SemesterSubjectStats
     */
    courseWeeklyHours?: number;
    /**
     * 
     * @type {number}
     * @memberof SemesterSubjectStats
     */
    laboratoryWeeklyHours?: number;
    /**
     * 
     * @type {number}
     * @memberof SemesterSubjectStats
     */
    totalWeeklyHours?: number;
    /**
     * 
     * @type {number}
     * @memberof SemesterSubjectStats
     */
    examCount?: number;
    /**
     * 
     * @type {number}
     * @memberof SemesterSubjectStats
     */
    verificationsCount?: number;
}

export function SemesterSubjectStatsFromJSON(json: any): SemesterSubjectStats {
    return SemesterSubjectStatsFromJSONTyped(json, false);
}

export function SemesterSubjectStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SemesterSubjectStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'semester': !exists(json, 'semester') ? undefined : SemesterTypeEnumFromJSON(json['semester']),
        'fundamentalWeeklyHours': !exists(json, 'fundamentalWeeklyHours') ? undefined : json['fundamentalWeeklyHours'],
        'domainWeeklyHours': !exists(json, 'domainWeeklyHours') ? undefined : json['domainWeeklyHours'],
        'specialtyWeeklyHours': !exists(json, 'specialtyWeeklyHours') ? undefined : json['specialtyWeeklyHours'],
        'complemetaryWeeklyHours': !exists(json, 'complemetaryWeeklyHours') ? undefined : json['complemetaryWeeklyHours'],
        'courseWeeklyHours': !exists(json, 'courseWeeklyHours') ? undefined : json['courseWeeklyHours'],
        'laboratoryWeeklyHours': !exists(json, 'laboratoryWeeklyHours') ? undefined : json['laboratoryWeeklyHours'],
        'totalWeeklyHours': !exists(json, 'totalWeeklyHours') ? undefined : json['totalWeeklyHours'],
        'examCount': !exists(json, 'examCount') ? undefined : json['examCount'],
        'verificationsCount': !exists(json, 'verificationsCount') ? undefined : json['verificationsCount'],
    };
}

export function SemesterSubjectStatsToJSON(value?: SemesterSubjectStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'semester': SemesterTypeEnumToJSON(value.semester),
        'fundamentalWeeklyHours': value.fundamentalWeeklyHours,
        'domainWeeklyHours': value.domainWeeklyHours,
        'specialtyWeeklyHours': value.specialtyWeeklyHours,
        'complemetaryWeeklyHours': value.complemetaryWeeklyHours,
        'courseWeeklyHours': value.courseWeeklyHours,
        'laboratoryWeeklyHours': value.laboratoryWeeklyHours,
        'totalWeeklyHours': value.totalWeeklyHours,
        'examCount': value.examCount,
        'verificationsCount': value.verificationsCount,
    };
}

