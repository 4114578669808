/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccreditationTypeEnum,
    AccreditationTypeEnumFromJSON,
    AccreditationTypeEnumFromJSONTyped,
    AccreditationTypeEnumToJSON,
} from './AccreditationTypeEnum';
import {
    AdminBoardDecision,
    AdminBoardDecisionFromJSON,
    AdminBoardDecisionFromJSONTyped,
    AdminBoardDecisionToJSON,
} from './AdminBoardDecision';
import {
    Domain,
    DomainFromJSON,
    DomainFromJSONTyped,
    DomainToJSON,
} from './Domain';
import {
    ExternalEvaluationReport,
    ExternalEvaluationReportFromJSON,
    ExternalEvaluationReportFromJSONTyped,
    ExternalEvaluationReportToJSON,
} from './ExternalEvaluationReport';
import {
    Faculty,
    FacultyFromJSON,
    FacultyFromJSONTyped,
    FacultyToJSON,
} from './Faculty';
import {
    MasterCategoryEnum,
    MasterCategoryEnumFromJSON,
    MasterCategoryEnumFromJSONTyped,
    MasterCategoryEnumToJSON,
} from './MasterCategoryEnum';
import {
    SenateDecision,
    SenateDecisionFromJSON,
    SenateDecisionFromJSONTyped,
    SenateDecisionToJSON,
} from './SenateDecision';
import {
    StudyCycleEnum,
    StudyCycleEnumFromJSON,
    StudyCycleEnumFromJSONTyped,
    StudyCycleEnumToJSON,
} from './StudyCycleEnum';
import {
    StudyPlan,
    StudyPlanFromJSON,
    StudyPlanFromJSONTyped,
    StudyPlanToJSON,
} from './StudyPlan';
import {
    StudyProgramLangEnum,
    StudyProgramLangEnumFromJSON,
    StudyProgramLangEnumFromJSONTyped,
    StudyProgramLangEnumToJSON,
} from './StudyProgramLangEnum';
import {
    Subject,
    SubjectFromJSON,
    SubjectFromJSONTyped,
    SubjectToJSON,
} from './Subject';

/**
 * 
 * @export
 * @interface StudyProgram
 */
export interface StudyProgram {
    /**
     * 
     * @type {string}
     * @memberof StudyProgram
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof StudyProgram
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StudyProgram
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StudyProgram
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StudyProgram
     */
    numberECTS?: number;
    /**
     * 
     * @type {AccreditationTypeEnum}
     * @memberof StudyProgram
     */
    accreditationType?: AccreditationTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof StudyProgram
     */
    capacity?: number;
    /**
     * 
     * @type {string}
     * @memberof StudyProgram
     */
    educaionalDomainISCED?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyProgram
     */
    codCOR?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyProgram
     */
    ocupationName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyProgram
     */
    ancNoticeNumber?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StudyProgram
     */
    ancNoticeDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StudyProgram
     */
    readonly ancExpireDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StudyProgram
     */
    aracisNoticeDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StudyProgram
     */
    readonly aracisExpireDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof StudyProgram
     */
    externalEvaluationHistory?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyProgram
     */
    ancFilePath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyProgram
     */
    aracisRequestPath?: string | null;
    /**
     * 
     * @type {Array<ExternalEvaluationReport>}
     * @memberof StudyProgram
     */
    externalEvaluations?: Array<ExternalEvaluationReport> | null;
    /**
     * 
     * @type {Array<AdminBoardDecision>}
     * @memberof StudyProgram
     */
    adminBoardDecisions?: Array<AdminBoardDecision> | null;
    /**
     * 
     * @type {Array<SenateDecision>}
     * @memberof StudyProgram
     */
    senateDecisions?: Array<SenateDecision> | null;
    /**
     * 
     * @type {string}
     * @memberof StudyProgram
     */
    facultyId?: string;
    /**
     * 
     * @type {Faculty}
     * @memberof StudyProgram
     */
    faculty?: Faculty;
    /**
     * 
     * @type {string}
     * @memberof StudyProgram
     */
    domainId?: string;
    /**
     * 
     * @type {Domain}
     * @memberof StudyProgram
     */
    domain?: Domain;
    /**
     * 
     * @type {StudyCycleEnum}
     * @memberof StudyProgram
     */
    studyCycle?: StudyCycleEnum;
    /**
     * 
     * @type {string}
     * @memberof StudyProgram
     */
    programSupervisor?: string | null;
    /**
     * 
     * @type {StudyProgramLangEnum}
     * @memberof StudyProgram
     */
    language?: StudyProgramLangEnum;
    /**
     * 
     * @type {MasterCategoryEnum}
     * @memberof StudyProgram
     */
    masterCategory?: MasterCategoryEnum;
    /**
     * 
     * @type {Array<Subject>}
     * @memberof StudyProgram
     */
    subjects?: Array<Subject> | null;
    /**
     * 
     * @type {Array<StudyPlan>}
     * @memberof StudyProgram
     */
    studyPlans?: Array<StudyPlan> | null;
}

export function StudyProgramFromJSON(json: any): StudyProgram {
    return StudyProgramFromJSONTyped(json, false);
}

export function StudyProgramFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudyProgram {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'numberECTS': !exists(json, 'numberECTS') ? undefined : json['numberECTS'],
        'accreditationType': !exists(json, 'accreditationType') ? undefined : AccreditationTypeEnumFromJSON(json['accreditationType']),
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'educaionalDomainISCED': !exists(json, 'educaionalDomainISCED') ? undefined : json['educaionalDomainISCED'],
        'codCOR': !exists(json, 'codCOR') ? undefined : json['codCOR'],
        'ocupationName': !exists(json, 'ocupationName') ? undefined : json['ocupationName'],
        'ancNoticeNumber': !exists(json, 'ancNoticeNumber') ? undefined : json['ancNoticeNumber'],
        'ancNoticeDate': !exists(json, 'ancNoticeDate') ? undefined : (new Date(json['ancNoticeDate'])),
        'ancExpireDate': !exists(json, 'ancExpireDate') ? undefined : (new Date(json['ancExpireDate'])),
        'aracisNoticeDate': !exists(json, 'aracisNoticeDate') ? undefined : (new Date(json['aracisNoticeDate'])),
        'aracisExpireDate': !exists(json, 'aracisExpireDate') ? undefined : (new Date(json['aracisExpireDate'])),
        'externalEvaluationHistory': !exists(json, 'externalEvaluationHistory') ? undefined : json['externalEvaluationHistory'],
        'ancFilePath': !exists(json, 'ancFilePath') ? undefined : json['ancFilePath'],
        'aracisRequestPath': !exists(json, 'aracisRequestPath') ? undefined : json['aracisRequestPath'],
        'externalEvaluations': !exists(json, 'externalEvaluations') ? undefined : (json['externalEvaluations'] === null ? null : (json['externalEvaluations'] as Array<any>).map(ExternalEvaluationReportFromJSON)),
        'adminBoardDecisions': !exists(json, 'adminBoardDecisions') ? undefined : (json['adminBoardDecisions'] === null ? null : (json['adminBoardDecisions'] as Array<any>).map(AdminBoardDecisionFromJSON)),
        'senateDecisions': !exists(json, 'senateDecisions') ? undefined : (json['senateDecisions'] === null ? null : (json['senateDecisions'] as Array<any>).map(SenateDecisionFromJSON)),
        'facultyId': !exists(json, 'facultyId') ? undefined : json['facultyId'],
        'faculty': !exists(json, 'faculty') ? undefined : FacultyFromJSON(json['faculty']),
        'domainId': !exists(json, 'domainId') ? undefined : json['domainId'],
        'domain': !exists(json, 'domain') ? undefined : DomainFromJSON(json['domain']),
        'studyCycle': !exists(json, 'studyCycle') ? undefined : StudyCycleEnumFromJSON(json['studyCycle']),
        'programSupervisor': !exists(json, 'programSupervisor') ? undefined : json['programSupervisor'],
        'language': !exists(json, 'language') ? undefined : StudyProgramLangEnumFromJSON(json['language']),
        'masterCategory': !exists(json, 'masterCategory') ? undefined : MasterCategoryEnumFromJSON(json['masterCategory']),
        'subjects': !exists(json, 'subjects') ? undefined : (json['subjects'] === null ? null : (json['subjects'] as Array<any>).map(SubjectFromJSON)),
        'studyPlans': !exists(json, 'studyPlans') ? undefined : (json['studyPlans'] === null ? null : (json['studyPlans'] as Array<any>).map(StudyPlanFromJSON)),
    };
}

export function StudyProgramToJSON(value?: StudyProgram | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'name': value.name,
        'numberECTS': value.numberECTS,
        'accreditationType': AccreditationTypeEnumToJSON(value.accreditationType),
        'capacity': value.capacity,
        'educaionalDomainISCED': value.educaionalDomainISCED,
        'codCOR': value.codCOR,
        'ocupationName': value.ocupationName,
        'ancNoticeNumber': value.ancNoticeNumber,
        'ancNoticeDate': value.ancNoticeDate === undefined ? undefined : (value.ancNoticeDate.toISOString()),
        'aracisNoticeDate': value.aracisNoticeDate === undefined ? undefined : (value.aracisNoticeDate.toISOString()),
        'externalEvaluationHistory': value.externalEvaluationHistory,
        'ancFilePath': value.ancFilePath,
        'aracisRequestPath': value.aracisRequestPath,
        'externalEvaluations': value.externalEvaluations === undefined ? undefined : (value.externalEvaluations === null ? null : (value.externalEvaluations as Array<any>).map(ExternalEvaluationReportToJSON)),
        'adminBoardDecisions': value.adminBoardDecisions === undefined ? undefined : (value.adminBoardDecisions === null ? null : (value.adminBoardDecisions as Array<any>).map(AdminBoardDecisionToJSON)),
        'senateDecisions': value.senateDecisions === undefined ? undefined : (value.senateDecisions === null ? null : (value.senateDecisions as Array<any>).map(SenateDecisionToJSON)),
        'facultyId': value.facultyId,
        'faculty': FacultyToJSON(value.faculty),
        'domainId': value.domainId,
        'domain': DomainToJSON(value.domain),
        'studyCycle': StudyCycleEnumToJSON(value.studyCycle),
        'programSupervisor': value.programSupervisor,
        'language': StudyProgramLangEnumToJSON(value.language),
        'masterCategory': MasterCategoryEnumToJSON(value.masterCategory),
        'subjects': value.subjects === undefined ? undefined : (value.subjects === null ? null : (value.subjects as Array<any>).map(SubjectToJSON)),
        'studyPlans': value.studyPlans === undefined ? undefined : (value.studyPlans === null ? null : (value.studyPlans as Array<any>).map(StudyPlanToJSON)),
    };
}

