import { DomainApi, DomainDTO, DomainUpdateDTO, FundamentalDomainDTO, FundamentalDomainUpdateDTO, StudyCycleEnum } from "../Api";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new DomainApi(getAuthConfiguration());

export const getDomain = (id: string) => {
    if (!id) {
        return {} as DomainDTO;
    }
    return ApiFactory().apiDomainGetDomainByIdIdGet({ id });
}

export const getDomains = (namePattern?: string, facultyIds?: string[], studyCycle: StudyCycleEnum = StudyCycleEnum.All, page: number = 1, pageSize: number = 10) => {
    return ApiFactory().apiDomainGetAllDomainsGet({ page, pageSize, namePattern, facultyIds, studyCycle });
}

export const getFundamentalDomains = (namePattern?: string, page: number = 1, pageSize: number = 10) => {
    return ApiFactory().apiDomainGetAllFundamentalDomainsGet({ page, pageSize, namePattern});
}

export const addDomain = (domainUpdateDTO: DomainUpdateDTO) => {
    return ApiFactory().apiDomainAddDomainPost({ domainUpdateDTO });
}

export const updateDomain = (id: string, domainUpdateDTO: DomainUpdateDTO) => { 
    return ApiFactory().apiDomainUpdateDomainIdPut({ id, domainUpdateDTO });
}

export const deleteDomain = (id: string) => {
    return ApiFactory().apiDomainDeleteIdDelete({ id });
}

export const getFundamentalDomain = (id: string) => {
    if (!id) {
        return {} as FundamentalDomainDTO;
    }
    return ApiFactory().apiDomainGetFundamentalDomainByIdIdGet({ id });
}

export const addFundamentalDomain = (fundamentalDomainUpdateDTO: FundamentalDomainUpdateDTO) => {
    return ApiFactory().apiDomainAddFundamentalDomainPost({ fundamentalDomainUpdateDTO });
}

export const updateFundamentalDomain = (id: string, fundamentalDomainUpdateDTO: FundamentalDomainUpdateDTO) => { 
    return ApiFactory().apiDomainUpdateFundamentalDomainIdPut({ id, fundamentalDomainUpdateDTO });
}

export const deleteFundamentalDomain = (id: string) => {
    return ApiFactory().apiDomainDeleteFundamentalDomainIdDelete({ id });
}