import { Select } from "antd";
import { StudyYearEnum } from "../../Api/models/StudyYearEnum";
import { SemesterTypeEnum, StudyPlanDTO } from "../../Api";

const { Option } = Select;


export const getSchoolYear = (currentDate: Date): number => {
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    if (currentMonth < 9) {
        return currentYear - 1;
    } else {
        return currentYear;
    }
};

export const getSchoolYearRange = (yearsBack: number): number[] => {
    const currentDate = new Date();
    const nextSchoolYear = getSchoolYear(currentDate);

    return Array.from({ length: yearsBack }, (_, index) => nextSchoolYear - index);
};


export const planOptions = (plans: StudyPlanDTO[]) => getSchoolYearRange(1).map(key => (
    <Option key={key} value={key.toString()}>{'Plan curent'}</Option>
)).concat(plans.map(plan => (
    <Option key={'cadru ' + plan.planYear} value={'cadru ' + plan.planYear?.toString()}>{'Plan cadru ' + plan.planYear + '-' + (plan.planYear! + 4)}</Option>
)
));

export const getStudyYearOptions = (studyCycle: string) => {
    const filteredKeys = Object.keys(StudyYearEnum).filter(key => {
        if (studyCycle === 'Master') {
            return key === 'M1' || key === 'M2';
        }
        return key === 'L1' || key === 'L2' || key === 'L3' || key === 'L4';
    });

    return filteredKeys.map(key => (
        <Option key={key} value={key}>
            {StudyYearEnum[key as keyof typeof StudyYearEnum]} {/* Use enum value */}
        </Option>
    ));
};

export const semesterOptions = Object.keys(SemesterTypeEnum).filter(key => key !== 'All').map(key => (
    <Option key={key} value={key}>{key}</Option>
));