/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Domain,
    DomainFromJSON,
    DomainFromJSONTyped,
    DomainToJSON,
} from './Domain';
import {
    Indicator,
    IndicatorFromJSON,
    IndicatorFromJSONTyped,
    IndicatorToJSON,
} from './Indicator';

/**
 * 
 * @export
 * @interface FundamentalDomain
 */
export interface FundamentalDomain {
    /**
     * 
     * @type {string}
     * @memberof FundamentalDomain
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof FundamentalDomain
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FundamentalDomain
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof FundamentalDomain
     */
    name?: string | null;
    /**
     * 
     * @type {Array<Domain>}
     * @memberof FundamentalDomain
     */
    domains?: Array<Domain> | null;
    /**
     * 
     * @type {Array<Indicator>}
     * @memberof FundamentalDomain
     */
    indicators?: Array<Indicator> | null;
}

export function FundamentalDomainFromJSON(json: any): FundamentalDomain {
    return FundamentalDomainFromJSONTyped(json, false);
}

export function FundamentalDomainFromJSONTyped(json: any, ignoreDiscriminator: boolean): FundamentalDomain {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'domains': !exists(json, 'domains') ? undefined : (json['domains'] === null ? null : (json['domains'] as Array<any>).map(DomainFromJSON)),
        'indicators': !exists(json, 'indicators') ? undefined : (json['indicators'] === null ? null : (json['indicators'] as Array<any>).map(IndicatorFromJSON)),
    };
}

export function FundamentalDomainToJSON(value?: FundamentalDomain | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'name': value.name,
        'domains': value.domains === undefined ? undefined : (value.domains === null ? null : (value.domains as Array<any>).map(DomainToJSON)),
        'indicators': value.indicators === undefined ? undefined : (value.indicators === null ? null : (value.indicators as Array<any>).map(IndicatorToJSON)),
    };
}

