/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SemesterTypeEnum {
    S1 = 'S1',
    S2 = 'S2',
    All = 'All'
}

export function SemesterTypeEnumFromJSON(json: any): SemesterTypeEnum {
    return SemesterTypeEnumFromJSONTyped(json, false);
}

export function SemesterTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SemesterTypeEnum {
    return json as SemesterTypeEnum;
}

export function SemesterTypeEnumToJSON(value?: SemesterTypeEnum | null): any {
    return value as any;
}

