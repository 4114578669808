/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum GradingTypeEnum {
    Grade = 'Grade',
    AdmRej = 'AdmRej'
}

export function GradingTypeEnumFromJSON(json: any): GradingTypeEnum {
    return GradingTypeEnumFromJSONTyped(json, false);
}

export function GradingTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): GradingTypeEnum {
    return json as GradingTypeEnum;
}

export function GradingTypeEnumToJSON(value?: GradingTypeEnum | null): any {
    return value as any;
}

