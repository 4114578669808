import { StudyPlanApi } from "../Api";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new StudyPlanApi(getAuthConfiguration());

export const getStudyPlanByProgramIdAndYear = (studyProgramId: string, planYear: number) => {
    return ApiFactory().apiStudyPlanGetStudyPlanByProgramIdAndYearStudyProgramIdPlanYearGet({ studyProgramId, planYear });
}

export const getStudyPlanById = (studyPlanId: string) => {
    return ApiFactory().apiStudyPlanGetStudyPlanByIdStudyPlanIdGet({ studyPlanId });
}

export const getAllStudyPlans = (studyProgramId: string, page: number, pageSize: number) => {
    return ApiFactory().apiStudyPlanGetAllStudyPlansStudyProgramIdGet({ studyProgramId, page, pageSize });
}

export const updateStudyPlanDepartment = (studyPlanId: string, departmentId: string) => {
    return ApiFactory().apiStudyPlanUpdateStudyPlanDepartmentStudyPlanIdDepartmentIdPut({ studyPlanId, departmentId });
}