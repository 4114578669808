import { Button, Form, Input, Row, Col } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { InternshipCompetencesFormProps, InternshipCompetencesFormProps1 } from "./SubjectContentForm.types";
import i18n from "../../../i18n";

const { List, Item } = Form;

const SubjectLaboratoryContentForm = (props: InternshipCompetencesFormProps) => {
	const { t } = i18n;

	return (
		<List
			initialValue={props.initialValue}
			name={props.name}
			rules={props.rules}
		>
			{(fields, { add, remove }) => (
				<div className="flex flex-col pb-5">
					<div style={{display: "flex"}}>
						<label className="pb-5" style={{width: "35%", textAlign: "left"}}>
							<span style={{ color: "#ff4d4f" }}>*&nbsp;</span>
							<b>Criterii de evaluare</b>
						</label>
						<label className="pb-5" style={{width: "35%", textAlign: "left"}}>
							<span style={{ color: "#ff4d4f" }}>*&nbsp;</span>
							<b>Metode de evaluare</b>
						</label>
						<label className="pb-5" style={{width: "35%", textAlign: "left"}}>
							<span style={{ color: "#ff4d4f" }}>*&nbsp;</span>
							<b>Pondere din nota finală</b>
						</label>
					</div> 
					

					<div className="flex flex-col">
						{fields.map(({ key, name, ...restField }) => (
							<div className="flex items-start gap-2 border-b border-black" style={{marginBottom: "10px"}}  key={key}>
								<div className="flex gap-x-2 flex-wrap" style={{display: "flex"}}>
									<Item
										{...restField}
										name={[name, "evaluationCriteria"]}
										initialValue=""
										style={{width: "35%", marginBottom: "5px"}}
										rules={[]}
									>
										<Input
											disabled={props.disabled ?? false}
											placeholder={"Criteriu evaluare"}
										/>
									</Item>

									<Item
										{...restField}
										name={[name, "evaluationMethod"]}
										initialValue=""
										style={{width: "35%", marginBottom: "5px"}}
										rules={[]}
									>
										<Input
											disabled={props.disabled ?? false}
											placeholder={"Metoda evaluare"}
										/>
									</Item>

									<Item
										{...restField}
										name={[name, "evaluationPercent"]}
										initialValue=""
										style={{width: "35%", marginBottom: "5px"}}
										rules={[]}
									>
										<Input
											disabled={props.disabled ?? false}
											placeholder={"Procent"}
										/>
									</Item>

								</div>

								{fields.length > 1 && !props.disabled && (
									<MinusCircleOutlined
										className="mt-2"
										onClick={() => remove(name)}
									/>
								)}
							</div>
						))}
					</div>

					{!props.disabled && (
						<div className="">
							<Button
								type="dashed"
								onClick={() => add()}
								icon={<PlusOutlined />}
							>
								Adauga evaluare
							</Button>
						</div>
					)}
				</div>
			)}
		</List>
	);
};

export default SubjectLaboratoryContentForm;
