import { useState } from "react";
import styles from "./Indicators.module.scss"; // Ensure you have styles defined here
import { Table } from "antd";
import Layout from "../../Containers/Layout";
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useIsAdmin } from "../../utils/utilFunctions";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import { deleteIndicator, getIndicatorsStats } from "../../Requests/indicator-requests";
import { IndicatorTypeEnum, IndicatorValueTypeEnum, Stats } from "../../Api";

const IndicatorsStats = () => {
    const { t } = useTranslation();
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const history = useHistory();
    const isAdmin = useIsAdmin();

    const { studyProgramId, planYear } = useParams<{ studyProgramId: string, planYear: string }>();

    const [modalPropsDelete, setModalPropsDelete] = useState({
        isModalVisible: false,
        title: '',
        id: '',
    });

    const [modalProps, setModalProps] = useState<{ isModalVisible: boolean, title: string, data: string[] }>( {
        isModalVisible: false,
        title: '',
        data: []
    });

    const handleShowDelete = (id: string) => {
        setModalPropsDelete(modalProps => ({ ...modalProps, isModalVisible: true, id }));
    }

    const handleCloseDelete = () => {
        setModalPropsDelete(modalProps => ({ ...modalProps, isModalVisible: false }));
    }

    const handleOkDelete = () => {
        setModalPropsDelete(modalProps => ({ ...modalProps, isModalVisible: false }));
        deleteIndicator(modalPropsDelete.id)
            .then(() => {
                deleteSucceeded();
                invalidateQuery();
            })
            .catch(deleteFailed);
    }

    const deleteSucceeded = () => {
        openNotification(
            'Success',
            'Deletion was successful!',
            NOTIFICATION_TYPES.SUCCESS
        );
    };

    const deleteFailed = (_err: any) => {
        openNotification(
            'Error',
            'Deletion was not successful!',
            NOTIFICATION_TYPES.ERROR
        );
    };

    const queryClient = useQueryClient();

    const invalidateQuery = () => { queryClient.invalidateQueries('getIndicators') };

    const openFetchError = (_error: any) => {
        openNotification(
            'Error!',
            'Data could not be fetched from the server!',
            NOTIFICATION_TYPES.ERROR
        );
    }

    const { data, isLoading } = useQuery(['getIndicatorsStats', studyProgramId, planYear],
        () => {
            const response = getIndicatorsStats(studyProgramId, Number(planYear));
            return response;
        },
        {
            onError: openFetchError,
        }
    );


    const transformData = (data: Stats | undefined) => {
        return data?.yearSubjectStats?.map((yearStat, index) => ({
            key: index + 1,
            studyYear: yearStat.studyYear,
            fundamentalWeeklyHoursS1: yearStat.s1?.fundamentalWeeklyHours,
            domainWeeklyHoursS1: yearStat.s1?.domainWeeklyHours,
            specialtyWeeklyHoursS1: yearStat.s1?.specialtyWeeklyHours,
            complemetaryWeeklyHoursS1: yearStat.s1?.complemetaryWeeklyHours,
            courseWeeklyHoursS1: yearStat.s1?.courseWeeklyHours,
            laboratoryWeeklyHoursS1: yearStat.s1?.laboratoryWeeklyHours,
            totalWeeklyHoursS1: yearStat.s1?.totalWeeklyHours,
            examCountS1: yearStat.s1?.examCount,
            verificationsCountS1: yearStat.s1?.verificationsCount,
            fundamentalWeeklyHoursS2: yearStat.s2?.fundamentalWeeklyHours,
            domainWeeklyHoursS2: yearStat.s2?.domainWeeklyHours,
            specialtyWeeklyHoursS2: yearStat.s2?.specialtyWeeklyHours,
            complemetaryWeeklyHoursS2: yearStat.s2?.complemetaryWeeklyHours,
            courseWeeklyHoursS2: yearStat.s2?.courseWeeklyHours,
            laboratoryWeeklyHoursS2: yearStat.s2?.laboratoryWeeklyHours,
            totalWeeklyHoursS2: yearStat.s2?.totalWeeklyHours,
            examCountS2: yearStat.s2?.examCount,
            verificationsCountS2: yearStat.s2?.verificationsCount,
            totalFundamentalWeeklyHours: yearStat.total?.fundamentalWeeklyHours,
            totalDomainWeeklyHours: yearStat.total?.domainWeeklyHours,
            totalSpecialtyWeeklyHours: yearStat.total?.specialtyWeeklyHours,
            totalComplemetaryWeeklyHours: yearStat.total?.complemetaryWeeklyHours,
            totalCourseWeeklyHours: yearStat.total?.courseWeeklyHours,
            totalLaboratoryWeeklyHours: yearStat.total?.laboratoryWeeklyHours,
            totalWeeklyHours: yearStat.total?.totalWeeklyHours,
            totalExamCount: yearStat.total?.examCount,
            totalVerificationsCount: yearStat.total?.verificationsCount,
            totalSubjects: yearStat.totalSubjects,
        }));
    };


    const transformIndicatorData = (data: Stats | undefined) => {
        if (!data?.indicatorStats) return [];
    
       
        const totalWeeklyHours = data.indicatorStats.reduce((sum, indicator) => sum + (indicator.weeklyHours || 0), 0);
        const totalHours = data.indicatorStats.reduce((sum, indicator) => sum + (indicator.hours || 0), 0);
        const totalPercentage = data.indicatorStats.reduce((sum, indicator) => sum + (indicator.indicatorValue || 0), 0) / data.indicatorStats.length;
    
        const transformedData = data.indicatorStats.map((indicatorStat, index) => ({
            key: index + 1,
            indicatorType: indicatorStat.indicatorType,
            weeklyHours: indicatorStat.weeklyHours,
            hours: indicatorStat.hours,
            percentage: indicatorStat.indicatorValue?.toFixed(2),
            criteria: indicatorStat.indicatorValueType === IndicatorValueTypeEnum.Lower ? "<" + indicatorStat.indicatorCriteria : ">" + indicatorStat.indicatorCriteria,
            boxColor: indicatorStat.indicatorValueType === IndicatorValueTypeEnum.Lower ? indicatorStat.indicatorValue! <= indicatorStat?.indicatorCriteria! ? "#D1E7DD" : "#F8D7DA" 
                                                                                        : indicatorStat.indicatorValue! >= indicatorStat?.indicatorCriteria! ? "#D1E7DD" : "#F8D7DA"
        }));
    
        
        transformedData.push({
            key: transformedData.length + 1,
            indicatorType: undefined,
            weeklyHours: totalWeeklyHours,
            hours: totalHours,
            percentage: totalPercentage.toFixed(2),
            criteria: '',
            boxColor: "transparent"
        });
    
        return transformedData;
    };

    const transformGeneralData = (data: Stats | undefined) => {
        if (!data?.generalStats) return [];
        const generalStats = data.generalStats;
        return [
            { key: 1, label: "Raport Examene / TOTAL Discipline", value: generalStats.examPercentage?.toFixed(2) },
            { key: 2, label: "Raport Examene / Colocvii", value: generalStats.examVerificationRatio?.toFixed(2) },
            { key: 3, label: "Media de ore pe semestru", value: generalStats.averageSemesterWeeklyHours?.toFixed(2) },
            { key: 4, label: "Numărul de discipline cu proiect", value: generalStats.projectSubjectsCount },
            { key: 5, label: "Numărul de discipline cu laborator", value: generalStats.labSubjectsCount },
            // { key: 6, label: "CURS / Aplicatii cu practica (240 ore)", value: "103.44%" },
            // { key: 7, label: "CURS / Aplicatii fără practica (240 ore)", value: "122.11%" },
            { key: 8, label: "Total ore CURS", value: generalStats.totalCourseHours },
            { key: 9, label: "Total ore Seminar-Laborator-Proiect", value: generalStats.totalSeminarLabProjectHours },
            { key: 10, label: "Total ore Seminar-Laborator-Proiect + Practică", value: generalStats.totalSeminarLabProjectPracticeHours },
            { key: 11, label: "Medie ore/săptămână", value: generalStats.averageSemesterWeeklyHours?.toFixed(2) },
            { key: 12, label: "Examene", value: generalStats.examsCount },
            { key: 13, label: "Colocvii", value: generalStats.verificationCount },
        ];
    };

    const transformDisciplineData = (data: Stats | undefined) => {
        if (!data?.disciplineStats) return [];

        const projectStats = data.disciplineStats.find(stat => stat.type === "Project");
        const labStats = data.disciplineStats.find(stat => stat.type === "Laboratory");
        const optionalStats = data.disciplineStats.find(stat => stat.type === "Optional");
        const facultativeStats = data.disciplineStats.find(stat => stat.type === "Facultative");

        return [
            {
                key: 1,
                label: "Număr Discipline cu PROIECT",
                firstYear: projectStats?.firstYearCount || 0,
                secondYear: projectStats?.secondYearCount || 0,
                thirdYear: projectStats?.thirdYearCount || 0,
                fourthYear: projectStats?.fourthYearCount || 0,
            },
            {
                key: 2,
                label: "Număr discipline cu LABORATOR",
                firstYear: labStats?.firstYearCount || 0,
                secondYear: labStats?.secondYearCount || 0,
                thirdYear: labStats?.thirdYearCount || 0,
                fourthYear: labStats?.fourthYearCount || 0,
            },
            {
                key: 3,
                label: "Ore Discipline OPȚIONALE",
                firstYear: optionalStats?.firstYearCount || 0,
                secondYear: optionalStats?.secondYearCount || 0,
                thirdYear: optionalStats?.thirdYearCount || 0,
                fourthYear: optionalStats?.fourthYearCount || 0,
            },
            {
                key: 4,
                label: "Ore Discipline FACULTATIVE",
                firstYear: facultativeStats?.firstYearCount || 0,
                secondYear: facultativeStats?.secondYearCount || 0,
                thirdYear: facultativeStats?.thirdYearCount || 0,
                fourthYear: facultativeStats?.fourthYearCount || 0,
            },
        ];
    };

    const getTotalSubjects = (data: Stats | undefined) => {
        if (!data) return 0;
        return data.yearSubjectStats?.reduce((sum, yearStat) => {
            return sum + (yearStat?.totalSubjects ?? 0);
        }, 0).toString();
    };

    const columns = [
        {
            title: '',
            children: [
                {
                    title: '',
                    dataIndex: 'studyYear',
                    key: 'studyYear',
                    rowSpan: 2,
                    colSpan: 1,
                    render: (text: string, record: any, index: number) =>
                        {
                            return record.studyYear === "L1" ? "Anul 1" : record.studyYear === "L2" ? "Anul 2" : record.studyYear === "L3" ? "Anul 3" : "Anul 4"
                        }
                },
            ],
        },
        {
            title: 'Sem. 1',
            colSpan: 9,
            children: [
                { title: 'F', dataIndex: 'fundamentalWeeklyHoursS1', key: 'fundamentalWeeklyHoursS1' },
                { title: 'D', dataIndex: 'domainWeeklyHoursS1', key: 'domainWeeklyHoursS1' },
                { title: 'S', dataIndex: 'specialtyWeeklyHoursS1', key: 'specialtyWeeklyHoursS1' },
                { title: 'C', dataIndex: 'complemetaryWeeklyHoursS1', key: 'complemetaryWeeklyHoursS1' },
                { title: 'Curs', dataIndex: 'courseWeeklyHoursS1', key: 'courseWeeklyHoursS1' },
                { title: 'Aplic.', dataIndex: 'laboratoryWeeklyHoursS1', key: 'laboratoryWeeklyHoursS1' },
                { title: 'Tot.', dataIndex: 'totalWeeklyHoursS1', key: 'totalWeeklyHoursS1' },
                { title: 'Ex', dataIndex: 'examCountS1', key: 'examCountS1' },
                { title: 'Col', dataIndex: 'verificationsCountS1', key: 'verificationsCountS1' },
            ],
        },
        {
            title: 'Sem. 2',
            colSpan: 9,
            children: [
                { title: 'F', dataIndex: 'fundamentalWeeklyHoursS2', key: 'fundamentalWeeklyHoursS2' },
                { title: 'D', dataIndex: 'domainWeeklyHoursS2', key: 'domainWeeklyHoursS2' },
                { title: 'S', dataIndex: 'specialtyWeeklyHoursS2', key: 'specialtyWeeklyHoursS2' },
                { title: 'C', dataIndex: 'complemetaryWeeklyHoursS2', key: 'complemetaryWeeklyHoursS2' },
                { title: 'Curs', dataIndex: 'courseWeeklyHoursS2', key: 'courseWeeklyHoursS2' },
                { title: 'Aplic.', dataIndex: 'laboratoryWeeklyHoursS2', key: 'laboratoryWeeklyHoursS2' },
                { title: 'Tot.', dataIndex: 'totalWeeklyHoursS2', key: 'totalWeeklyHoursS2' },
                { title: 'Ex', dataIndex: 'examCountS2', key: 'examCountS2' },
                { title: 'Col', dataIndex: 'verificationsCountS2', key: 'verificationsCountS2' },
            ],
        },
        {
            title: 'Total',
            colSpan: 9,
            children: [
                { title: 'F', dataIndex: 'totalFundamentalWeeklyHours', key: 'totalFundamentalWeeklyHours' },
                { title: 'D', dataIndex: 'totalDomainWeeklyHours', key: 'totalDomainWeeklyHours' },
                { title: 'S', dataIndex: 'totalSpecialtyWeeklyHours', key: 'totalSpecialtyWeeklyHours' },
                { title: 'C', dataIndex: 'totalComplemetaryWeeklyHours', key: 'totalComplemetaryWeeklyHours' },
                { title: 'Curs', dataIndex: 'totalCourseWeeklyHours', key: 'totalCourseWeeklyHours' },
                { title: 'Aplic.', dataIndex: 'totalLaboratoryWeeklyHours', key: 'totalLaboratoryWeeklyHours' },
                { title: 'Tot.', dataIndex: 'totalWeeklyHours', key: 'totalWeeklyHours' },
                { title: 'Ex', dataIndex: 'totalExamCount', key: 'totalExamCount' },
                { title: 'Col', dataIndex: 'totalVerificationsCount', key: 'totalVerificationsCount' },
            ],
        },
        {
            title: 'Nr. discipline pe an studii',
            colSpan: 1,
            children: [
                { title: getTotalSubjects(data), dataIndex: 'totalSubjects', key: 'totalSubjects' },
            ],
        },
    ];


    const indicatorColumns = [
        { 
            title: "Tip discipline", 
            dataIndex: "indicatorType", 
            key: "indicatorType",
            render: (text: string, record: any, index: number) => {
                switch (record.indicatorType) {
                    case IndicatorTypeEnum.Fundamental:
                        return 'Discipline Fundamentale';
                    case IndicatorTypeEnum.Domain:
                        return 'Discipline de Domeniu';
                    case IndicatorTypeEnum.Specialty:
                        return 'Discipline de Specialitate';
                    case IndicatorTypeEnum.Complementary:
                        return 'Discipline Complementare';
                    case IndicatorTypeEnum.Optional:
                        return 'Discipline Opționale';
                    case IndicatorTypeEnum.Mandatory:
                        return 'Discipline Obligatorii';
                    case IndicatorTypeEnum.Facultative:
                        return 'Discipline Facultative';
                    case undefined:
                        return "Total"
                }
            }
        },
        { title: "Nr. ore/săpt.", dataIndex: "weeklyHours", key: "weeklyHours" },
        { title: "Nr. ore", dataIndex: "hours", key: "hours" },
        { 
            title: "Procent", 
            dataIndex: "percentage", 
            key: "percentage",
            styles: {},
            render: (text: string, record: any, index: number) =>
                {
                    return {
                      props: {
                        style: { background: record.boxColor }
                      },
                      children: <div>{record.percentage}%</div>
                    };
                  }
        },
        { title: "Criteriu", dataIndex: "criteria", key: "criteria" },
    ];


    const generalColumns = [
        { title: "", dataIndex: "label", key: "label" },
        { title: "", dataIndex: "value", key: "value" },
    ];


    


    const disciplineColumns = [
        { title: "", dataIndex: "label", key: "label" },
        { title: "I", dataIndex: "firstYear", key: "firstYear" },
        { title: "II", dataIndex: "secondYear", key: "secondYear" },
        { title: "III", dataIndex: "thirdYear", key: "thirdYear" },
        { title: "IV", dataIndex: "fourthYear", key: "fourthYear" },
    ];

    return (
        <Layout>
            <div className={styles.tableContainer}>
                <Table
                    columns={columns}
                    dataSource={isLoading ? [] : transformData(data!)}
                    loading={isLoading}
                    pagination={false}
                    bordered
                />
                <br/>
                <Table
                    columns={indicatorColumns}
                    dataSource={isLoading ? [] : transformIndicatorData(data!)}
                    loading={isLoading}
                    pagination={false}
                    bordered
                />
                <br/>
                <Table
                    columns={generalColumns}
                    dataSource={isLoading ? [] : transformGeneralData(data!)}
                    loading={isLoading}
                    pagination={false}
                    bordered
                />
                <br/>
                <Table
                    columns={disciplineColumns}
                    dataSource={isLoading ? [] : transformDisciplineData(data)}
                    loading={isLoading}
                    pagination={false}
                    bordered
                />
            </div>
        </Layout>
    );
};

export default IndicatorsStats;