/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StatTypeEnum,
    StatTypeEnumFromJSON,
    StatTypeEnumFromJSONTyped,
    StatTypeEnumToJSON,
} from './StatTypeEnum';

/**
 * 
 * @export
 * @interface DisciplineStats
 */
export interface DisciplineStats {
    /**
     * 
     * @type {StatTypeEnum}
     * @memberof DisciplineStats
     */
    type?: StatTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DisciplineStats
     */
    firstYearCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DisciplineStats
     */
    secondYearCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DisciplineStats
     */
    thirdYearCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DisciplineStats
     */
    fourthYearCount?: number;
}

export function DisciplineStatsFromJSON(json: any): DisciplineStats {
    return DisciplineStatsFromJSONTyped(json, false);
}

export function DisciplineStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisciplineStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : StatTypeEnumFromJSON(json['type']),
        'firstYearCount': !exists(json, 'firstYearCount') ? undefined : json['firstYearCount'],
        'secondYearCount': !exists(json, 'secondYearCount') ? undefined : json['secondYearCount'],
        'thirdYearCount': !exists(json, 'thirdYearCount') ? undefined : json['thirdYearCount'],
        'fourthYearCount': !exists(json, 'fourthYearCount') ? undefined : json['fourthYearCount'],
    };
}

export function DisciplineStatsToJSON(value?: DisciplineStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': StatTypeEnumToJSON(value.type),
        'firstYearCount': value.firstYearCount,
        'secondYearCount': value.secondYearCount,
        'thirdYearCount': value.thirdYearCount,
        'fourthYearCount': value.fourthYearCount,
    };
}

