/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum StudyYearEnum {
    L1 = 'L1',
    L2 = 'L2',
    L3 = 'L3',
    L4 = 'L4',
    M1 = 'M1',
    M2 = 'M2'
}

export function StudyYearEnumFromJSON(json: any): StudyYearEnum {
    return StudyYearEnumFromJSONTyped(json, false);
}

export function StudyYearEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudyYearEnum {
    return json as StudyYearEnum;
}

export function StudyYearEnumToJSON(value?: StudyYearEnum | null): any {
    return value as any;
}

