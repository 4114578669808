import moment from "moment";
import { useQuery } from "react-query";
import { Role, SimpleViewAcademicUserDTO } from "../Api";
import { getRolesForUser } from "../Requests/academic-user-requests";
import { getSubject } from "../Requests/subject-requests";

const jwt = require('jsonwebtoken');

export function getUserId(): any {
    return jwt.decode(localStorage.getItem('token'))?.sub;
}

export function getTokenExpire(): any {
    return jwt.decode(localStorage.getItem('token'))?.exp;
}

export function isUserLogged(): boolean {
    return localStorage.getItem('token') != null;
}

export function logout(isAcademic: boolean): void {
    localStorage.clear();

    // if (isAcademic) {
    //     window.location.href = `${process.env.REACT_APP_AUTH_API_URL}/auth?clientAction=logout&clientName=https://${process.env.REACT_APP_DOMAIN}/`;
    // }
};

export const capitalizeFirst = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export default function useRoles() {
    return useQuery(["getRoles"], () => isUserLogged() ? getRolesForUser() : [], { refetchOnWindowFocus: false });
};

export const useIsAdmin = () => {
    return useRoles().data?.includes(Role.Admin);
};

export const useIsDean = () => {
    return useRoles().data?.includes(Role.Dean);
};

export const useIsDepartmentDirector = () => {
    return useRoles().data?.includes(Role.DepartmentDirector);
};

export const useIsTitular = (subjectId: string) => {
    let subject = useQuery(["getSubject", subjectId], () => isUserLogged() ? getSubject(subjectId) : undefined, { refetchOnWindowFocus: false });
    return subject.data?.courseCoordinators?.find(e => e.id === getUserId());
};

export const getKey = (record: SimpleViewAcademicUserDTO) => {
    return record.id!;
}

export const getAllKeys = (filterItems: any[]) => {
    const keys: any[] = [];

    filterItems.forEach((filter) => {
        keys.push(filter.key);

        filter.children.forEach((filterItem: any) => {
            keys.push(filterItem.key.split("@")[1]);
        });
    });

    return keys;
};

export function geti18nLanguage(): any {
    return localStorage.getItem('i18nextLng');
}

export const addMonths = (date: Date, months: number): moment.Moment => {
    const newDate = moment(date);
    newDate.add(months, "months");

    return newDate;
};