/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LaboratoryTypeEnum {
    Laboratory = 'Laboratory',
    Curs = 'Curs',
    Seminar = 'Seminar'
}

export function LaboratoryTypeEnumFromJSON(json: any): LaboratoryTypeEnum {
    return LaboratoryTypeEnumFromJSONTyped(json, false);
}

export function LaboratoryTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaboratoryTypeEnum {
    return json as LaboratoryTypeEnum;
}

export function LaboratoryTypeEnumToJSON(value?: LaboratoryTypeEnum | null): any {
    return value as any;
}

