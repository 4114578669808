import { useState } from "react";
import styles from "./Laboratories.module.scss";
import { Button, Modal, Select, Table, Tooltip, Typography } from "antd";
import Layout from "../../Containers/Layout";
import Search from 'antd/lib/input/Search';
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlusOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { theme } from "../../theme";
import { useIsAdmin } from "../../utils/utilFunctions";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import { deleteLaboratory, getLaboratories } from "../../Requests/laboratory-requests";
import { LaboratoryDTO, LaboratoryCategoryEnum, LaboratoryTypeEnum } from "../../Api";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const { Option } = Select;

const Laboratories = () => {
    const { t } = useTranslation();
    const [searchTermNumber, setSearchTermNumber] = useState('');
    const [searchTermName, setSearchTermName] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const history = useHistory();
    const isAdmin = useIsAdmin();

    const [modalPropsDelete, setModalPropsDelete] = useState({
        isModalVisible: false,
        title: '',
        id: '',
    });

    const [modalProps, setModalProps] = useState<{ isModalVisible: boolean, title: string, data: string[] }>({
        isModalVisible: false,
        title: '',
        data: []
    });

    const handleShowDelete = (id: string) => {
        setModalPropsDelete(modalProps => ({ ...modalProps, isModalVisible: true, id }));
    }

    const handleCloseDelete = () => {
        setModalPropsDelete(modalProps => ({ ...modalProps, isModalVisible: false }));
    }

    const handleOkDelete = () => {
        setModalPropsDelete(modalProps => ({ ...modalProps, isModalVisible: false }));
        deleteLaboratory(modalPropsDelete.id)
            .then(() => {
                deleteSucceeded();
                invalidateQuery();
            })
            .catch(deleteFailed);
    }

    const deleteSucceeded = () => {
        openNotification(
            'Succes',
            'Ștergerea a fost efectuată cu succes!',
            NOTIFICATION_TYPES.SUCCESS
        );
    };

    const deleteFailed = (_err: any) => {
        openNotification(
            'Eroare',
            'Ștergerea nu a fost efectuată!',
            NOTIFICATION_TYPES.ERROR
        );
    };

    const queryClient = useQueryClient();

    const invalidateQuery = () => { queryClient.invalidateQueries('getLaboratories') };

    const openFetchError = (_error: any) => {
        openNotification(
            'Eroare!',
            'Datele nu au putut fi preluate de la server!',
            NOTIFICATION_TYPES.ERROR
        );
    }

    const { data, isLoading } = useQuery(['getLaboratories', searchTermNumber, searchTermName, pageSize, currentPage],
        () => {
            const response = getLaboratories(undefined, searchTermName, searchTermNumber, currentPage, pageSize);
            return response;
        },
        {
            onError: openFetchError,
        }
    );

    const handleClose = () => {
        setModalProps(modalProps => ({...modalProps, isModalVisible: false}));
    }

    const handleOk = () => {
        setModalProps(modalProps => ({...modalProps, isModalVisible: false}));

    }

    const FilterByNumberInput = (
        <Search placeholder={t('laboratory.number')} allowClear onSearch={setSearchTermNumber} style={{ width: '100%' }} />
    );

    const FilterByNameInput = (
        <Search placeholder={t('laboratory.name')} allowClear onSearch={setSearchTermName} style={{ width: '100%' }} />
    );

    const handleShowDescription = (record: LaboratoryDTO) => {
        setModalProps(modalProps => ({...modalProps, isModalVisible: true, title: "Descrierea laboratorului",
            data: record.description && record.description.length > 0 ? record.description!.split("\n") : ["Nu exista descriere!"]}));
    }

    const handleShowResources = (record: LaboratoryDTO) => {
        setModalProps(modalProps => ({...modalProps, isModalVisible: true, title: "Resursele laboratorului",
            data: record.resources && record.resources.length > 0 ? record.resources!.split("\n") : ["Nu exista resurse definite!"]}));
    }

    const columns = [
        {
            title: FilterByNumberInput,
            dataIndex: 'number',
        },
        {
            title: FilterByNameInput,
            dataIndex: 'name',
        },
        {
            title: 'Suprafața',
            dataIndex: 'area',
        },
        {
            title: 'Volum',
            dataIndex: 'volume',
        },
        {
            title: 'Capacitate',
            dataIndex: 'capacity',
        },
        {
            title: 'Categorie',
            dataIndex: 'category',
            render: (text: string, record: any, index: number) =>
            {
                return record.category === LaboratoryCategoryEnum.Technological ? "Tehnologic" : "Informatic"
            }
        },
        {
            title: 'Tip',
            dataIndex: 'type',
            render: (text: string, record: any, index: number) =>
                {
                    return record.type === LaboratoryTypeEnum.Laboratory ? "Laborator" : 
                            record.type === LaboratoryTypeEnum.Curs ? "Curs" : "Seminar"
                }
        },
        {
            title: 'Descriere',
            width: '100px',
            render: (text: string, record: any, index: number) =>
                <div>
                    <Tooltip title='Vizualizare'>
                        <FontAwesomeIcon icon={solid('eye')} style={{ marginLeft: '20px', cursor: 'pointer', height: "1rem", padding: "6px 0px" }}
                        onClick={() => handleShowDescription(record)}/>
                    </Tooltip>
                </div>
            
        },
        {
            title: 'Resurse',
            width: '100px',
            render: (text: string, record: any, index: number) =>
                <div>
                    <Tooltip title='Resurse'>
                        <FontAwesomeIcon icon={solid('eye')} style={{ marginLeft: '20px', cursor: 'pointer', height: "1rem", padding: "6px 0px" }}
                        onClick={() => handleShowResources(record)}/>
                    </Tooltip>
                </div>
        },
        {
            title: 'Acțiuni',
            width: '100px',
            render: (text: string, record: any, index: number) =>
                <div>
                    <Link to={"/editare-laborator/" + record.id}><Tooltip title='Editare'>
                        <FontAwesomeIcon icon={solid('edit')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }} />
                    </Tooltip></Link>
                    <Tooltip title='Ștergere'>
                        <FontAwesomeIcon icon={solid('trash')} style={{ marginLeft: '20px', cursor: 'pointer', height: "1rem", padding: "6px 0px" }}
                            onClick={() => handleShowDelete(record.id)} />
                    </Tooltip>
                </div>
        }
    ];

    return (
        <div className={styles.container} style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}>
            <Layout>
                <Modal
                    open={modalPropsDelete.isModalVisible}
                    onOk={handleOkDelete}
                    onCancel={handleCloseDelete}
                    title="Ștergere"
                    width={"400px"}
                    okText={t('account.confirm')}
                    cancelText={t('account.cancel')}
                    cancelButtonProps={{ style: { background: theme.green, border: theme.green, color: theme.white, borderRadius: "10px" } }}
                    okButtonProps={{ style: { background: theme.secondColor, border: theme.secondColor, borderRadius: "10px" } }}
                >
                    <div>
                        <Typography>Sunteți sigur că doriți ștergerea laboratorului?</Typography>
                    </div>
                </Modal>
                <Modal
                    open={modalProps.isModalVisible}
                    onCancel={handleClose}
                    title={modalProps.title}
                    width={"500px"}
                    footer={null}
                    >
                        <div>
                            {modalProps.data.map(name => <Typography key={name}>{name}</Typography>)}
                        </div>
                </Modal>
                <Table
                    className={styles.usersTable}
                    dataSource={data?.data || []}
                    pagination={{
                        total: data?.totalCount,
                        current: currentPage,
                        pageSize: data?.pageSize,
                        pageSizeOptions: ["10", "20", "50"],
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        showTotal: (total: number, range: [number, number]) => {
                            return <div>
                                    {/* {isAdmin && <Tooltip title="Descărcare listă domenii">
                                        <Button type="primary" icon={<DownloadOutlined />} style={{marginRight: 10}} onClick={downloadReport}>
                                            Descărcare listă domenii
                                        </Button>
                                    </Tooltip>} */}
                                    <Tooltip title="Adaugă sală">
                                        <Link to='/adaugare-laborator'>
                                            <Button type="primary" icon={<PlusOutlined />}>
                                                Adaugă sală
                                            </Button>
                                        </Link>
                                    </Tooltip>
                                </div>
                        },
                        hideOnSinglePage: false,
                        locale: { items_per_page: t('configuration.pagination')},
                        position: ['topRight']
                    }}
                    locale={{
                        emptyText: (
                            <span>
                                <h3>Nu există săli adăugate! Apăsați butonul de mai jos pentru a adăuga o sală nou!</h3>
                                <Tooltip title="Adaugă sală">
                                    <Link to='/adaugare-laborator'>
                                        <Button type="primary" icon={<PlusOutlined />}>
                                            Adaugă sală
                                        </Button>
                                    </Link>
                                </Tooltip>
                            </span>
                          )
                    }}
                    columns={columns}
                    rowKey={record => record.id!}
                    loading={isLoading}
                />
            </Layout>
        </div>
    );
}

export default Laboratories;