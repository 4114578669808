/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LaboratoryAddDTO,
    LaboratoryAddDTOFromJSON,
    LaboratoryAddDTOToJSON,
    LaboratoryDTO,
    LaboratoryDTOFromJSON,
    LaboratoryDTOToJSON,
    LaboratoryDTOPagedResponse,
    LaboratoryDTOPagedResponseFromJSON,
    LaboratoryDTOPagedResponseToJSON,
} from '../models';

export interface ApiLaboratoryAddLaboratoryPostRequest {
    laboratoryAddDTO?: LaboratoryAddDTO;
}

export interface ApiLaboratoryDeleteIdDeleteRequest {
    id: string;
}

export interface ApiLaboratoryGetAlLaboratoriesByUserFacultyGetRequest {
    namePattern?: string;
    number?: string;
    facultyIds?: Array<string>;
    page?: number;
    pageSize?: number;
}

export interface ApiLaboratoryGetAlLaboratoriesGetRequest {
    namePattern?: string;
    number?: string;
    facultyIds?: Array<string>;
    page?: number;
    pageSize?: number;
}

export interface ApiLaboratoryGetLaboratoryByIdIdGetRequest {
    id: string;
}

export interface ApiLaboratoryUpdateLaboratoryIdPutRequest {
    id: string;
    laboratoryAddDTO?: LaboratoryAddDTO;
}

/**
 * 
 */
export class LaboratoryApi extends runtime.BaseAPI {

    /**
     */
    async apiLaboratoryAddLaboratoryPostRaw(requestParameters: ApiLaboratoryAddLaboratoryPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Laboratory/AddLaboratory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LaboratoryAddDTOToJSON(requestParameters.laboratoryAddDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiLaboratoryAddLaboratoryPost(requestParameters: ApiLaboratoryAddLaboratoryPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiLaboratoryAddLaboratoryPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiLaboratoryDeleteIdDeleteRaw(requestParameters: ApiLaboratoryDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiLaboratoryDeleteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Laboratory/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiLaboratoryDeleteIdDelete(requestParameters: ApiLaboratoryDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiLaboratoryDeleteIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiLaboratoryGetAlLaboratoriesByUserFacultyGetRaw(requestParameters: ApiLaboratoryGetAlLaboratoriesByUserFacultyGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LaboratoryDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.number !== undefined) {
            queryParameters['Number'] = requestParameters.number;
        }

        if (requestParameters.facultyIds) {
            queryParameters['FacultyIds'] = requestParameters.facultyIds;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Laboratory/GetAlLaboratoriesByUserFaculty`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaboratoryDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiLaboratoryGetAlLaboratoriesByUserFacultyGet(requestParameters: ApiLaboratoryGetAlLaboratoriesByUserFacultyGetRequest = {}, initOverrides?: RequestInit): Promise<LaboratoryDTOPagedResponse> {
        const response = await this.apiLaboratoryGetAlLaboratoriesByUserFacultyGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiLaboratoryGetAlLaboratoriesGetRaw(requestParameters: ApiLaboratoryGetAlLaboratoriesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LaboratoryDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.number !== undefined) {
            queryParameters['Number'] = requestParameters.number;
        }

        if (requestParameters.facultyIds) {
            queryParameters['FacultyIds'] = requestParameters.facultyIds;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Laboratory/GetAlLaboratories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaboratoryDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiLaboratoryGetAlLaboratoriesGet(requestParameters: ApiLaboratoryGetAlLaboratoriesGetRequest = {}, initOverrides?: RequestInit): Promise<LaboratoryDTOPagedResponse> {
        const response = await this.apiLaboratoryGetAlLaboratoriesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiLaboratoryGetLaboratoryByIdIdGetRaw(requestParameters: ApiLaboratoryGetLaboratoryByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LaboratoryDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiLaboratoryGetLaboratoryByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Laboratory/GetLaboratoryById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaboratoryDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiLaboratoryGetLaboratoryByIdIdGet(requestParameters: ApiLaboratoryGetLaboratoryByIdIdGetRequest, initOverrides?: RequestInit): Promise<LaboratoryDTO> {
        const response = await this.apiLaboratoryGetLaboratoryByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiLaboratoryUpdateLaboratoryIdPutRaw(requestParameters: ApiLaboratoryUpdateLaboratoryIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiLaboratoryUpdateLaboratoryIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Laboratory/UpdateLaboratory/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LaboratoryAddDTOToJSON(requestParameters.laboratoryAddDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiLaboratoryUpdateLaboratoryIdPut(requestParameters: ApiLaboratoryUpdateLaboratoryIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiLaboratoryUpdateLaboratoryIdPutRaw(requestParameters, initOverrides);
    }

}
