import { useState } from "react";
import Layout from "../../../Containers/Layout";
import styles from "./DomainForm.module.scss";
import { Form, DatePicker, Select, Radio, Col, Row, Input } from "antd";
import CustomForm from "../../../CustomComponents/CustomForm";
import { theme } from "../../../theme";
import { Redirect, useParams } from "react-router-dom";
import {
  NOTIFICATION_TYPES,
  openNotification,
} from "../../Notifications/NotificationsUtils";
import { getUserId } from "../../../utils/utilFunctions";
import ConfirmationModal from "../../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { addFundamentalDomain, getFundamentalDomain, updateDomain } from "../../../Requests/domain-requests";

const DomainForm = (props: any) => {
  const { t, i18n } = useTranslation();

  const openGetErrorNotification = (_error: any) => {
    openNotification(
      'Eroare',
      'Datele nu au putut fi aduse din server!',
      NOTIFICATION_TYPES.ERROR
    );
  };

  const [redirect, setRedirect] = useState(false);

  const [form] = Form.useForm<{
    name: string | null,
  }>();

  const { fundamentalDomainId } = useParams<{ fundamentalDomainId: string }>();

  const { data: fundamentalDomain } = useQuery(["getFundamentalDomain", fundamentalDomainId], () => getFundamentalDomain(fundamentalDomainId), {
    onError: (err) => {
      openGetErrorNotification(err);
    },
    onSuccess: (data) => {
      if (data) {
        form.setFieldsValue({
            name: data.name,
        });

      }
    },
    refetchOnWindowFocus: false
  });

  const openSaveErrorNotification = (error: any) => {
    if (error.status === 400) {
      openNotification(
        'Eroare!',
        'Datele completate nu sunt valide!',
        NOTIFICATION_TYPES.ERROR
      );
    } else {
      openNotification(
        'Eroare!',
        'Propunerea nu a putut fi salvată!',
        NOTIFICATION_TYPES.ERROR
      );
    }
  };

  const handleCancel = () => {
    setRedirect(true);
  };

  const openSuccessNotificationAndRedirect = () => {
    setRedirect(true);
    openNotification(
      'Succes!',
      'Salvarea s-a efectuat cu succes!',
      NOTIFICATION_TYPES.SUCCESS,
      6
    );
  }


  const handleSave = () => {
    const state = form.getFieldsValue();
    console.warn(state);
    
   //TODO validation
    if (state.name !== "") {
      let obj = {
        name: state.name!,
      };
      (fundamentalDomainId ? updateDomain(fundamentalDomainId, obj) : addFundamentalDomain(obj))
      .then(openSuccessNotificationAndRedirect)
      .catch(openSaveErrorNotification);
    } else {
      openNotification(
        'Eroare!',
        'Datele introduse nu sunt valide!',
        NOTIFICATION_TYPES.ERROR
      );
    }
  };
  

  if (redirect) {
    return <Redirect to={{pathname: "/domenii", state: {activeTab: "2"}}} />;
  } else {
    return (
      <Layout>
        <div className={styles.container}>
          <CustomForm
            form={form}
            layout={theme.layout}
            action="/domenii"
            boxshadow={"none"}
          >
            <div className={styles.firstLine}>    

              <Row gutter={8}>
                <Col span={20}>
                  <Form.Item
                    name='name'
                    label='Denumirea domeniului fundamental:'
                    rules={[
                      {
                        required: true,
                        message: 'Denumirea domeniului fundamental este obligatorie!',
                        whitespace: true,
                      },
                    ]}
                    >
                    <Input
                      placeholder='Completează denumirea domeniului fundamental'
                      />
                  </Form.Item>
                </Col>
               
              </Row>
            </div>
            <div className={styles.secondLine}>
              <div className={styles.firstColumn}>
                <ConfirmationModal
                  nameButton={t('internships.addInternshipForm.cancel')}
                  modalText={t('internships.cancelMessage')}
                  titleButton={''}
                  handleFunction={handleCancel}
                  iconButton={null}
                  shapeButton={''}
                  typeButton={''}
                  disabledProp={false}
                  styleProp={{ background: theme.green, color: theme.white, marginRight: '10%', marginTop: '0%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                />
                <ConfirmationModal
                  nameButton={fundamentalDomainId ? t('internships.addInternshipForm.updateButton') : t('internships.addInternshipForm.saveButton')}
                  modalText={t('internships.saveMessage')}
                  titleButton={''}
                  handleFunction={handleSave}
                  iconButton={null}
                  shapeButton={''}
                  typeButton={''}
                  disabledProp={false}
                  styleProp={{ background: theme.secondColor, color: theme.white, marginRight: '10%', marginTop: '0%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                />
              </div>
            </div>
          </CustomForm>
        </div>
      </Layout>
    );
  }
};

export default DomainForm;
