import {  useState } from "react";
import styles from "./Domains.module.scss";
import { Button, MenuProps, Modal, Progress, Table, TablePaginationConfig, Tooltip, Typography } from "antd";
import Search from 'antd/lib/input/Search';
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlusOutlined } from "@ant-design/icons";
import { Link, useHistory  } from "react-router-dom";
import { theme } from "../../theme";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useIsAdmin } from "../../utils/utilFunctions";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import { deleteFundamentalDomain, getFundamentalDomains } from "../../Requests/domain-requests";
import { StudyCycleEnum } from "../../Api";

interface PageResponse<T> {
    data?: T[] | null,
    totalCount?: number,
    page?: number,
    pageSize?: number
}

export type PageCallBack<T> = (currentPage: number, pageSize: number) => Promise<PageResponse<T> | null | undefined>;
export type ColumnType<T> = { title: string, key: any, dataIndex?: string, width?: any, render?: (text: string, record: T, index: number) => any };

const FundamentalDomains = () => {
    const { t } = useTranslation();
    const [searchTermName, setSearchTermName] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrent] = useState(1);

    
    const [modalPropsDelete, setModalPropsDelete] = useState({
        isModalVisible: false,
        title: '',
        id: '',
    });

    const [modalProps, setModalProps] = useState<{ isModalVisible: boolean, title: string, data: string[]}>({
        isModalVisible: false,
        title: '',
        data: []
    });

    const handleClose = () => {
        setModalProps(modalProps => ({...modalProps, isModalVisible: false}));
    }

    const handleOk = () => {
        setModalProps(modalProps => ({...modalProps, isModalVisible: false}));

    }

    const handleShowDelete = (id: string) => {
        setModalPropsDelete(modalProps => ({...modalProps, isModalVisible: true, id}));
    }

    const handleCloseDelete = () => {
        setModalPropsDelete(modalProps => ({...modalProps, isModalVisible: false}));
    }

    const handleOkDelete = () => {
        setModalPropsDelete(modalProps => ({...modalProps, isModalVisible: false}));
        deleteFundamentalDomain(modalPropsDelete.id)
            .then(() => {
                deleteSucceded();
                invalidateQuery();
            })
            .catch(deleteFailed);

    }
  
    const handleTableChange = (pagination: TablePaginationConfig) => {
        setCurrent(() => pagination.current ?? 1);
        setPageSize(() => pagination.pageSize ?? 10);
    };

    const deleteSucceded = () => {
        openNotification(
            'Succes',
            'Ștergerea s-a realizat cu succes!',
            NOTIFICATION_TYPES.SUCCESS
        );
    };

    const deleteFailed = (_err: any) => {
        openNotification(
            'Eroare',
            'Ștergerea nu s-a realizat cu succes!',
            NOTIFICATION_TYPES.ERROR
        );
    };

    const queryClient = useQueryClient();

    const invalidateQuery = () => { queryClient.invalidateQueries('getFundamentalDomains') };

    const openFetchError = (_error: any) => {
        openNotification(
            'Eroare!',
            'Datele nu au putut fi aduse din server!',
            NOTIFICATION_TYPES.ERROR
        );
    }

    const { data, isLoading } = useQuery(['getFundamentalDomains', searchTermName, pageSize, currentPage],
        () => {
            return getFundamentalDomains(searchTermName, currentPage, pageSize);
        },
        {
            onError: openFetchError,
        }
    );

    const FilterByNameInput = (
        <Search placeholder={t('account.name')} allowClear onSearch={setSearchTermName} style={{ width: '100%' }} />
    );


    const columns = [
        {
            title: FilterByNameInput,
            dataIndex: 'completeName',
            render: (text: string, record: any, index: number) => {
                return record.name;
            }
        },
        {
            title: "Indicatori",
            width: '100px',
            render: (text: string, record: any, index: number) => 
                <div>
                    <Link to={"/Indicatori/" + record.id}><Tooltip title='Indicatori'>
                        <FontAwesomeIcon icon={solid('edit')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                    </Tooltip></Link>
                </div>
        },
        {
            title: 'Acțiuni',
            width: '100px',
            render: (text: string, record: any, index: number) => 
                <div>
                    <Link to={"/editare-domeniu-fundamental/" + record.id}><Tooltip title='Editare'>
                        <FontAwesomeIcon icon={solid('edit')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                    </Tooltip></Link>
                    <Tooltip title='Ștergere'>
                        <FontAwesomeIcon icon={solid('trash')} style={{ marginLeft: '20px', cursor: 'pointer', height: "1rem", padding: "6px 0px" }}
                        onClick={() => handleShowDelete(record.id)}/>
                    </Tooltip>
                </div>
        }
    ];

    return <div className={styles.container} style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}>
            <Modal
                open={modalPropsDelete.isModalVisible}
                onOk={handleOkDelete}
                onCancel={handleCloseDelete}
                title="Ștergere"
                width={"400px"}
                okText={t('account.confirm')}
                cancelText={t('account.cancel')}
                cancelButtonProps={{ style: { background: theme.green, border: theme.green, color: theme.white, borderRadius: "10px" } }}
                okButtonProps={{ style: { background: theme.secondColor, border: theme.secondColor, borderRadius: "10px" } }}
                >
                    <div>
                        <Typography>Sigur doriti stergerea domeniului?</Typography>
                    </div>
            </Modal>
            <Modal
                open={modalProps.isModalVisible}
                onOk={handleOk}
                onCancel={handleClose}
                title={modalProps.title}
                width={"500px"}
                okText={t('account.confirm')}
                cancelText={t('account.cancel')}
                cancelButtonProps={{ style: { background: theme.green, border: theme.green, color: theme.white, borderRadius: "10px" } }}
                okButtonProps={{ style: { background: theme.secondColor, border: theme.secondColor, borderRadius: "10px" } }}
                >
                    <div>
                        {modalProps.data.map(name => <Typography key={name}>- {name}</Typography>)}
                    </div>
            </Modal>
            
            <Table
                className={styles.usersTable}
                dataSource={data?.data || []}
                scroll={{ y: 'calc(100vh - 330px)'  }}
                sticky={true}
                pagination={{
                    total: data?.totalCount,
                    current: currentPage,
                    pageSize: data?.pageSize,
                    pageSizeOptions: ["10", "20", "50"],
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    showTotal: (total: number, range: [number, number]) => {
                        return <div>
                                <Tooltip title="Adaugă domeniu fundamental">
                                    <Link to='/adaugare-domeniu-fundamental'>
                                        <Button type="primary" icon={<PlusOutlined />}>
                                            Adaugă domeniu
                                        </Button>
                                    </Link>
                                </Tooltip>
                            </div>
                    },
                    hideOnSinglePage: false,
                    locale: { items_per_page: t('configuration.pagination')},
                    position: ['topRight']
                }}
                locale={{
                    emptyText: (
                        <span>
                            <h3>Nu există domenii fundamentale adăugate! Apasă butonul de mai jos pentru a adăuga un domeniu nou!</h3>
                            <Tooltip title="Adaugă domeniu fundamental">
                                <Link to='/adaugare-domeniu-fundamental'>
                                    <Button type="primary" icon={<PlusOutlined />}>
                                        Adaugă domeniu fundamental
                                    </Button>
                                </Link>
                            </Tooltip>
                        </span>
                      )
                }}
                columns={columns}
                rowKey={record => record.id!}
                loading={isLoading}
                onChange={handleTableChange}
            />
    </div>
}

export default FundamentalDomains;
