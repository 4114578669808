/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FacultyDTO,
    FacultyDTOFromJSON,
    FacultyDTOFromJSONTyped,
    FacultyDTOToJSON,
} from './FacultyDTO';
import {
    SimpleStudyCycleEnum,
    SimpleStudyCycleEnumFromJSON,
    SimpleStudyCycleEnumFromJSONTyped,
    SimpleStudyCycleEnumToJSON,
} from './SimpleStudyCycleEnum';
import {
    StudyProgramDTO,
    StudyProgramDTOFromJSON,
    StudyProgramDTOFromJSONTyped,
    StudyProgramDTOToJSON,
} from './StudyProgramDTO';

/**
 * 
 * @export
 * @interface DomainDTO
 */
export interface DomainDTO {
    /**
     * 
     * @type {string}
     * @memberof DomainDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainDTO
     */
    readonly fundamentalName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainDTO
     */
    fundamentalDomainId?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainDTO
     */
    readonly name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainDTO
     */
    readonly completeName?: string | null;
    /**
     * 
     * @type {SimpleStudyCycleEnum}
     * @memberof DomainDTO
     */
    studyCycle?: SimpleStudyCycleEnum;
    /**
     * 
     * @type {Array<FacultyDTO>}
     * @memberof DomainDTO
     */
    faculties?: Array<FacultyDTO> | null;
    /**
     * 
     * @type {Array<StudyProgramDTO>}
     * @memberof DomainDTO
     */
    studyPrograms?: Array<StudyProgramDTO> | null;
}

export function DomainDTOFromJSON(json: any): DomainDTO {
    return DomainDTOFromJSONTyped(json, false);
}

export function DomainDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DomainDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fundamentalName': !exists(json, 'fundamentalName') ? undefined : json['fundamentalName'],
        'fundamentalDomainId': !exists(json, 'fundamentalDomainId') ? undefined : json['fundamentalDomainId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'completeName': !exists(json, 'completeName') ? undefined : json['completeName'],
        'studyCycle': !exists(json, 'studyCycle') ? undefined : SimpleStudyCycleEnumFromJSON(json['studyCycle']),
        'faculties': !exists(json, 'faculties') ? undefined : (json['faculties'] === null ? null : (json['faculties'] as Array<any>).map(FacultyDTOFromJSON)),
        'studyPrograms': !exists(json, 'studyPrograms') ? undefined : (json['studyPrograms'] === null ? null : (json['studyPrograms'] as Array<any>).map(StudyProgramDTOFromJSON)),
    };
}

export function DomainDTOToJSON(value?: DomainDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fundamentalDomainId': value.fundamentalDomainId,
        'studyCycle': SimpleStudyCycleEnumToJSON(value.studyCycle),
        'faculties': value.faculties === undefined ? undefined : (value.faculties === null ? null : (value.faculties as Array<any>).map(FacultyDTOToJSON)),
        'studyPrograms': value.studyPrograms === undefined ? undefined : (value.studyPrograms === null ? null : (value.studyPrograms as Array<any>).map(StudyProgramDTOToJSON)),
    };
}

