import { useState } from "react";
import styles from "./Indicators.module.scss";
import { Button, Modal, Select, Table, Tag, Tooltip, Typography } from "antd";
import Layout from "../../Containers/Layout";
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlusOutlined } from "@ant-design/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import { theme } from "../../theme";
import { useIsAdmin } from "../../utils/utilFunctions";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { deleteIndicator, getIndicators } from "../../Requests/indicator-requests";
import { IndicatorTypeEnum, IndicatorValueTypeEnum } from "../../Api";

const { Option } = Select;

const Indicators = () => {
    const { t } = useTranslation();
    const [searchTermType, setsearchTermType] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const history = useHistory();
    const isAdmin = useIsAdmin();

    const { fundamentalDomainId } = useParams<{ fundamentalDomainId: string }>();

    const [modalPropsDelete, setModalPropsDelete] = useState({
        isModalVisible: false,
        title: '',
        id: '',
    });

    const [modalProps, setModalProps] = useState<{ isModalVisible: boolean, title: string, data: string[] }>({
        isModalVisible: false,
        title: '',
        data: []
    });

    const handleShowDelete = (id: string) => {
        setModalPropsDelete(modalProps => ({ ...modalProps, isModalVisible: true, id }));
    }

    const handleCloseDelete = () => {
        setModalPropsDelete(modalProps => ({ ...modalProps, isModalVisible: false }));
    }

    const handleOkDelete = () => {
        setModalPropsDelete(modalProps => ({ ...modalProps, isModalVisible: false }));
        deleteIndicator(modalPropsDelete.id)
            .then(() => {
                deleteSucceeded();
                invalidateQuery();
            })
            .catch(deleteFailed);
    }

    const deleteSucceeded = () => {
        openNotification(
            'Succes',
            'Ștergerea a fost efectuată cu succes!',
            NOTIFICATION_TYPES.SUCCESS
        );
    };

    const deleteFailed = (_err: any) => {
        openNotification(
            'Eroare',
            'Ștergerea nu a fost efectuată!',
            NOTIFICATION_TYPES.ERROR
        );
    };

    const queryClient = useQueryClient();

    const invalidateQuery = () => { queryClient.invalidateQueries('getIndicators') };

    const openFetchError = (_error: any) => {
        openNotification(
            'Eroare!',
            'Datele nu au putut fi preluate de la server!',
            NOTIFICATION_TYPES.ERROR
        );
    }

    const { data, isLoading } = useQuery(['getIndicators', fundamentalDomainId, searchTermType, pageSize, currentPage],
        () => {
            const response = getIndicators(fundamentalDomainId, undefined, currentPage, pageSize);
            return response;
        },
        {
            onError: openFetchError,
        }
    );

    const columns = [
        {
            title: 'Tip Indicator',
            render: (text: string, record: any, index: number) =>
                  record.indicatorType === IndicatorTypeEnum.Fundamental ? <Tag color='magenta'  key={'Fundamentală'}>Fundamentală</Tag>
                : record.indicatorType === IndicatorTypeEnum.Domain ? <Tag color='volcano'  key={'De domeniu'}>De domeniu</Tag>
                : record.indicatorType === IndicatorTypeEnum.Complementary ? <Tag color='gold'  key={'Complementară'}>Complementară</Tag>
                : record.indicatorType === IndicatorTypeEnum.Specialty ? <Tag color='purple'  key={'De specialitate'}>De specialitate</Tag>
                : record.indicatorType === IndicatorTypeEnum.Mandatory ? <Tag color='gold'  key={'Obligatorie'}>Obligatorie</Tag>
                : record.indicatorType === IndicatorTypeEnum.Optional ? <Tag color='green'  key={'Opțională'}>Opțională</Tag>
                : record.indicatorType === IndicatorTypeEnum.Facultative ? <Tag color='orange'  key={'Facultativă'}>Facultativă</Tag>
                : 'N/A'
        },
        {
            title: 'Tip Valoare',
            render: (text: string, record: any, index: number) =>
                record.indicatorValueType === IndicatorValueTypeEnum.Lower ? "Mai mic" : "Mai mare"
        },
        {
            title: 'Valoare Indicator',
            dataIndex: 'indicatorValue',
        },
        {
            title: 'Acțiuni',
            width: '100px',
            render: (text: string, record: any, index: number) =>
                <div>
                    <Link to={"/editare-indicator/" + fundamentalDomainId + "/" + record.id}><Tooltip title='Editare'>
                        <FontAwesomeIcon icon={solid('edit')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }} />
                    </Tooltip></Link>
                    <Tooltip title='Ștergere'>
                        <FontAwesomeIcon icon={solid('trash')} style={{ marginLeft: '20px', cursor: 'pointer', height: "1rem", padding: "6px 0px" }}
                            onClick={() => handleShowDelete(record.id)} />
                    </Tooltip>
                </div>
        }
    ];

    return (
        <div className={styles.container} style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}>
            <Layout>
                <Modal
                    open={modalPropsDelete.isModalVisible}
                    onOk={handleOkDelete}
                    onCancel={handleCloseDelete}
                    title="Ștergere"
                    width={"400px"}
                    okText={t('account.confirm')}
                    cancelText={t('account.cancel')}
                    cancelButtonProps={{ style: { background: theme.green, border: theme.green, color: theme.white, borderRadius: "10px" } }}
                    okButtonProps={{ style: { background: theme.secondColor, border: theme.secondColor, borderRadius: "10px" } }}
                >
                    <div>
                        <Typography>Sunteți sigur că doriți ștergerea indicatorului?</Typography>
                    </div>
                </Modal>
                <Table
                    className={styles.indicatorsTable}
                    dataSource={data?.data || []}
                    pagination={{
                        total: data?.totalCount,
                        current: currentPage,
                        pageSize: data?.pageSize,
                        pageSizeOptions: ["10", "20", "50"],
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        showTotal: (total: number, range: [number, number]) => {
                            return <div>
                                    <Tooltip title="Adaugă Indicator">
                                        <Link to={`/adaugare-indicator/${fundamentalDomainId}`}>
                                            <Button type="primary" icon={<PlusOutlined />}>
                                                Adaugă indicator
                                            </Button>
                                        </Link>
                                    </Tooltip>
                                </div>
                        },
                        hideOnSinglePage: false,
                        locale: { items_per_page: t('configuration.pagination')},
                        position: ['topRight']
                    }}
                    locale={{
                        emptyText: (
                            <span>
                                <h3>Nu există indicatori adăugați! Apăsați butonul de mai jos pentru a adăuga un indicator nou!</h3>
                                <Tooltip title="Adaugă indicator">
                                    <Link to={`/adaugare-indicator/${fundamentalDomainId}`}>
                                        <Button type="primary" icon={<PlusOutlined />}>
                                            Adaugă indicator
                                        </Button>
                                    </Link>
                                </Tooltip>
                            </span>
                          )
                    }}
                    columns={columns}
                    rowKey={record => record.id!}
                    loading={isLoading}
                />
            </Layout>
        </div>
    );
}

export default Indicators;