/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum StudyPlanStatusEnum {
    Draft = 'Draft',
    PendingDeanValidation = 'PendingDeanValidation',
    PendingQaValidation = 'PendingQAValidation',
    PendingFacultyCouncilApproval = 'PendingFacultyCouncilApproval',
    Approved = 'Approved',
    All = 'All'
}

export function StudyPlanStatusEnumFromJSON(json: any): StudyPlanStatusEnum {
    return StudyPlanStatusEnumFromJSONTyped(json, false);
}

export function StudyPlanStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudyPlanStatusEnum {
    return json as StudyPlanStatusEnum;
}

export function StudyPlanStatusEnumToJSON(value?: StudyPlanStatusEnum | null): any {
    return value as any;
}

