/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IndicatorTypeEnum,
    IndicatorTypeEnumFromJSON,
    IndicatorTypeEnumFromJSONTyped,
    IndicatorTypeEnumToJSON,
} from './IndicatorTypeEnum';
import {
    IndicatorValueTypeEnum,
    IndicatorValueTypeEnumFromJSON,
    IndicatorValueTypeEnumFromJSONTyped,
    IndicatorValueTypeEnumToJSON,
} from './IndicatorValueTypeEnum';

/**
 * 
 * @export
 * @interface IndicatorDTO
 */
export interface IndicatorDTO {
    /**
     * 
     * @type {string}
     * @memberof IndicatorDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof IndicatorDTO
     */
    fundamentalDomainId?: string;
    /**
     * 
     * @type {IndicatorTypeEnum}
     * @memberof IndicatorDTO
     */
    indicatorType?: IndicatorTypeEnum;
    /**
     * 
     * @type {IndicatorValueTypeEnum}
     * @memberof IndicatorDTO
     */
    indicatorValueType?: IndicatorValueTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof IndicatorDTO
     */
    indicatorValue?: number;
}

export function IndicatorDTOFromJSON(json: any): IndicatorDTO {
    return IndicatorDTOFromJSONTyped(json, false);
}

export function IndicatorDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndicatorDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fundamentalDomainId': !exists(json, 'fundamentalDomainId') ? undefined : json['fundamentalDomainId'],
        'indicatorType': !exists(json, 'indicatorType') ? undefined : IndicatorTypeEnumFromJSON(json['indicatorType']),
        'indicatorValueType': !exists(json, 'indicatorValueType') ? undefined : IndicatorValueTypeEnumFromJSON(json['indicatorValueType']),
        'indicatorValue': !exists(json, 'indicatorValue') ? undefined : json['indicatorValue'],
    };
}

export function IndicatorDTOToJSON(value?: IndicatorDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fundamentalDomainId': value.fundamentalDomainId,
        'indicatorType': IndicatorTypeEnumToJSON(value.indicatorType),
        'indicatorValueType': IndicatorValueTypeEnumToJSON(value.indicatorValueType),
        'indicatorValue': value.indicatorValue,
    };
}

