/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum IndicatorValueTypeEnum {
    Lower = 'Lower',
    Higher = 'Higher'
}

export function IndicatorValueTypeEnumFromJSON(json: any): IndicatorValueTypeEnum {
    return IndicatorValueTypeEnumFromJSONTyped(json, false);
}

export function IndicatorValueTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndicatorValueTypeEnum {
    return json as IndicatorValueTypeEnum;
}

export function IndicatorValueTypeEnumToJSON(value?: IndicatorValueTypeEnum | null): any {
    return value as any;
}

