import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from '../Components/Home/Home';
import Login from '../Components/Login/Login';
import Info from '../Components/Info/Info';
import Notifications from '../Components/Notifications/Notifications';
import { useIsAdmin } from '../utils/utilFunctions';
import StudyPrograms from '../Components/StudyPrograms/StudyPrograms';
import DomainForm from '../Components/Domains/DomainForm/DomainForm';
import StudyProgramForm from '../Components/StudyPrograms/StudyProgramForm/StudyProgramForm';
import ExternalEvaluations from '../Components/ExternalEvaluations/ExternalEvaluations';
import ExternalEvaluationForm from '../Components/ExternalEvaluations/ExternalEvaluationForm/ExternalEvaluationForm';
import AdminBoardDecisions from '../Components/AdminBoardDecisions/AdminBoardDecisions';
import AdminBoardDecisionForm from '../Components/AdminBoardDecisions/AdminBoardDecisionForm/AdminBoardDecisionForm';
import SenateDecisionForm from '../Components/SenateDecisions/SenateDecisionForm/SenateDecisionForm';
import SenateDecisions from '../Components/SenateDecisions/SenateDecisions';
import FundamentalDomainForm from '../Components/Domains/DomainForm/FundamentalDomainForm';
import DomainTabs from '../Components/Domains/DomainTabs';
import Laboratories from '../Components/Laboratory/Laboratories';
import LaboratoryForm from '../Components/Laboratory/LaboratoryForm/LaboratoryForm';
import Indicators from '../Components/Indicator/Indicators';
import IndicatorForm from '../Components/Indicator/IndicatorForm/IndicatorForm';
import Subjects from '../Components/Subject/Subjects';
import SubjectForm from '../Components/Subject/SubjectForm/SubjectForm';
import SubjectShortForm from '../Components/Subject/SubjectShortForm/SubjectShortForm';
import IndicatorsStats from '../Components/Indicator/IndicatorsStats';

const Router = () => {
  const isAdmin = useIsAdmin();

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/autentificare' component={Login} />
        <Route path='/info' component={Info} />
        <Route path='/notificari' component={Notifications} />
        <Route path='/:studyCycle/programe' component={StudyPrograms} />
        <Route path='/:studyCycle/editare-program/:programId' component={StudyProgramForm} />
        <Route path='/:studyCycle/adaugare-program' component={StudyProgramForm} />
        <Route path='/domenii' component={DomainTabs} />
        <Route path='/editare-domeniu/:domainId' component={DomainForm} />
        <Route path='/adaugare-domeniu' component={DomainForm} />
        <Route path='/laboratoare' component={Laboratories} />
        <Route path='/:studyCycle/plan/:programId' component={Subjects} />
        <Route path='/:studyCycle/adaugare-disciplina-short/:programId' component={SubjectShortForm} />
        <Route path='/:studyCycle/editare-disciplina-short/:programId/:subjectId' component={SubjectShortForm} />
        <Route path='/:studyCycle/editare-disciplina/:programId/:subjectId' component={SubjectForm} />
        <Route path='/:studyCycle/adaugare-disciplina/:programId' component={SubjectForm} />
        <Route path='/indicatori/:fundamentalDomainId' component={Indicators} />
        <Route path='/statusIndicatori/:studyProgramId/:planYear' component={IndicatorsStats} />
        <Route path='/editare-laborator/:laboratoryId' component={LaboratoryForm} />
        <Route path='/adaugare-laborator' component={LaboratoryForm} />
        <Route path='/editare-indicator/:fundamentalDomainId/:indicatorId' component={IndicatorForm} />
        <Route path='/adaugare-indicator/:fundamentalDomainId' component={IndicatorForm} />
        <Route path='/adaugare-domeniu-fundamental' component={FundamentalDomainForm} />
        <Route path='/editare-domeniu-fundamental/:fundamentalDomainId' component={FundamentalDomainForm} />
        <Route path='/:studyCycle/editare-evaluare/:programId/:externalEvaluationId' component={ExternalEvaluationForm} />
        <Route path='/:studyCycle/adaugare-evaluare/:programId' component={ExternalEvaluationForm} />
        <Route path='/:studyCycle/evaluari-externe/:programId' component={ExternalEvaluations} />
        <Route path='/:studyCycle/editare-admin/:programId/:adminBoardDecisionId' component={AdminBoardDecisionForm} />
        <Route path='/:studyCycle/adaugare-admin/:programId' component={AdminBoardDecisionForm} />
        <Route path='/:studyCycle/hotarari-ca/:programId' component={AdminBoardDecisions} />
        <Route path='/:studyCycle/editare-senat/:programId/:senateDecisionId' component={SenateDecisionForm} />
        <Route path='/:studyCycle/adaugare-senat/:programId' component={SenateDecisionForm} />
        <Route path='/:studyCycle/hotarari-senat/:programId' component={SenateDecisions} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;