/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum IndicatorTypeEnum {
    Fundamental = 'Fundamental',
    Specialty = 'Specialty',
    Domain = 'Domain',
    Complementary = 'Complementary',
    Mandatory = 'Mandatory',
    Optional = 'Optional',
    Facultative = 'Facultative'
}

export function IndicatorTypeEnumFromJSON(json: any): IndicatorTypeEnum {
    return IndicatorTypeEnumFromJSONTyped(json, false);
}

export function IndicatorTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndicatorTypeEnum {
    return json as IndicatorTypeEnum;
}

export function IndicatorTypeEnumToJSON(value?: IndicatorTypeEnum | null): any {
    return value as any;
}

