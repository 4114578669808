/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LaboratoryCategoryEnum,
    LaboratoryCategoryEnumFromJSON,
    LaboratoryCategoryEnumFromJSONTyped,
    LaboratoryCategoryEnumToJSON,
} from './LaboratoryCategoryEnum';
import {
    LaboratoryTypeEnum,
    LaboratoryTypeEnumFromJSON,
    LaboratoryTypeEnumFromJSONTyped,
    LaboratoryTypeEnumToJSON,
} from './LaboratoryTypeEnum';
import {
    Subject,
    SubjectFromJSON,
    SubjectFromJSONTyped,
    SubjectToJSON,
} from './Subject';

/**
 * 
 * @export
 * @interface LaboratoryAddDTO
 */
export interface LaboratoryAddDTO {
    /**
     * 
     * @type {string}
     * @memberof LaboratoryAddDTO
     */
    number?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LaboratoryAddDTO
     */
    area?: number;
    /**
     * 
     * @type {number}
     * @memberof LaboratoryAddDTO
     */
    volume?: number;
    /**
     * 
     * @type {number}
     * @memberof LaboratoryAddDTO
     */
    capacity?: number;
    /**
     * 
     * @type {string}
     * @memberof LaboratoryAddDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LaboratoryAddDTO
     */
    facultyId?: string;
    /**
     * 
     * @type {LaboratoryCategoryEnum}
     * @memberof LaboratoryAddDTO
     */
    category?: LaboratoryCategoryEnum;
    /**
     * 
     * @type {LaboratoryTypeEnum}
     * @memberof LaboratoryAddDTO
     */
    type?: LaboratoryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LaboratoryAddDTO
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LaboratoryAddDTO
     */
    resources?: string | null;
    /**
     * 
     * @type {Array<Subject>}
     * @memberof LaboratoryAddDTO
     */
    subjects?: Array<Subject> | null;
}

export function LaboratoryAddDTOFromJSON(json: any): LaboratoryAddDTO {
    return LaboratoryAddDTOFromJSONTyped(json, false);
}

export function LaboratoryAddDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaboratoryAddDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': !exists(json, 'number') ? undefined : json['number'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'facultyId': !exists(json, 'facultyId') ? undefined : json['facultyId'],
        'category': !exists(json, 'category') ? undefined : LaboratoryCategoryEnumFromJSON(json['category']),
        'type': !exists(json, 'type') ? undefined : LaboratoryTypeEnumFromJSON(json['type']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'resources': !exists(json, 'resources') ? undefined : json['resources'],
        'subjects': !exists(json, 'subjects') ? undefined : (json['subjects'] === null ? null : (json['subjects'] as Array<any>).map(SubjectFromJSON)),
    };
}

export function LaboratoryAddDTOToJSON(value?: LaboratoryAddDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'area': value.area,
        'volume': value.volume,
        'capacity': value.capacity,
        'name': value.name,
        'facultyId': value.facultyId,
        'category': LaboratoryCategoryEnumToJSON(value.category),
        'type': LaboratoryTypeEnumToJSON(value.type),
        'description': value.description,
        'resources': value.resources,
        'subjects': value.subjects === undefined ? undefined : (value.subjects === null ? null : (value.subjects as Array<any>).map(SubjectToJSON)),
    };
}

