import React, { useState } from "react";
import { Form, Input, Select, Button, Row, Col } from "antd";
import Layout from "../../../Containers/Layout";
import styles from "./IndicatorForm.module.scss";
import { Redirect, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getIndicator, updateIndicator, addIndicator } from "../../../Requests/indicator-requests";
import { NOTIFICATION_TYPES, openNotification } from "../../Notifications/NotificationsUtils";
import { theme } from "../../../theme";
import ConfirmationModal from "../../../Containers/ConfirmationModal";

const { Option } = Select;

const IndicatorForm = (props: any) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { indicatorId, fundamentalDomainId } = useParams<{ indicatorId: string, fundamentalDomainId: string }>();

  const [redirect, setRedirect] = useState(false);

  const openGetErrorNotification = (_error: any) => {
    openNotification(
      'Eroare',
      'Nu s-au putut prelua datele de pe server!',
      NOTIFICATION_TYPES.ERROR
    );
  };

  useQuery(["getIndicator", indicatorId], () => getIndicator(indicatorId), {
    onError: (err) => {
      openGetErrorNotification(err);
    },
    onSuccess: (data) => {
      if (data) {
        form.setFieldsValue({
          indicatorType: data.indicatorType,
          indicatorValueType: data.indicatorValueType,
          indicatorValue: data.indicatorValue,
        });
      }
    },
    enabled: !!indicatorId,
    refetchOnWindowFocus: false
  });

  const openSaveErrorNotification = (error: any) => {
    openNotification(
      'Eroare!',
      'Nu s-a putut salva indicatorul!',
      NOTIFICATION_TYPES.ERROR
    );
  };

  const handleCancel = () => {
    setRedirect(true);
  };

  const openSuccessNotificationAndRedirect = () => {
    setRedirect(true);
    openNotification(
      'Succes!',
      'Indicatorul a fost salvat cu succes!',
      NOTIFICATION_TYPES.SUCCESS,
      6
    );
  };

  const handleSave = () => {
    const values = form.getFieldsValue();
    if (values.indicatorType && values.indicatorValueType !== undefined && values.indicatorValue !== undefined) {
      const indicatorData = {
        fundamentalDomainId: fundamentalDomainId,
        indicatorType: values.indicatorType,
        indicatorValueType: values.indicatorValueType,
        indicatorValue: values.indicatorValue,
      };

      const saveAction = indicatorId ? updateIndicator(indicatorId, indicatorData) : addIndicator(indicatorData);

      saveAction.then(openSuccessNotificationAndRedirect).catch(openSaveErrorNotification);
    } else {
      openNotification(
        'Eroare!',
        'Vă rugăm să completați toate câmpurile obligatorii!',
        NOTIFICATION_TYPES.ERROR
      );
    }
  };

  if (redirect) {
    return <Redirect to={`/indicatori/${fundamentalDomainId}`} />
  }

  return (
    <Layout>
      <div className="container">
        <Form form={form} layout="vertical">
          <Row>
            <Col span={8} offset={8}>
              <Form.Item
                name="indicatorType"
                label="Tip Indicator"
                rules={[{ required: true, message: 'Vă rugăm să selectați un tip de indicator!' }]}
              >
                <Select placeholder="Selectați un tip de indicator">
                  <Option value="Fundamental">Fundamental</Option>
                  <Option value="Specialty">Specialitate</Option>
                  <Option value="Domain">Domeniu</Option>
                  <Option value="Complementary">Complementar</Option>
                  <Option value="Mandatory">Obligatoriu</Option>
                  <Option value="Optional">Opțional</Option>
                  <Option value="Facultative">Facultativ</Option>
                </Select>
              </Form.Item>
            </Col>
            </Row>
            <Row>
            <Col span={8} offset={8}>
              <Form.Item
                name="indicatorValue"
                label="Valoare Indicator"
                rules={[{ required: true, message: 'Vă rugăm să introduceți valoarea indicatorului!' }]}
              >
                <Input placeholder="Valoarea indicatorului" type="number" />
              </Form.Item>
            </Col>
            </Row>
            <Row>
            <Col span={8} offset={8}>
              <Form.Item
                name="indicatorValueType"
                label="Tip Valoare Indicator"
                rules={[{ required: true, message: 'Vă rugăm să selectați un tip de valoare indicator!' }]}
              >
                <Select placeholder="Selectați un tip de valoare indicator">
                  <Option value="Lower">Mai mic</Option>
                  <Option value="Higher">Mai mare</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            
          </Row>
          <Row>
            <Col span={8} offset={8}>
            <div className={styles.secondLine}>
            <div className={styles.firstColumn} style={{ display: "flex", width: "250px", justifyContent: "space-between " }}>
                <ConfirmationModal
                  nameButton="Anulează"
                  modalText="Sunteți sigur că doriți să anulați?"
                  titleButton=""
                  handleFunction={handleCancel}
                  iconButton={null}
                  shapeButton=""
                  typeButton=""
                  disabledProp={false}
                  styleProp={{ background: theme.green, color: theme.white, marginRight: '10%', marginTop: '0%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                />
                <ConfirmationModal
                  nameButton={indicatorId ? "Actualizează" : "Salvează"}
                  modalText="Sunteți sigur că doriți să salvați?"
                  titleButton=""
                  handleFunction={handleSave}
                  iconButton={null}
                  shapeButton=""
                  typeButton=""
                  disabledProp={false}
                  styleProp={{ background: theme.secondColor, color: theme.white, marginRight: '10%', marginTop: '0%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                />
              </div>
            </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Layout>
  );
};

export default IndicatorForm;