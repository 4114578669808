/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminDTO,
    AdminDTOFromJSON,
    AdminDTOToJSON,
    DomainDTO,
    DomainDTOFromJSON,
    DomainDTOToJSON,
    DomainDTOPagedResponse,
    DomainDTOPagedResponseFromJSON,
    DomainDTOPagedResponseToJSON,
    DomainUpdateDTO,
    DomainUpdateDTOFromJSON,
    DomainUpdateDTOToJSON,
    FundamentalDomainDTO,
    FundamentalDomainDTOFromJSON,
    FundamentalDomainDTOToJSON,
    FundamentalDomainDTOPagedResponse,
    FundamentalDomainDTOPagedResponseFromJSON,
    FundamentalDomainDTOPagedResponseToJSON,
    FundamentalDomainUpdateDTO,
    FundamentalDomainUpdateDTOFromJSON,
    FundamentalDomainUpdateDTOToJSON,
    StudyCycleEnum,
    StudyCycleEnumFromJSON,
    StudyCycleEnumToJSON,
} from '../models';

export interface ApiDomainAddDomainPostRequest {
    domainUpdateDTO?: DomainUpdateDTO;
}

export interface ApiDomainAddFundamentalDomainPostRequest {
    fundamentalDomainUpdateDTO?: FundamentalDomainUpdateDTO;
}

export interface ApiDomainDeleteFundamentalDomainIdDeleteRequest {
    id: string;
}

export interface ApiDomainDeleteIdDeleteRequest {
    id: string;
}

export interface ApiDomainGetAllDomainsGetRequest {
    namePattern?: string;
    studyCycle?: StudyCycleEnum;
    facultyIds?: Array<string>;
    page?: number;
    pageSize?: number;
}

export interface ApiDomainGetAllFundamentalDomainsGetRequest {
    namePattern?: string;
    page?: number;
    pageSize?: number;
}

export interface ApiDomainGetDomainByIdIdGetRequest {
    id: string;
}

export interface ApiDomainGetFundamentalDomainByIdIdGetRequest {
    id: string;
}

export interface ApiDomainUpdateDomainIdPutRequest {
    id: string;
    domainUpdateDTO?: DomainUpdateDTO;
}

export interface ApiDomainUpdateFundamentalDomainIdPutRequest {
    id: string;
    fundamentalDomainUpdateDTO?: FundamentalDomainUpdateDTO;
}

/**
 * 
 */
export class DomainApi extends runtime.BaseAPI {

    /**
     */
    async apiDomainAddDomainPostRaw(requestParameters: ApiDomainAddDomainPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DomainDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Domain/AddDomain`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DomainUpdateDTOToJSON(requestParameters.domainUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DomainDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiDomainAddDomainPost(requestParameters: ApiDomainAddDomainPostRequest = {}, initOverrides?: RequestInit): Promise<DomainDTO> {
        const response = await this.apiDomainAddDomainPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDomainAddFundamentalDomainPostRaw(requestParameters: ApiDomainAddFundamentalDomainPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FundamentalDomainDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Domain/AddFundamentalDomain`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FundamentalDomainUpdateDTOToJSON(requestParameters.fundamentalDomainUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FundamentalDomainDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiDomainAddFundamentalDomainPost(requestParameters: ApiDomainAddFundamentalDomainPostRequest = {}, initOverrides?: RequestInit): Promise<FundamentalDomainDTO> {
        const response = await this.apiDomainAddFundamentalDomainPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDomainDeleteFundamentalDomainIdDeleteRaw(requestParameters: ApiDomainDeleteFundamentalDomainIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDomainDeleteFundamentalDomainIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Domain/DeleteFundamentalDomain/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDomainDeleteFundamentalDomainIdDelete(requestParameters: ApiDomainDeleteFundamentalDomainIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiDomainDeleteFundamentalDomainIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiDomainDeleteIdDeleteRaw(requestParameters: ApiDomainDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDomainDeleteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Domain/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDomainDeleteIdDelete(requestParameters: ApiDomainDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiDomainDeleteIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiDomainGetAllDomainsGetRaw(requestParameters: ApiDomainGetAllDomainsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DomainDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.studyCycle !== undefined) {
            queryParameters['StudyCycle'] = requestParameters.studyCycle;
        }

        if (requestParameters.facultyIds) {
            queryParameters['FacultyIds'] = requestParameters.facultyIds;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Domain/GetAllDomains`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DomainDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDomainGetAllDomainsGet(requestParameters: ApiDomainGetAllDomainsGetRequest = {}, initOverrides?: RequestInit): Promise<DomainDTOPagedResponse> {
        const response = await this.apiDomainGetAllDomainsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDomainGetAllFundamentalDomainsGetRaw(requestParameters: ApiDomainGetAllFundamentalDomainsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FundamentalDomainDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Domain/GetAllFundamentalDomains`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FundamentalDomainDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiDomainGetAllFundamentalDomainsGet(requestParameters: ApiDomainGetAllFundamentalDomainsGetRequest = {}, initOverrides?: RequestInit): Promise<FundamentalDomainDTOPagedResponse> {
        const response = await this.apiDomainGetAllFundamentalDomainsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDomainGetDomainByIdIdGetRaw(requestParameters: ApiDomainGetDomainByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DomainDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDomainGetDomainByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Domain/GetDomainById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DomainDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiDomainGetDomainByIdIdGet(requestParameters: ApiDomainGetDomainByIdIdGetRequest, initOverrides?: RequestInit): Promise<DomainDTO> {
        const response = await this.apiDomainGetDomainByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDomainGetFundamentalDomainByIdIdGetRaw(requestParameters: ApiDomainGetFundamentalDomainByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FundamentalDomainDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDomainGetFundamentalDomainByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Domain/GetFundamentalDomainById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FundamentalDomainDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiDomainGetFundamentalDomainByIdIdGet(requestParameters: ApiDomainGetFundamentalDomainByIdIdGetRequest, initOverrides?: RequestInit): Promise<FundamentalDomainDTO> {
        const response = await this.apiDomainGetFundamentalDomainByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDomainUpdateDomainIdPutRaw(requestParameters: ApiDomainUpdateDomainIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdminDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDomainUpdateDomainIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Domain/UpdateDomain/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DomainUpdateDTOToJSON(requestParameters.domainUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiDomainUpdateDomainIdPut(requestParameters: ApiDomainUpdateDomainIdPutRequest, initOverrides?: RequestInit): Promise<AdminDTO> {
        const response = await this.apiDomainUpdateDomainIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDomainUpdateFundamentalDomainIdPutRaw(requestParameters: ApiDomainUpdateFundamentalDomainIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FundamentalDomainDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDomainUpdateFundamentalDomainIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Domain/UpdateFundamentalDomain/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FundamentalDomainUpdateDTOToJSON(requestParameters.fundamentalDomainUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FundamentalDomainDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiDomainUpdateFundamentalDomainIdPut(requestParameters: ApiDomainUpdateFundamentalDomainIdPutRequest, initOverrides?: RequestInit): Promise<FundamentalDomainDTO> {
        const response = await this.apiDomainUpdateFundamentalDomainIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
