/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DepartmentDTO,
    DepartmentDTOFromJSON,
    DepartmentDTOFromJSONTyped,
    DepartmentDTOToJSON,
} from './DepartmentDTO';
import {
    StudyPlanStatusEnum,
    StudyPlanStatusEnumFromJSON,
    StudyPlanStatusEnumFromJSONTyped,
    StudyPlanStatusEnumToJSON,
} from './StudyPlanStatusEnum';
import {
    SubjectDTO,
    SubjectDTOFromJSON,
    SubjectDTOFromJSONTyped,
    SubjectDTOToJSON,
} from './SubjectDTO';

/**
 * 
 * @export
 * @interface StudyPlanDTO
 */
export interface StudyPlanDTO {
    /**
     * 
     * @type {string}
     * @memberof StudyPlanDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof StudyPlanDTO
     */
    planYear?: number;
    /**
     * 
     * @type {StudyPlanStatusEnum}
     * @memberof StudyPlanDTO
     */
    status?: StudyPlanStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof StudyPlanDTO
     */
    approvalDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof StudyPlanDTO
     */
    studyProgramId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudyPlanDTO
     */
    departmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof StudyPlanDTO
     */
    facultyId?: string;
    /**
     * 
     * @type {DepartmentDTO}
     * @memberof StudyPlanDTO
     */
    department?: DepartmentDTO;
    /**
     * 
     * @type {Array<SubjectDTO>}
     * @memberof StudyPlanDTO
     */
    subjects?: Array<SubjectDTO> | null;
}

export function StudyPlanDTOFromJSON(json: any): StudyPlanDTO {
    return StudyPlanDTOFromJSONTyped(json, false);
}

export function StudyPlanDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudyPlanDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'planYear': !exists(json, 'planYear') ? undefined : json['planYear'],
        'status': !exists(json, 'status') ? undefined : StudyPlanStatusEnumFromJSON(json['status']),
        'approvalDate': !exists(json, 'approvalDate') ? undefined : (new Date(json['approvalDate'])),
        'studyProgramId': !exists(json, 'studyProgramId') ? undefined : json['studyProgramId'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'facultyId': !exists(json, 'facultyId') ? undefined : json['facultyId'],
        'department': !exists(json, 'department') ? undefined : DepartmentDTOFromJSON(json['department']),
        'subjects': !exists(json, 'subjects') ? undefined : (json['subjects'] === null ? null : (json['subjects'] as Array<any>).map(SubjectDTOFromJSON)),
    };
}

export function StudyPlanDTOToJSON(value?: StudyPlanDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'planYear': value.planYear,
        'status': StudyPlanStatusEnumToJSON(value.status),
        'approvalDate': value.approvalDate === undefined ? undefined : (value.approvalDate.toISOString()),
        'studyProgramId': value.studyProgramId,
        'departmentId': value.departmentId,
        'facultyId': value.facultyId,
        'department': DepartmentDTOToJSON(value.department),
        'subjects': value.subjects === undefined ? undefined : (value.subjects === null ? null : (value.subjects as Array<any>).map(SubjectDTOToJSON)),
    };
}

