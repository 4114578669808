/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum StatTypeEnum {
    Optional = 'Optional',
    Facultative = 'Facultative',
    Project = 'Project',
    Laboratory = 'Laboratory'
}

export function StatTypeEnumFromJSON(json: any): StatTypeEnum {
    return StatTypeEnumFromJSONTyped(json, false);
}

export function StatTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatTypeEnum {
    return json as StatTypeEnum;
}

export function StatTypeEnumToJSON(value?: StatTypeEnum | null): any {
    return value as any;
}

