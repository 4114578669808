/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FundamentalDomain,
    FundamentalDomainFromJSON,
    FundamentalDomainFromJSONTyped,
    FundamentalDomainToJSON,
} from './FundamentalDomain';
import {
    IndicatorTypeEnum,
    IndicatorTypeEnumFromJSON,
    IndicatorTypeEnumFromJSONTyped,
    IndicatorTypeEnumToJSON,
} from './IndicatorTypeEnum';
import {
    IndicatorValueTypeEnum,
    IndicatorValueTypeEnumFromJSON,
    IndicatorValueTypeEnumFromJSONTyped,
    IndicatorValueTypeEnumToJSON,
} from './IndicatorValueTypeEnum';

/**
 * 
 * @export
 * @interface IndicatorAddDTO
 */
export interface IndicatorAddDTO {
    /**
     * 
     * @type {string}
     * @memberof IndicatorAddDTO
     */
    fundamentalDomainId?: string;
    /**
     * 
     * @type {IndicatorTypeEnum}
     * @memberof IndicatorAddDTO
     */
    indicatorType?: IndicatorTypeEnum;
    /**
     * 
     * @type {IndicatorValueTypeEnum}
     * @memberof IndicatorAddDTO
     */
    indicatorValueType?: IndicatorValueTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof IndicatorAddDTO
     */
    indicatorValue?: number;
    /**
     * 
     * @type {FundamentalDomain}
     * @memberof IndicatorAddDTO
     */
    fundamentalDomain?: FundamentalDomain;
}

export function IndicatorAddDTOFromJSON(json: any): IndicatorAddDTO {
    return IndicatorAddDTOFromJSONTyped(json, false);
}

export function IndicatorAddDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndicatorAddDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fundamentalDomainId': !exists(json, 'fundamentalDomainId') ? undefined : json['fundamentalDomainId'],
        'indicatorType': !exists(json, 'indicatorType') ? undefined : IndicatorTypeEnumFromJSON(json['indicatorType']),
        'indicatorValueType': !exists(json, 'indicatorValueType') ? undefined : IndicatorValueTypeEnumFromJSON(json['indicatorValueType']),
        'indicatorValue': !exists(json, 'indicatorValue') ? undefined : json['indicatorValue'],
        'fundamentalDomain': !exists(json, 'fundamentalDomain') ? undefined : FundamentalDomainFromJSON(json['fundamentalDomain']),
    };
}

export function IndicatorAddDTOToJSON(value?: IndicatorAddDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fundamentalDomainId': value.fundamentalDomainId,
        'indicatorType': IndicatorTypeEnumToJSON(value.indicatorType),
        'indicatorValueType': IndicatorValueTypeEnumToJSON(value.indicatorValueType),
        'indicatorValue': value.indicatorValue,
        'fundamentalDomain': FundamentalDomainToJSON(value.fundamentalDomain),
    };
}

