/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AcademicUser,
    AcademicUserFromJSON,
    AcademicUserFromJSONTyped,
    AcademicUserToJSON,
} from './AcademicUser';
import {
    Department,
    DepartmentFromJSON,
    DepartmentFromJSONTyped,
    DepartmentToJSON,
} from './Department';
import {
    EvaluationTypeEnum,
    EvaluationTypeEnumFromJSON,
    EvaluationTypeEnumFromJSONTyped,
    EvaluationTypeEnumToJSON,
} from './EvaluationTypeEnum';
import {
    GradingTypeEnum,
    GradingTypeEnumFromJSON,
    GradingTypeEnumFromJSONTyped,
    GradingTypeEnumToJSON,
} from './GradingTypeEnum';
import {
    IndicatorTypeEnum,
    IndicatorTypeEnumFromJSON,
    IndicatorTypeEnumFromJSONTyped,
    IndicatorTypeEnumToJSON,
} from './IndicatorTypeEnum';
import {
    Laboratory,
    LaboratoryFromJSON,
    LaboratoryFromJSONTyped,
    LaboratoryToJSON,
} from './Laboratory';
import {
    SemesterTypeEnum,
    SemesterTypeEnumFromJSON,
    SemesterTypeEnumFromJSONTyped,
    SemesterTypeEnumToJSON,
} from './SemesterTypeEnum';
import {
    StudyPlan,
    StudyPlanFromJSON,
    StudyPlanFromJSONTyped,
    StudyPlanToJSON,
} from './StudyPlan';
import {
    StudyProgram,
    StudyProgramFromJSON,
    StudyProgramFromJSONTyped,
    StudyProgramToJSON,
} from './StudyProgram';
import {
    StudyYearEnum,
    StudyYearEnumFromJSON,
    StudyYearEnumFromJSONTyped,
    StudyYearEnumToJSON,
} from './StudyYearEnum';
import {
    SubjectContent,
    SubjectContentFromJSON,
    SubjectContentFromJSONTyped,
    SubjectContentToJSON,
} from './SubjectContent';
import {
    SubjectEvaluation,
    SubjectEvaluationFromJSON,
    SubjectEvaluationFromJSONTyped,
    SubjectEvaluationToJSON,
} from './SubjectEvaluation';

/**
 * 
 * @export
 * @interface Subject
 */
export interface Subject {
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof Subject
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Subject
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    orderNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    disciplineGroup?: number;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    nameRO?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    nameEN?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    departmentId?: string;
    /**
     * 
     * @type {SemesterTypeEnum}
     * @memberof Subject
     */
    semester?: SemesterTypeEnum;
    /**
     * 
     * @type {StudyYearEnum}
     * @memberof Subject
     */
    studyYear?: StudyYearEnum;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    schoolYear?: number;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    studyProgramId?: string;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    studyPlanId?: string;
    /**
     * 
     * @type {EvaluationTypeEnum}
     * @memberof Subject
     */
    evaluationType?: EvaluationTypeEnum;
    /**
     * 
     * @type {IndicatorTypeEnum}
     * @memberof Subject
     */
    subjectType?: IndicatorTypeEnum;
    /**
     * 
     * @type {IndicatorTypeEnum}
     * @memberof Subject
     */
    subjectCategory?: IndicatorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    code?: string | null;
    /**
     * 
     * @type {GradingTypeEnum}
     * @memberof Subject
     */
    gradingType?: GradingTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    totalWeeklyHours?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    courseWeeklyHours?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    labWeeklyHours?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    seminarWeeklyHours?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    projectWeeklyHours?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    readonly totalHours?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    readonly courseHours?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    readonly labHours?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    selfStudyHours?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    tutoringHours?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    examHours?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    otherActivitiesHours?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    readonly totalSelfStudyHours?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    readonly totalSemesterHours?: number;
    /**
     * 
     * @type {number}
     * @memberof Subject
     */
    readonly credits?: number;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    curriculumPrecondition?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    respultsPrecondition?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    courseConditions?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    labConditions?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    generalObjective?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    specificCompetence?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    generalCompetence?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    teachingKnowledges?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    teachingAptitudes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    teachingResponsabilities?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    teachingMethods?: string | null;
    /**
     * 
     * @type {Array<SubjectContent>}
     * @memberof Subject
     */
    subjectContents?: Array<SubjectContent> | null;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    courseBibliography?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    labBibliography?: string | null;
    /**
     * 
     * @type {Array<SubjectEvaluation>}
     * @memberof Subject
     */
    subjectEvaluations?: Array<SubjectEvaluation> | null;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    promotionConditions?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subject
     */
    subjectCoroboration?: string | null;
    /**
     * 
     * @type {StudyProgram}
     * @memberof Subject
     */
    studyProgram?: StudyProgram;
    /**
     * 
     * @type {StudyPlan}
     * @memberof Subject
     */
    studyPlan?: StudyPlan;
    /**
     * 
     * @type {Department}
     * @memberof Subject
     */
    department?: Department;
    /**
     * 
     * @type {Array<AcademicUser>}
     * @memberof Subject
     */
    courseCoordinators?: Array<AcademicUser> | null;
    /**
     * 
     * @type {Array<AcademicUser>}
     * @memberof Subject
     */
    labCoordinators?: Array<AcademicUser> | null;
    /**
     * 
     * @type {Array<Laboratory>}
     * @memberof Subject
     */
    laboratories?: Array<Laboratory> | null;
}

export function SubjectFromJSON(json: any): Subject {
    return SubjectFromJSONTyped(json, false);
}

export function SubjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): Subject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'orderNumber': !exists(json, 'orderNumber') ? undefined : json['orderNumber'],
        'disciplineGroup': !exists(json, 'disciplineGroup') ? undefined : json['disciplineGroup'],
        'nameRO': !exists(json, 'nameRO') ? undefined : json['nameRO'],
        'nameEN': !exists(json, 'nameEN') ? undefined : json['nameEN'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'semester': !exists(json, 'semester') ? undefined : SemesterTypeEnumFromJSON(json['semester']),
        'studyYear': !exists(json, 'studyYear') ? undefined : StudyYearEnumFromJSON(json['studyYear']),
        'schoolYear': !exists(json, 'schoolYear') ? undefined : json['schoolYear'],
        'studyProgramId': !exists(json, 'studyProgramId') ? undefined : json['studyProgramId'],
        'studyPlanId': !exists(json, 'studyPlanId') ? undefined : json['studyPlanId'],
        'evaluationType': !exists(json, 'evaluationType') ? undefined : EvaluationTypeEnumFromJSON(json['evaluationType']),
        'subjectType': !exists(json, 'subjectType') ? undefined : IndicatorTypeEnumFromJSON(json['subjectType']),
        'subjectCategory': !exists(json, 'subjectCategory') ? undefined : IndicatorTypeEnumFromJSON(json['subjectCategory']),
        'code': !exists(json, 'code') ? undefined : json['code'],
        'gradingType': !exists(json, 'gradingType') ? undefined : GradingTypeEnumFromJSON(json['gradingType']),
        'totalWeeklyHours': !exists(json, 'totalWeeklyHours') ? undefined : json['totalWeeklyHours'],
        'courseWeeklyHours': !exists(json, 'courseWeeklyHours') ? undefined : json['courseWeeklyHours'],
        'labWeeklyHours': !exists(json, 'labWeeklyHours') ? undefined : json['labWeeklyHours'],
        'seminarWeeklyHours': !exists(json, 'seminarWeeklyHours') ? undefined : json['seminarWeeklyHours'],
        'projectWeeklyHours': !exists(json, 'projectWeeklyHours') ? undefined : json['projectWeeklyHours'],
        'totalHours': !exists(json, 'totalHours') ? undefined : json['totalHours'],
        'courseHours': !exists(json, 'courseHours') ? undefined : json['courseHours'],
        'labHours': !exists(json, 'labHours') ? undefined : json['labHours'],
        'selfStudyHours': !exists(json, 'selfStudyHours') ? undefined : json['selfStudyHours'],
        'tutoringHours': !exists(json, 'tutoringHours') ? undefined : json['tutoringHours'],
        'examHours': !exists(json, 'examHours') ? undefined : json['examHours'],
        'otherActivitiesHours': !exists(json, 'otherActivitiesHours') ? undefined : json['otherActivitiesHours'],
        'totalSelfStudyHours': !exists(json, 'totalSelfStudyHours') ? undefined : json['totalSelfStudyHours'],
        'totalSemesterHours': !exists(json, 'totalSemesterHours') ? undefined : json['totalSemesterHours'],
        'credits': !exists(json, 'credits') ? undefined : json['credits'],
        'curriculumPrecondition': !exists(json, 'curriculumPrecondition') ? undefined : json['curriculumPrecondition'],
        'respultsPrecondition': !exists(json, 'respultsPrecondition') ? undefined : json['respultsPrecondition'],
        'courseConditions': !exists(json, 'courseConditions') ? undefined : json['courseConditions'],
        'labConditions': !exists(json, 'labConditions') ? undefined : json['labConditions'],
        'generalObjective': !exists(json, 'generalObjective') ? undefined : json['generalObjective'],
        'specificCompetence': !exists(json, 'specificCompetence') ? undefined : json['specificCompetence'],
        'generalCompetence': !exists(json, 'generalCompetence') ? undefined : json['generalCompetence'],
        'teachingKnowledges': !exists(json, 'teachingKnowledges') ? undefined : json['teachingKnowledges'],
        'teachingAptitudes': !exists(json, 'teachingAptitudes') ? undefined : json['teachingAptitudes'],
        'teachingResponsabilities': !exists(json, 'teachingResponsabilities') ? undefined : json['teachingResponsabilities'],
        'teachingMethods': !exists(json, 'teachingMethods') ? undefined : json['teachingMethods'],
        'subjectContents': !exists(json, 'subjectContents') ? undefined : (json['subjectContents'] === null ? null : (json['subjectContents'] as Array<any>).map(SubjectContentFromJSON)),
        'courseBibliography': !exists(json, 'courseBibliography') ? undefined : json['courseBibliography'],
        'labBibliography': !exists(json, 'labBibliography') ? undefined : json['labBibliography'],
        'subjectEvaluations': !exists(json, 'subjectEvaluations') ? undefined : (json['subjectEvaluations'] === null ? null : (json['subjectEvaluations'] as Array<any>).map(SubjectEvaluationFromJSON)),
        'promotionConditions': !exists(json, 'promotionConditions') ? undefined : json['promotionConditions'],
        'subjectCoroboration': !exists(json, 'subjectCoroboration') ? undefined : json['subjectCoroboration'],
        'studyProgram': !exists(json, 'studyProgram') ? undefined : StudyProgramFromJSON(json['studyProgram']),
        'studyPlan': !exists(json, 'studyPlan') ? undefined : StudyPlanFromJSON(json['studyPlan']),
        'department': !exists(json, 'department') ? undefined : DepartmentFromJSON(json['department']),
        'courseCoordinators': !exists(json, 'courseCoordinators') ? undefined : (json['courseCoordinators'] === null ? null : (json['courseCoordinators'] as Array<any>).map(AcademicUserFromJSON)),
        'labCoordinators': !exists(json, 'labCoordinators') ? undefined : (json['labCoordinators'] === null ? null : (json['labCoordinators'] as Array<any>).map(AcademicUserFromJSON)),
        'laboratories': !exists(json, 'laboratories') ? undefined : (json['laboratories'] === null ? null : (json['laboratories'] as Array<any>).map(LaboratoryFromJSON)),
    };
}

export function SubjectToJSON(value?: Subject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'orderNumber': value.orderNumber,
        'disciplineGroup': value.disciplineGroup,
        'nameRO': value.nameRO,
        'nameEN': value.nameEN,
        'departmentId': value.departmentId,
        'semester': SemesterTypeEnumToJSON(value.semester),
        'studyYear': StudyYearEnumToJSON(value.studyYear),
        'schoolYear': value.schoolYear,
        'studyProgramId': value.studyProgramId,
        'studyPlanId': value.studyPlanId,
        'evaluationType': EvaluationTypeEnumToJSON(value.evaluationType),
        'subjectType': IndicatorTypeEnumToJSON(value.subjectType),
        'subjectCategory': IndicatorTypeEnumToJSON(value.subjectCategory),
        'code': value.code,
        'gradingType': GradingTypeEnumToJSON(value.gradingType),
        'totalWeeklyHours': value.totalWeeklyHours,
        'courseWeeklyHours': value.courseWeeklyHours,
        'labWeeklyHours': value.labWeeklyHours,
        'seminarWeeklyHours': value.seminarWeeklyHours,
        'projectWeeklyHours': value.projectWeeklyHours,
        'selfStudyHours': value.selfStudyHours,
        'tutoringHours': value.tutoringHours,
        'examHours': value.examHours,
        'otherActivitiesHours': value.otherActivitiesHours,
        'curriculumPrecondition': value.curriculumPrecondition,
        'respultsPrecondition': value.respultsPrecondition,
        'courseConditions': value.courseConditions,
        'labConditions': value.labConditions,
        'generalObjective': value.generalObjective,
        'specificCompetence': value.specificCompetence,
        'generalCompetence': value.generalCompetence,
        'teachingKnowledges': value.teachingKnowledges,
        'teachingAptitudes': value.teachingAptitudes,
        'teachingResponsabilities': value.teachingResponsabilities,
        'teachingMethods': value.teachingMethods,
        'subjectContents': value.subjectContents === undefined ? undefined : (value.subjectContents === null ? null : (value.subjectContents as Array<any>).map(SubjectContentToJSON)),
        'courseBibliography': value.courseBibliography,
        'labBibliography': value.labBibliography,
        'subjectEvaluations': value.subjectEvaluations === undefined ? undefined : (value.subjectEvaluations === null ? null : (value.subjectEvaluations as Array<any>).map(SubjectEvaluationToJSON)),
        'promotionConditions': value.promotionConditions,
        'subjectCoroboration': value.subjectCoroboration,
        'studyProgram': StudyProgramToJSON(value.studyProgram),
        'studyPlan': StudyPlanToJSON(value.studyPlan),
        'department': DepartmentToJSON(value.department),
        'courseCoordinators': value.courseCoordinators === undefined ? undefined : (value.courseCoordinators === null ? null : (value.courseCoordinators as Array<any>).map(AcademicUserToJSON)),
        'labCoordinators': value.labCoordinators === undefined ? undefined : (value.labCoordinators === null ? null : (value.labCoordinators as Array<any>).map(AcademicUserToJSON)),
        'laboratories': value.laboratories === undefined ? undefined : (value.laboratories === null ? null : (value.laboratories as Array<any>).map(LaboratoryToJSON)),
    };
}

