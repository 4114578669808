/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SemesterSubjectStats,
    SemesterSubjectStatsFromJSON,
    SemesterSubjectStatsFromJSONTyped,
    SemesterSubjectStatsToJSON,
} from './SemesterSubjectStats';
import {
    StudyYearEnum,
    StudyYearEnumFromJSON,
    StudyYearEnumFromJSONTyped,
    StudyYearEnumToJSON,
} from './StudyYearEnum';

/**
 * 
 * @export
 * @interface YearSubjectStats
 */
export interface YearSubjectStats {
    /**
     * 
     * @type {StudyYearEnum}
     * @memberof YearSubjectStats
     */
    studyYear?: StudyYearEnum;
    /**
     * 
     * @type {SemesterSubjectStats}
     * @memberof YearSubjectStats
     */
    s1?: SemesterSubjectStats;
    /**
     * 
     * @type {SemesterSubjectStats}
     * @memberof YearSubjectStats
     */
    s2?: SemesterSubjectStats;
    /**
     * 
     * @type {SemesterSubjectStats}
     * @memberof YearSubjectStats
     */
    total?: SemesterSubjectStats;
    /**
     * 
     * @type {number}
     * @memberof YearSubjectStats
     */
    totalSubjects?: number;
}

export function YearSubjectStatsFromJSON(json: any): YearSubjectStats {
    return YearSubjectStatsFromJSONTyped(json, false);
}

export function YearSubjectStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): YearSubjectStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'studyYear': !exists(json, 'studyYear') ? undefined : StudyYearEnumFromJSON(json['studyYear']),
        's1': !exists(json, 's1') ? undefined : SemesterSubjectStatsFromJSON(json['s1']),
        's2': !exists(json, 's2') ? undefined : SemesterSubjectStatsFromJSON(json['s2']),
        'total': !exists(json, 'total') ? undefined : SemesterSubjectStatsFromJSON(json['total']),
        'totalSubjects': !exists(json, 'totalSubjects') ? undefined : json['totalSubjects'],
    };
}

export function YearSubjectStatsToJSON(value?: YearSubjectStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'studyYear': StudyYearEnumToJSON(value.studyYear),
        's1': SemesterSubjectStatsToJSON(value.s1),
        's2': SemesterSubjectStatsToJSON(value.s2),
        'total': SemesterSubjectStatsToJSON(value.total),
        'totalSubjects': value.totalSubjects,
    };
}

