/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Faculty,
    FacultyFromJSON,
    FacultyFromJSONTyped,
    FacultyToJSON,
} from './Faculty';
import {
    FundamentalDomain,
    FundamentalDomainFromJSON,
    FundamentalDomainFromJSONTyped,
    FundamentalDomainToJSON,
} from './FundamentalDomain';
import {
    SimpleStudyCycleEnum,
    SimpleStudyCycleEnumFromJSON,
    SimpleStudyCycleEnumFromJSONTyped,
    SimpleStudyCycleEnumToJSON,
} from './SimpleStudyCycleEnum';
import {
    StudyProgram,
    StudyProgramFromJSON,
    StudyProgramFromJSONTyped,
    StudyProgramToJSON,
} from './StudyProgram';

/**
 * 
 * @export
 * @interface Domain
 */
export interface Domain {
    /**
     * 
     * @type {string}
     * @memberof Domain
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof Domain
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Domain
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Domain
     */
    name?: string | null;
    /**
     * 
     * @type {SimpleStudyCycleEnum}
     * @memberof Domain
     */
    studyCycle?: SimpleStudyCycleEnum;
    /**
     * 
     * @type {Array<Faculty>}
     * @memberof Domain
     */
    faculties?: Array<Faculty> | null;
    /**
     * 
     * @type {Array<StudyProgram>}
     * @memberof Domain
     */
    studyPrograms?: Array<StudyProgram> | null;
    /**
     * 
     * @type {string}
     * @memberof Domain
     */
    readonly completeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Domain
     */
    fundamentalDomainId?: string;
    /**
     * 
     * @type {FundamentalDomain}
     * @memberof Domain
     */
    fundamentalDomain?: FundamentalDomain;
}

export function DomainFromJSON(json: any): Domain {
    return DomainFromJSONTyped(json, false);
}

export function DomainFromJSONTyped(json: any, ignoreDiscriminator: boolean): Domain {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'studyCycle': !exists(json, 'studyCycle') ? undefined : SimpleStudyCycleEnumFromJSON(json['studyCycle']),
        'faculties': !exists(json, 'faculties') ? undefined : (json['faculties'] === null ? null : (json['faculties'] as Array<any>).map(FacultyFromJSON)),
        'studyPrograms': !exists(json, 'studyPrograms') ? undefined : (json['studyPrograms'] === null ? null : (json['studyPrograms'] as Array<any>).map(StudyProgramFromJSON)),
        'completeName': !exists(json, 'completeName') ? undefined : json['completeName'],
        'fundamentalDomainId': !exists(json, 'fundamentalDomainId') ? undefined : json['fundamentalDomainId'],
        'fundamentalDomain': !exists(json, 'fundamentalDomain') ? undefined : FundamentalDomainFromJSON(json['fundamentalDomain']),
    };
}

export function DomainToJSON(value?: Domain | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'name': value.name,
        'studyCycle': SimpleStudyCycleEnumToJSON(value.studyCycle),
        'faculties': value.faculties === undefined ? undefined : (value.faculties === null ? null : (value.faculties as Array<any>).map(FacultyToJSON)),
        'studyPrograms': value.studyPrograms === undefined ? undefined : (value.studyPrograms === null ? null : (value.studyPrograms as Array<any>).map(StudyProgramToJSON)),
        'fundamentalDomainId': value.fundamentalDomainId,
        'fundamentalDomain': FundamentalDomainToJSON(value.fundamentalDomain),
    };
}

