import { useState } from "react";
import { Form, Input, Select, Row, Col } from "antd";
import styles from "./LaboratoryForm.module.scss";
import Layout from "../../../Containers/Layout";
import { Redirect, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getLaboratory, updateLaboratory, addLaboratory } from "../../../Requests/laboratory-requests";
import { NOTIFICATION_TYPES, openNotification } from "../../../Components/Notifications/NotificationsUtils";
import { getUserId } from "../../../utils/utilFunctions";
import { theme } from "../../../theme";
import ConfirmationModal from "../../../Containers/ConfirmationModal";
import { getFaculties } from "../../../Requests/faculty-requests";
import { getUserById } from "../../../Requests/academic-user-requests";
import { LaboratoryTypeEnum } from "../../../Api";

const { Option } = Select;

const LaboratoryForm = (props: any) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const currentUserId = getUserId();
  const { laboratoryId } = useParams<{ laboratoryId: string }>();
  const [redirect, setRedirect] = useState(false);

  const openGetErrorNotification = (_error: any) => {
    openNotification(
      'Eroare',
      'Nu s-au putut prelua datele de pe server!',
      NOTIFICATION_TYPES.ERROR
    );
  };

  const { data: user } = useQuery(['getAcademicUser'],
    () => {
        return getUserById(currentUserId);
    },
    {
        onError: openGetErrorNotification,
    }
  );

  const { data: laboratory } = useQuery(["getLaboratory", laboratoryId], () => getLaboratory(laboratoryId), {
    onError: (err) => {
      openGetErrorNotification(err);
    },
    onSuccess: (data) => {    
      if (data) {
        form.setFieldsValue({
          number: data.number,
          area: data.area,
          volume: data.volume,
          capacity: data.capacity,
          name: data.name,
          type: data.type,
          category: data.category,
          description: data.description,
          resources: data.resources,
          facultyId: data.facultyId
        });
      }
    },
    enabled: !!laboratoryId,
    refetchOnWindowFocus: false
  });

  const { data: faculties } = useQuery(['getFaculties'],
    () => {
        return getFaculties(undefined, undefined, 1, 50);
    },
    {
        onError: openGetErrorNotification,
    }
);



  const openSaveErrorNotification = (error: any) => {
    openNotification(
      'Eroare!',
      'Nu s-a putut salva sala!',
      NOTIFICATION_TYPES.ERROR
    );
  };

  const handleCancel = () => {
    setRedirect(true);
  };

  const openSuccessNotificationAndRedirect = () => {
    setRedirect(true);
    openNotification(
      'Succes!',
      'Sala a fost salvata cu succes!',
      NOTIFICATION_TYPES.SUCCESS,
      6
    );
  };

  const handleSave = () => {
    const values = form.getFieldsValue();
    if (values.name && values.number) {
      const laboratoryData = {
        number: values.number,
        area: values.area,
        volume: values.volume,
        capacity: values.capacity,
        name: values.name,
        type: values.type,
        category: values.category,
        description: values.description,
        resources: values.resources,
        facultyId: values.facultyId
      };

      const saveAction = laboratoryId ? updateLaboratory(laboratoryId, laboratoryData) : addLaboratory(laboratoryData);

      saveAction.then(openSuccessNotificationAndRedirect).catch(openSaveErrorNotification);
    } else {
      openNotification(
        'Eroare!',
        'Vă rugăm să completați toate câmpurile obligatorii!',
        NOTIFICATION_TYPES.ERROR
      );
    }
  };

  if (redirect) {
    return <Redirect to="/laboratoare" />;
  }

  return (
    <Layout>
      <div className="container">
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="number"
                label="Număr"
                rules={[{ required: true, message: 'Vă rugăm să introduceți numărul sălii!' }]}
              >
                <Input placeholder="Numărul sălii" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="area"
                label="Suprafață"
                rules={[{ required: true, message: 'Vă rugăm să introduceți suprafața!' }]}
              >
                <Input placeholder="Suprafața sălii" type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="volume"
                label="Volum"
                rules={[{ required: true, message: 'Vă rugăm să introduceți volumul!' }]}
              >
                <Input placeholder="Volumul sălii" type="number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="capacity"
                label="Capacitate"
                rules={[{ required: true, message: 'Vă rugăm să introduceți capacitatea!' }]}
              >
                <Input placeholder="Capacitatea sălii" type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Nume"
                rules={[{ required: true, message: 'Vă rugăm să introduceți numele sălii!' }]}
              >
                <Input placeholder="Numele sălii" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="category"
                label="Categorie"
                rules={[{ required: true, message: 'Vă rugăm să selectați o categorie!' }]}
              >
                <Select placeholder="Selectați o categorie">
                  <Option value="Technological">Tehnologic</Option>
                  <Option value="Informatic">Informatic</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="Type"
                label="Tip"
                rules={[{ required: true, message: 'Vă rugăm să selectați un tip!' }]}
              >
                <Select placeholder="Selectați tipul">
                  <Option value={LaboratoryTypeEnum.Laboratory}>Laborator</Option>
                  <Option value={LaboratoryTypeEnum.Seminar}>Seminar</Option>
                  <Option value={LaboratoryTypeEnum.Curs}>Curs</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="facultyId"
                label="Facultate"
                initialValue={user?.department?.facultyId}
                rules={[{ required: true, message: 'Vă rugăm să selectați facultatea!' }]}
              >
                <Select placeholder="Selectați facultatea" disabled={true}>
                          {
                          faculties?.map((option: any, index) => (
                              <Option key={option.id}>{option.name}</Option>
                          )) ?? [] }
                </Select>
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={16}>
            
            <Col span={24}>
              <Form.Item
                name="resources"
                label="Resurse"
              >
                <Input.TextArea placeholder="Resursele sălii" rows={4} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Descriere"
              >
                <Input.TextArea placeholder="Descrierea sălii" rows={4} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
          <div className={styles.secondLine}>
              <div className={styles.firstColumn} style={{ display: "flex", width: "250px", justifyContent: "space-between " }}>
                <ConfirmationModal
                  nameButton={t('internships.addInternshipForm.cancel')}
                  modalText={t('internships.cancelMessage')}
                  titleButton={''}
                  handleFunction={handleCancel}
                  iconButton={null}
                  shapeButton={''}
                  typeButton={''}
                  disabledProp={false}
                  styleProp={{ background: theme.green, color: theme.white, marginRight: '10%', marginTop: '0%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                />
                <ConfirmationModal
                  nameButton={laboratoryId ? t('internships.addInternshipForm.updateButton') : t('internships.addInternshipForm.saveButton')}
                  modalText={t('internships.saveMessage')}
                  titleButton={''}
                  handleFunction={handleSave}
                  iconButton={null}
                  shapeButton={''}
                  typeButton={''}
                  disabledProp={false}
                  styleProp={{ background: theme.secondColor, color: theme.white, marginRight: '10%', marginTop: '0%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                />
              </div>
            </div>
        </Row>
        </Form>
      </div>
    </Layout>
  );
};

export default LaboratoryForm;