/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TeachingTypeEnum,
    TeachingTypeEnumFromJSON,
    TeachingTypeEnumFromJSONTyped,
    TeachingTypeEnumToJSON,
} from './TeachingTypeEnum';

/**
 * 
 * @export
 * @interface SubjectEvaluation
 */
export interface SubjectEvaluation {
    /**
     * 
     * @type {string}
     * @memberof SubjectEvaluation
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof SubjectEvaluation
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SubjectEvaluation
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SubjectEvaluation
     */
    subjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubjectEvaluation
     */
    evaluationCriteria?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectEvaluation
     */
    evaluationMethod?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectEvaluation
     */
    evaluationPercent?: number;
    /**
     * 
     * @type {TeachingTypeEnum}
     * @memberof SubjectEvaluation
     */
    teachingType?: TeachingTypeEnum;
}

export function SubjectEvaluationFromJSON(json: any): SubjectEvaluation {
    return SubjectEvaluationFromJSONTyped(json, false);
}

export function SubjectEvaluationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubjectEvaluation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'subjectId': !exists(json, 'subjectId') ? undefined : json['subjectId'],
        'evaluationCriteria': !exists(json, 'evaluationCriteria') ? undefined : json['evaluationCriteria'],
        'evaluationMethod': !exists(json, 'evaluationMethod') ? undefined : json['evaluationMethod'],
        'evaluationPercent': !exists(json, 'evaluationPercent') ? undefined : json['evaluationPercent'],
        'teachingType': !exists(json, 'teachingType') ? undefined : TeachingTypeEnumFromJSON(json['teachingType']),
    };
}

export function SubjectEvaluationToJSON(value?: SubjectEvaluation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'subjectId': value.subjectId,
        'evaluationCriteria': value.evaluationCriteria,
        'evaluationMethod': value.evaluationMethod,
        'evaluationPercent': value.evaluationPercent,
        'teachingType': TeachingTypeEnumToJSON(value.teachingType),
    };
}

