/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DepartmentDTO,
    DepartmentDTOFromJSON,
    DepartmentDTOFromJSONTyped,
    DepartmentDTOToJSON,
} from './DepartmentDTO';

/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    readonly name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    departmentId?: string;
    /**
     * 
     * @type {DepartmentDTO}
     * @memberof UserDTO
     */
    department?: DepartmentDTO;
}

export function UserDTOFromJSON(json: any): UserDTO {
    return UserDTOFromJSONTyped(json, false);
}

export function UserDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'department': !exists(json, 'department') ? undefined : DepartmentDTOFromJSON(json['department']),
    };
}

export function UserDTOToJSON(value?: UserDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'departmentId': value.departmentId,
        'department': DepartmentDTOToJSON(value.department),
    };
}

