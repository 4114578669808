import {  useEffect, useState } from "react";
import styles from "./StudyPrograms.module.scss";
import { Button, Dropdown, Menu, MenuProps, Modal, Pagination, Progress, Select, Steps, Table, TablePaginationConfig, Tooltip, Typography } from "antd";
import Search from 'antd/lib/input/Search';
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import Layout from "../../Containers/Layout";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DownloadOutlined, InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { Link, useHistory, useParams  } from "react-router-dom";
import { theme } from "../../theme";
import Dragger from "antd/lib/upload/Dragger";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useIsAdmin } from "../../utils/utilFunctions";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import type { ColumnsType } from 'antd/es/table';
import { deleteStudyProgram, getFilterOptions, getStudyPrograms } from "../../Requests/study-program-request";
import { getDomains } from "../../Requests/domain-requests";
import { getFaculties } from "../../Requests/faculty-requests";
import { AccreditationTypeEnum, MasterCategoryEnum, StudyCycleEnum, StudyProgramLangEnum } from "../../Api";
import * as _ from 'lodash';
import { downloadExcel } from "../../utils/downloadUtils";
import { downloadAncFile, downloadAracisFile, uploadAncFile, uploadAracisFile } from "../../Requests/file-manager-requests";
import { downloadFile } from "../../utils/downloadUtils";
import moment from "moment";
import { dateFormat } from "../../utils/constants";
import { getUniversities } from "../../Requests/university-requests";
import { importFromExcel } from "../../Requests/excel-import-requests";
const { Option } = Select;

interface PageResponse<T> {
    data?: T[] | null,
    totalCount?: number,
    page?: number,
    pageSize?: number
}

export enum ActionKeyEnum {
    EditPlan,
    Edit,
    ImportPlan,
    Delete,
    ExternalEvaluation,
    AdminBoardDecision,
    SenateDecision,
    AncUFile,
    AncDFile,
    AracisUFile,
    AracisDFile,
}

export type PageCallBack<T> = (currentPage: number, pageSize: number) => Promise<PageResponse<T> | null | undefined>;
export type ColumnType<T> = { title: string, key: any, dataIndex?: string, width?: any, render?: ((text: string, record: T, index: number) => any) | ((date: Date) => any) };

const StudyPrograms = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const isAdmin = useIsAdmin();
    const { studyCycle } = useParams<{ studyCycle: StudyCycleEnum }>();

    
    const [filters, setFilters] = useState<{searchTermName: string, searchTermDomain: string, searchTermFaculty: string, 
        searchTermUniversity: string, pageSize: number, currentPageBachelor: number, currentPageMaster : number,
        programLanguageOption: StudyProgramLangEnum, ectsOption: number, externalEvaluationOption: number,
        accreditationTypeOption: AccreditationTypeEnum, capacityOption: number, masterCategoryOption: MasterCategoryEnum,
        ancNoticeDateOption: number, ancExpirationDateOption: number, aracisExpirationDateOption: number
        }>(() => {
            const savedFilters = localStorage.getItem('studyProgramsFilters');
            return savedFilters 
            ? JSON.parse(savedFilters) 
        : {
            searchTermName: '', 
            searchTermDomain: '00000000-0000-0000-0000-000000000000', 
            searchTermFaculty: '00000000-0000-0000-0000-000000000000', 
            searchTermUniversity: '00000000-0000-0000-0000-000000000000',
            pageSize: 10,
            currentPageBachelor: 1,
            currentPageMaster: 1,
            programLanguageOption: null,
            ectsOption: -1,
            externalEvaluationOption: -1,
            accreditationTypeOption: null,
            capacityOption: -1,
            masterCategoryOption: null,
            ancNoticeDateOption: -1,
            ancExpirationDateOption: -1,
            aracisExpirationDateOption: -1,
        };
    })
    const [currentPage, setCurrentPage] = useState(() => { return studyCycle === StudyCycleEnum.Bachelor ? filters.currentPageBachelor : filters.currentPageMaster})
    
    useEffect(() => {
        localStorage.setItem('studyProgramsFilters', JSON.stringify(filters));
    }, [filters]);

    const [modalProps, setModalProps] = useState({
        isModalVisible: false,
        title: '',
        fileExtension: '',
        studyProgramId: '',
        uploadHandler: uploadAncFile,
    });

    useEffect(() => {
        localStorage.setItem('studyProgramsFilters', JSON.stringify(filters));
    }, [filters]);

    const setPageFilters = (page: number, pageSize: number) => { 
        if (studyCycle === StudyCycleEnum.Bachelor)
        {
            setFilters({...filters, currentPageBachelor : page, pageSize : pageSize})
        }
        else
        {
            setFilters({...filters, currentPageMaster : page, pageSize : pageSize})
        }

    }

    const getActions = (record: any): ItemType[] | undefined => {
        let actions : MenuProps['items'] = [
            {
                label: 'Editare program',
                key: ActionKeyEnum.Edit,
                icon:
                <Tooltip title='Editare program'>
                    <FontAwesomeIcon icon={solid('edit')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>,
            },
            {
                label: 'Import plan de învățământ',
                key: ActionKeyEnum.ImportPlan,
                icon:
                <Tooltip title='Import plan de învățământ'>
                    <FontAwesomeIcon icon={solid('file-import')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>,
            },
            {
                label: 'Editare plan de învățământ',
                key: ActionKeyEnum.EditPlan,
                icon:
                <Tooltip title='Editare plan de învățământ'>
                    <FontAwesomeIcon icon={solid('edit')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>,
            },
            {
                label: 'Ștergere program',
                key: ActionKeyEnum.Delete,
                icon:
                <Tooltip title='Ștergere program'>
                    <FontAwesomeIcon icon={solid('trash')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>,
            },
            {
                label: 'Evaluări externe',
                key: ActionKeyEnum.ExternalEvaluation,
                icon:
                <Tooltip title='Evaluări externe'>
                    <FontAwesomeIcon icon={solid('circle-check')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>,
            },
            {
                label: 'Hotărâri CA',
                key: ActionKeyEnum.AdminBoardDecision,
                icon:
                <Tooltip title='Hotărâri CA'>
                    <FontAwesomeIcon icon={solid('circle-check')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>,
            },
            {
                label: 'Hotărâri Senat',
                key: ActionKeyEnum.SenateDecision,
                icon:
                <Tooltip title='Hotărâri Senat'>
                    <FontAwesomeIcon icon={solid('circle-check')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>,
            },
            {
                label: 'Fișă ANC',
                key: ActionKeyEnum.AncUFile,
                icon:
                <Tooltip title='Încarcă fișă ANC'>
                    <FontAwesomeIcon icon={solid('upload')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>

            },
            {
                label: 'Fișă ANC',
                key: ActionKeyEnum.AncDFile,
                icon:
                <Tooltip title='Descarcă fișă ANC'>
                    <FontAwesomeIcon icon={solid('download')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>
            },
            {
                label: 'Fișă ARACIS',
                key: ActionKeyEnum.AracisUFile,
                icon:
                <Tooltip title='Încarcă fișă ARACIS'>
                    <FontAwesomeIcon icon={solid('upload')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>
            },
            {
                label: 'Fișă ARACIS',
                key: ActionKeyEnum.AracisDFile,
                icon:
                <Tooltip title='Descarcă fișă ARACIS'>
                    <FontAwesomeIcon icon={solid('download')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
                </Tooltip>
            }


        ];
        return actions;
    }
    const [modalPropsDelete, setModalPropsDelete] = useState({
        isModalVisible: false,
        title: '',
        fileExtension: '',
        studyProgramId: '',
        //uploadHandler: uploadCVs
    });

    const [modalPropsStatus, setModalPropsStatus] =
    useState<{  isModalVisible: boolean,
                title: string,
                proposal: any | undefined
            }>
    ({
        isModalVisible: false,
        title: '',
        proposal: undefined,
    });

    const handleShow = (studyProgramId: string, uploadHandler: any, fileExtension: string = 'pdf') => {
        setModalProps(modalProps => ({...modalProps, isModalVisible: true, studyProgramId, uploadHandler, fileExtension}));
    }

    const handleClose = () => {
        console.log('aocolasd');
        setModalProps(modalProps => ({...modalProps, isModalVisible: false}));
    }

    const handleOk = () => {
        console.log('aoco');
        setModalProps(modalProps => ({...modalProps, isModalVisible: false}));

    }

    const handleShowDelete = (studyProgramId: string) => {
        setModalPropsDelete(modalProps => ({...modalProps, isModalVisible: true, studyProgramId}));
    }

    const handleCloseDelete = () => {
        setModalPropsDelete(modalProps => ({...modalProps, isModalVisible: false}));
    }

    const handleOkDelete = () => {
        setModalPropsDelete(modalProps => ({...modalProps, isModalVisible: false}));
        deleteStudyProgram((modalPropsDelete.studyProgramId))
                .then(() => {
                    deleteSucceded();
                    invalidateQuery();
                })
                .catch(deleteFailed);

    }

    const handleShowStatus = (proposal: any) => {
        setModalPropsStatus(modalPropsStatus => ({...modalPropsStatus, isModalVisible: true, proposal}));
    }

    const handleCloseStatus = () => {
        setModalPropsStatus(modalPropsStatus => ({...modalPropsStatus, isModalVisible: false}));
    }

    const handleTablePageChange = (page: number, pageSize: number) => {
        setPageFilters(page, pageSize);
        setCurrentPage(page);
    };

    const uploadFailed = (_err: any) => {
        openNotification(
            'Eroare',
            'Încarcarea nu s-a realizat cu succes!',
            NOTIFICATION_TYPES.ERROR
        );
    };


    const uploadSucceded = () => {
        handleClose();
        openNotification(
            'Succes',
            'Încarcarea s-a realizat cu succes!',
            NOTIFICATION_TYPES.SUCCESS
            );
        };

    const validationSucceded = () => {
        openNotification(
            'Succes',
            'Validarea s-a realizat cu succes!',
            NOTIFICATION_TYPES.SUCCESS
        );
    };

    const validationFailed = (_err: any) => {
        openNotification(
            'Eroare',
            'Validarea nu s-a realizat cu succes!',
            NOTIFICATION_TYPES.ERROR
        );
    };

    const deleteSucceded = () => {
        openNotification(
            'Succes',
            'Ștergerea s-a realizat cu succes!',
            NOTIFICATION_TYPES.SUCCESS
        );
    };

    const deleteFailed = (_err: any) => {
        openNotification(
            'Eroare',
            'Ștergerea nu s-a realizat cu succes!',
            NOTIFICATION_TYPES.ERROR
        );
    };

    const wrongFile = () => {
        openNotification(
            'Eroare',
            'Extensia fișierului selectat nu este permisă!',
            NOTIFICATION_TYPES.ERROR
        );
    };


    const upload = async (file: Blob | null) => {
        if (file) {            
            await modalProps.uploadHandler(modalProps.studyProgramId, file).then(uploadSucceded);

        }
    }

    const queryClient = useQueryClient();

    const invalidateQuery = () => { queryClient.invalidateQueries('getStudyProgramPage') };

    const openFetchError = (_error: any) => {
        openNotification(
            'Eroare!',
            'Datele nu au putut fi aduse din server!',
            NOTIFICATION_TYPES.ERROR
        );
    }

    const { data, isLoading } = useQuery(['getStudyProgramPage', filters, filters.pageSize, filters.currentPageBachelor, filters.currentPageMaster, studyCycle],
        () => {
            console.log(filters);
            
            return getStudyPrograms({...filters, studyCycle: studyCycle, studyProgramLanguageOption: filters.programLanguageOption,
                                     universityCenterId: filters.searchTermUniversity, domainIds: [filters.searchTermDomain], facultyIds: [filters.searchTermFaculty]});
        },
        {
            onError: openFetchError,
        }
    );

    const onMenuClick = (e: any, record: any) => {
        if (e.key == ActionKeyEnum.Edit)
        {
            history.push('/' + studyCycle + '/editare-program/' + record.id);
            return;
        }
        if (e.key == ActionKeyEnum.EditPlan)
        {
            history.push('/' + studyCycle + '/plan/' + record.id);
            return;
        }
        else if (e.key == ActionKeyEnum.Delete)
        {
            handleShowDelete(record.id!);
            return;
        }
        else if (e.key == ActionKeyEnum.ExternalEvaluation)
        {
            history.push('/' + studyCycle + '/evaluari-externe/' + record.id);
            return;
        }
        else if (e.key == ActionKeyEnum.AdminBoardDecision)
        {
            history.push('/' + studyCycle + '/hotarari-ca/' + record.id);
            return;
        }
        else if (e.key == ActionKeyEnum.SenateDecision)
        {
            history.push('/' + studyCycle + '/hotarari-senat/' + record.id);
            return;
        }
        else if (e.key == ActionKeyEnum.AncUFile)
        {
            handleShow(record.id!, uploadAncFile, 'pdf');
            return;
        }
        else if (e.key == ActionKeyEnum.ImportPlan)
            {
                handleShow(record.id!, importFromExcel, 'xlsx');
                return;
            }
        else if (e.key == ActionKeyEnum.AncDFile)
        {
            downloadFile(downloadAncFile(record.id), 'Fișă ANC din ' + moment(record.ancNoticeDate).format(dateFormat));
            return;
        }
        else if (e.key == ActionKeyEnum.AracisUFile)
        {
            handleShow(record.id!, uploadAracisFile, 'pdf');
            return;
        }
        else if (e.key == ActionKeyEnum.AracisDFile)
        {
            downloadFile(downloadAracisFile(record.id), 'Fișă ARACIS din ' + moment(record.aracisNoticeDate).format(dateFormat));
            return;
        }
    };

    const FilterByNameInput = (
        <div>
            <label>Nume</label>
            <Search placeholder={t('account.name')} defaultValue={filters.searchTermName} allowClear
            onSearch={(searchTermName) => {
                handleTablePageChange(1, filters.pageSize)
                setFilters({...filters, searchTermName: searchTermName})}} style={{ width: 270 }} />
        </div>
    );

    const { data: filterOptions } = useQuery(['getOptions', studyCycle],
        () => {
            return getFilterOptions(studyCycle);
        },
        {
            onError: openFetchError,
        }
    );

    const { data: domains } = useQuery(['getDomains', studyCycle],
        () => {
            return getDomains(undefined, undefined, studyCycle, 1, 100);
        },
        {
            onError: openFetchError,
        }
    );
      
    const { data: universities } = useQuery(['getUniversities'],
        () => {
            return getUniversities();
        },
        {
            onError: openFetchError,
        }
    );

    const { data: faculties } = useQuery(['getFaculties', filters.searchTermUniversity],
        () => {
            return getFaculties(undefined, filters.searchTermUniversity, 1, 50);
        },
        {
            onError: openFetchError,
        }
    );

    const getOptions = () => {
        let options: any = [
            {
                label: 'Toate',
                value: "00000000-0000-0000-0000-000000000000"
            }
        ];

        let groups = _.groupBy(domains?.data, 'fundamentalName');
        
        for (const [key, value] of Object.entries(groups)) {
            options.push({
                label: key,
                options: value.map((option: any) => (
                    {label: option.name, value: option.id}
                ) ?? [])
            });
        }
        return options;
    }


    const FilterByDomainInput = (
        <div>
            <label>Domeniu</label>
            <Select defaultValue={filters.searchTermDomain} style={{ width: "370px" }} 
                onChange={(searchTermDomain) => {
                    handleTablePageChange(1, filters.pageSize)
                    setFilters({...filters, searchTermDomain: searchTermDomain})}}
                options={getOptions()}>
            </Select>
        </div>
    );

    const getLanguageOptions = () => {
        let options: any = [
            {
                label: 'Toate',
                value: null
            }
        ];

        let groups = filterOptions?.studyProgramLanguageOptions;
        
        if (groups != null)
        {
            for (let  value of groups) {
                options.push({
                    label: value,
                    value: value
                });
            } 
        }
        return options;
    }

    const FilterByLanguageInput = (
        <div>
            <label>Limba de predare</label>
            <Select defaultValue={filters.programLanguageOption} style={{ width: "130px" }} 
                onChange={(searchTermLanguage) => {
                    handleTablePageChange(1, filters.pageSize)
                    setFilters({...filters, programLanguageOption: searchTermLanguage})}}
                options={getLanguageOptions()}>
            </Select>
        </div>
    );

    const getEctsOptions = () => {
        let options: any = [
            {
                label: 'Toate',
                value: -1
            }
        ];

        let groups = filterOptions?.ectsOptions;
        
        if (groups != null)
        {
            for (let  value of groups) {
                options.push({
                    label: value,
                    value: value
                });
            } 
        }
        return options;
    }

    const FilterByEctsInput = (
        <div>
            <label>ECTS</label>
            <Select defaultValue={filters.ectsOption} style={{ width: "130px" }} 
                onChange={(ectsOption) => {
                    handleTablePageChange(1, filters.pageSize)
                    setFilters({...filters, ectsOption: ectsOption})}}
                options={getEctsOptions()}>
            </Select>
        </div>
    );
    
    const getExternalEvaluationOptions = () => {
        let options: any = [
            {
                label: 'Toate',
                value: -1
            }
        ];

        let groups = filterOptions?.externalEvaluationOptions;
        
        if (groups != null)
        {
            for (let  value of groups) {
                options.push({
                    label: value,
                    value: value
                });
            } 
        }
        return options;
    }

    const FilterByExternalEvaluationInput = (
        <div>
            <label>Data ultimei evaluari</label>
            <Select defaultValue={filters.externalEvaluationOption} style={{ width: "170px" }} 
                onChange={(externalEvaluationOption) => {
                    handleTablePageChange(1, filters.pageSize)
                    setFilters({...filters, externalEvaluationOption: externalEvaluationOption})}}
                options={getExternalEvaluationOptions()}>
            </Select>
        </div>
    );

    const getAccreditationTypeOptions = () => {
        let options: any = [
            {
                label: 'Toate',
                value: null
            }
        ];

        let groups = filterOptions?.accreditationTypeOptions;
        
        if (groups != null)
        {
            for (let  value of groups) {
                options.push({
                    label: value,
                    value: value
                });
            } 
        }
        return options;
    }

    const FilterByAccreditationTypeInput = (
        <div>
            <label>Acreditare</label>
            <Select defaultValue={filters.accreditationTypeOption} style={{ width: "130px" }} 
                onChange={(accreditationTypeOption) => {
                    handleTablePageChange(1, filters.pageSize)
                    setFilters({...filters, accreditationTypeOption: accreditationTypeOption})}}
                options={getAccreditationTypeOptions()}>
            </Select>
        </div>
    );


    const getCapacityOptions = () => {
        let options: any = [
            {
                label: 'Toate',
                value: -1
            }
        ];

        let groups = filterOptions?.capacityOptions;
        
        if (groups != null)
        {
            for (let  value of groups) {
                options.push({
                    label: value,
                    value: value
                });
            } 
        }
        return options;
    }

    const FilterByCapacityInput = (
        <div>
            <label>Capacitate</label>
            <Select defaultValue={filters.capacityOption} style={{ width: "130px" }} 
                onChange={(capacityOption) => {
                    handleTablePageChange(1, filters.pageSize)
                    setFilters({...filters, capacityOption: capacityOption})}}
                options={getCapacityOptions()}>
            </Select>
        </div>
    );

    const getMasterCategoryOptions = () => {
        let options: any = [
            {
                label: 'Toate',
                value: null
            }
        ];

        let groups = filterOptions?.masterCategoryOptions;
        
        if (groups != null)
        {
            for (let  value of groups) {
                options.push({
                    label: value,
                    value: value
                });
            } 
        }
        return options;
    }

    const FilterByMasterCategoryInput = (
        <div>
            <label>Categorie Masterat</label>
            <Select defaultValue={filters.masterCategoryOption} style={{ width: "130px" }} 
                onChange={(masterCategoryOption) => {
                    handleTablePageChange(1, filters.pageSize)
                    setFilters({...filters, masterCategoryOption: masterCategoryOption})}}
                options={getMasterCategoryOptions()}>
            </Select>
        </div>
    );


    const getAncNoticeDateOptions = () => {
        let options: any = [
            {
                label: 'Toate',
                value: -1
            }
        ];

        let groups = filterOptions?.ancNoticeDateOptions;
        
        if (groups != null)
        {
            for (let  value of groups) {
                options.push({
                    label: value,
                    value: value
                });
            } 
        }
        return options;
    }

    const FilterByAncNoticeDateInput = (
        <div>
            <label>Data Aviz ANC</label>
            <Select defaultValue={filters.ancNoticeDateOption} style={{ width: "130px" }} 
                onChange={(ancNoticeDateOption) => {
                    handleTablePageChange(1, filters.pageSize)
                    setFilters({...filters, ancNoticeDateOption: ancNoticeDateOption})}}
                options={getAncNoticeDateOptions()}>
            </Select>
        </div>
    );

    const getAncExpirationDateOptions = () => {
        let options: any = [
            {
                label: 'Toate',
                value: -1
            }
        ];

        let groups = filterOptions?.ancExpirationDateOptions;
        
        if (groups != null)
        {
            for (let  value of groups) {
                options.push({
                    label: value,
                    value: value
                });
            } 
        }
        return options;
    }

    const FilterByAncExpirationDateInput = (
        <div>
            <label>Data Expirare ANC</label>
            <Select defaultValue={filters.ancExpirationDateOption} style={{ width: "130px" }} 
                onChange={(ancExpirationDateOption) => {
                    handleTablePageChange(1, filters.pageSize)
                    setFilters({...filters, ancExpirationDateOption: ancExpirationDateOption})}}
                options={getAncExpirationDateOptions()}>
            </Select>
        </div>
    );

    const getarAcisExpirationDateOptions = () => {
        let options: any = [
            {
                label: 'Toate',
                value: -1
            }
        ];

        let groups = filterOptions?.aracisExpirationDateOptions;
        
        if (groups != null)
        {
            for (let  value of groups) {
                options.push({
                    label: value,
                    value: value
                });
            } 
        }
        return options;
    }

    const FilterByAracisExpirationDateInput = (
        <div>
            <label>Data Expirare ARACIS</label>
            <Select defaultValue={filters.aracisExpirationDateOption} style={{ width: "180px" }} 
                onChange={(aracisExpirationDateOption) => {
                    handleTablePageChange(1, filters.pageSize)
                    setFilters({...filters, aracisExpirationDateOption: aracisExpirationDateOption})}}
                options={getarAcisExpirationDateOptions()}>
            </Select>
        </div>
    );
    
    const FilterByFacultyInput = (
        <div>
            <label>Facultate</label>
            <Select value={filters.searchTermFaculty} style={{ width: "370px" }} 
                onChange={(searchTermFaculty) => {
                    handleTablePageChange(1, filters.pageSize)
                    setFilters({...filters, searchTermFaculty: searchTermFaculty})}}>
                { [<Option key={"00000000-0000-0000-0000-000000000000"}>{"Toate"}</Option>].concat(
                faculties?.map((option: any, index) => (
                    <Option key={option.id}>{index + (filters.searchTermUniversity === '00000000-0000-0000-0012-000000000101' ? 18 : 1)}. {option.name}</Option>
                )) ?? [] )}
            </Select>
        </div>
    );
    
    const FilterByUniversityInput = (
        <div>
            <label><br></br></label>
            <Select defaultValue={filters.searchTermUniversity} style={{ width: "80px" }} 
                    onChange={(searchTermUniversity) => {
                        handleTablePageChange(1, filters.pageSize)
                        setFilters({...filters, searchTermUniversity: searchTermUniversity, searchTermFaculty: "00000000-0000-0000-0000-000000000000"})}}>
                { [<Option key={"00000000-0000-0000-0000-000000000000"}>{"Toate"}</Option>].concat(
                universities?.map((option: any,) => (
                    <Option key={option.id}>{option.shortName}</Option>
                )) ?? [] )}
            </Select>
        </div>
    );


    const columns : ColumnsType<any> = [
        {
            title: FilterByUniversityInput,
            dataIndex: 'universityName',
            key: 'universityName',
            width: 100,
        },
        {
            title: FilterByFacultyInput,
            dataIndex: 'facultyName',
            key: 'facultyName',
            width: 400,
        },
        {
            title: FilterByDomainInput,
            dataIndex: 'domainName',
            key: 'domainName',
            render: (text: string, record: any, index: number) => {
                return text;
            },
            width: 400,
        },
        {
            title: FilterByNameInput,
            dataIndex: 'name',
            render: (text: string, record: any, index: number) => {
                return text;
            },
            width: 300,
        },
        {
            title: FilterByLanguageInput,
            key: 'language',
            dataIndex: 'language',
            width: 150,
        },
        {
            title: FilterByEctsInput,
            key: 'numberECTS',
            dataIndex: 'numberECTS',
            width: 150,
        },
        {
            title: FilterByAccreditationTypeInput,
            key: 'accreditationType',
            dataIndex: 'accreditationType',
            width: 150,
        },
        {
            title: FilterByCapacityInput,
            key: 'capacity',
            dataIndex: 'capacity',
            width: 150,
        },
        {
            title: 'Domeniu educational ISCED 2013',
            key: 'educaionalDomainISCED',
            dataIndex: 'educaionalDomainISCED',
            width: 250,
        },
        {
            title: 'Cod COR/ESCO',
            key: 'codCOR',
            dataIndex: 'codCOR',
            width: 250,
        },
        {
            title: 'Denumire ocupatie',
            key: 'ocupationName',
            dataIndex: 'ocupationName',
            width: 400,
        },
        
        studyCycle === StudyCycleEnum.Master ? {
            title: FilterByMasterCategoryInput,
            key: 'masterCategory',
            dataIndex: 'masterCategory',
            render:(value: MasterCategoryEnum) => 
            { 
                switch(value) {
                case MasterCategoryEnum.NotApplied: return "Nu se aplica"
                case MasterCategoryEnum.Professional: return "Profesional"
                case MasterCategoryEnum.Research: return "De Cercetare"
                case MasterCategoryEnum.Teaching: return "Didactic"
            } },
            width:  250,
        } : {},
        {
            title: 'Nr. Aviz ANC',
            key: 'ancNoticeNumber',
            dataIndex: 'ancNoticeNumber',
            width: 150,
        },
        {
            title: FilterByAncNoticeDateInput,
            key: 'ancNoticeDate',
            dataIndex: 'ancNoticeDate',
            render:(date: Date) => { return date?.toLocaleDateString("ro-RO") },
            width: 150,
        },
        {
            title: FilterByAncExpirationDateInput,
            key: 'ancExpireDate',
            dataIndex: 'ancExpireDate',
            render:(date: Date) => { return date?.toLocaleDateString("ro-RO") },
            width: 150,
        },
        {
            title: FilterByAracisExpirationDateInput,
            key: 'aracisExpireDate',
            dataIndex: 'aracisExpireDate',
            render:(date: Date) => { return date?.toLocaleDateString("ro-RO") },
            width: 200,
        },
        {
            title: 'Istoric Evaluari Externe',
            key: 'externalEvaluationHistory',
            dataIndex: 'externalEvaluationHistory',
            width: 150,
        },
        {
            title: FilterByExternalEvaluationInput,
            key: 'lastEvaluationDate',
            dataIndex: 'lastEvaluationDate',
            render:(date: Date) => { return date?.toLocaleDateString("ro-RO") },
            width: 190,
        },
        {
            title: 'Responsabil program',
            key: 'programSupervisor',
            dataIndex: 'programSupervisor',
            width: 250,
        },
        {
            title: 'Acțiuni',
            fixed: 'right',
            width: '80px',
            render: (text: string, record: any, index: number) =>
                <Dropdown placement="bottomLeft"
                    menu={{
                        onClick: (e) => onMenuClick(e, record),
                        items: getActions(record)
                    }}
                >
                    <FontAwesomeIcon icon={solid('bars')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px", marginLeft: "13px"}}/>
                </Dropdown>
        }
    ];


    const reportColumns: ColumnType<any>[] = [
        {
            title: 'Facultatea',
            key: 'facultyName',
        },
        {
            title: 'Domeniu',
            key: 'domainName',
        },
        {
            title: 'Specializarea/Program de studii universitare de licenţă  şi limba de predare',
            key: 'name',
        },
        {
            title: 'Nr. ECTS',
            key: 'numberECTS',
        },
        {
            title: 'Acreditare (A)/ Autorizare de funcționare provizorie (AP)',
            key: 'accreditationType',
        },
        {
            title: 'Capacitatea de școlarizare',
            key: 'capacity',
        },
        {
            title: 'Domeniu educational ISCED 2013',
            key: 'educaionalDomainISCED',
        },
        {
            title: 'Cod COR/ESCO',
            key: 'codCOR',
        },
        {
            title: 'Denumire ocupatie',
            key: 'ocupationName',
        },
        studyCycle === StudyCycleEnum.Master ? {
            title: 'Categorie Masterat',
            key: 'masterCategoryDescription',
        } : {title: '',
        key: '',},
        {
            title: 'Nr. Aviz ANC',
            key: 'ancNoticeNumber',
            // render:(text: string, record: any, index: number) => {
            //     return record.ancNoticeNumber + "/" + record.ancNoticeDate?.toLocaleDateString("ro-RO");
            // }
        },
        {
            title: 'Data Aviz ANC',
            key: 'ancNoticeDate',
            // render:(date: Date) => { return date?.toLocaleDateString("ro-RO") }
        },
        {
            title: 'Data Expirare ANC',
            key: 'ancExpireDate',
            // render:(date: Date) => { return date?.toLocaleDateString("ro-RO") }
        },
        {
            title: 'Istoric Evaluari Externe',
            key: 'externalEvaluationHistory',
        },
        {
            title: 'Data ultimei evaluari',
            key: 'lastEvaluationDate',
            render:(date: Date) => { return date?.toLocaleDateString("ro-RO") }
        },
        {
            title: 'Data Aviz ARACIS',
            key: 'aracisNoticeDate',
            render:(date: Date) => { return date?.toLocaleDateString("ro-RO") }
        },
        {
            title: 'Data Expirare ARACIS',
            key: 'aracisExpireDate',
            render:(date: Date) => { return date?.toLocaleDateString("ro-RO") }
        },
        
        
        {
            title: 'Responsabil program',
            key: 'programSupervisor',
            dataIndex: 'programSupervisor',
        },
    ];

    const [progress, setProgress] = useState(0);
    const [downloading, setDownloading] = useState(false);
    const [downloadError, setDownloadError] = useState(false);

    const openErrorNotification = (error: string, message: string) => {
        openNotification(
            error,
            message,
            NOTIFICATION_TYPES.ERROR
        );
    }

    async function downloadAll<T>(getCall: PageCallBack<T>, setProgress: (progress: number) => void, onError: () => void) {
        let data = new Array<T>();
        let hasData = true;
        let pageIndex = 1;
        setProgress(0);

        do {
            const page = await getCall(pageIndex, 100).catch(onError);

            ++pageIndex;

            if (page?.data === undefined || page?.data === null) {
                onError();

                return;
            }

            data = data.concat(page.data);

            if ((pageIndex - 1) * 100 >= (page.totalCount ?? 0)) {
                hasData = false;
            }

            setProgress(hasData ? (Math.round((pageIndex - 1) * 100 / (page.totalCount ?? 1) * 100)) : 100)
        } while (hasData);

        return data;
    }

    const downloadReport = async () => {
        setDownloading(true);
        let facultySearch = [filters.searchTermFaculty];
        let domainSearch = [filters.searchTermDomain];
        let fileName = 'Raport Evaluări - ' + (studyCycle === StudyCycleEnum.Bachelor ? 'Licență - ' : 'Masterat - ') + (new Date()).toLocaleDateString("ro-RO");
        return downloadExcel(fileName, reportColumns.map(e => { return { header: e.title, key: e.key?.toString() ?? '' } }),
            await downloadAll((page, size) => getStudyPrograms({...filters, studyCycle: studyCycle, page: page, pageSize: size,
                universityCenterId: filters.searchTermUniversity, domainIds: [filters.searchTermDomain], facultyIds: [filters.searchTermFaculty]}), (value) => {
                setDownloadError(false);
                setProgress(value)
            }, () => {
                setDownloadError(true);
                openErrorNotification("Eroare!", "Raportul nu a putut fi descărcat cu succes!")
            }));
    }

    return <div className={styles.container} style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}>
        <Layout>
            <Modal
                open={modalPropsDelete.isModalVisible}
                onOk={handleOkDelete}
                onCancel={handleCloseDelete}
                title="Stergere"
                width={"50%"}
                okText={t('account.confirm')}
                cancelText={t('account.cancel')}
                cancelButtonProps={{ style: { background: theme.green, border: theme.green, color: theme.white, borderRadius: "10px" } }}
                okButtonProps={{ style: { background: theme.secondColor, border: theme.secondColor, borderRadius: "10px" } }}
                >
                    <div>
                        <Typography>Sigur doriti stergerea propunerii?</Typography>
                    </div>
            </Modal>
            <Modal
                open={modalProps.isModalVisible}
                closable={false}
                onOk={handleOk}
                onCancel={handleClose}
                title="Încărcare fișier"
                width={"50%"}
                cancelText='Anulare'
                cancelButtonProps={{ style: { background: theme.green, border: theme.green, color: theme.white, borderRadius: "10px" } }}
                okButtonProps={{ style: { display: 'none' } }}
                >
                    <div>
                    <Dragger
                        multiple={false}
                        beforeUpload={(uploaded) => {
                            if (uploaded.name.includes(modalProps.fileExtension)) {
                                upload(uploaded).then(invalidateQuery).catch(uploadFailed);
                            }
                            else {
                                wrongFile();
                            }
                            return false;
                        }}
                        showUploadList={false}
                    >
                        <p className="ant-upload-drag-icon" >
                            <InboxOutlined style={{color: theme.green}}/>
                        </p>
                        <p className="ant-upload-text">Dă click aici sau trage fișierul pe care vrei să îl încarci în acest chenar</p>
                        <p className="ant-upload-hint">
                            Extensii acceptate: {modalProps.fileExtension}
                        </p>
                    </Dragger>
                    </div>
            </Modal>
            {downloading && <div onClick={() => setDownloading(false)} style={{cursor: "pointer"}}>
                <Progress percent={progress} status={downloadError ? "exception" : undefined} />
            </div>}
            <div>
                <Pagination 
                    style={{maxWidth: "950px", marginLeft: "auto", marginRight: "0", marginBottom: "16px", marginTop: "16px"}}
                    total={data?.totalCount}
                    current={currentPage ?? 1}
                    pageSize={filters.pageSize ?? 10}
                    pageSizeOptions={["10", "20", "50"]}
                    defaultPageSize={10}
                    showSizeChanger={true}
                    onChange={handleTablePageChange}
                    showTotal={(total: number, range: [number, number]) => {
                        return <div>
                                {isAdmin && <Tooltip title="Descărcare raport">
                                    <Button type="primary" icon={<DownloadOutlined />} style={{marginRight: 10}} onClick={downloadReport}>
                                        Descărcare raport
                                    </Button>
                                </Tooltip>}
                                <Tooltip title="Adaugă program">
                                    <Link to={{ pathname: '/' + studyCycle + '/adaugare-program'}} >
                                        <Button type="primary" icon={<PlusOutlined />}>
                                            Adaugă program
                                        </Button>
                                    </Link>
                                </Tooltip>
                            </div>
                    }}
                    hideOnSinglePage={ false}
                    locale={{ items_per_page: t('configuration.pagination')}}
                >
            </Pagination>
            <Table
                className={styles.usersTable}
                dataSource={data?.data || []}
                scroll={{ x: 1500, y: 'calc(100vh - 380px)'  }}
                locale={{
                    emptyText: (
                        <span>
                            <h3>Nu există programe adăugate! Apasă butonul de mai jos pentru a adăuga un program nou!</h3>
                            <Tooltip title="Adaugă program">
                                <Link to={{ pathname: '/' + studyCycle + '/adaugare-program'}} >
                                    <Button type="primary" icon={<PlusOutlined />}>
                                        Adaugă program
                                    </Button>
                                </Link>
                            </Tooltip>
                        </span>
                      )
                }}
                pagination={false}
                columns={columns}
                rowKey={record => record.id ?? ''}
                loading={isLoading}
                sticky={true}
            />
            </div>

        </Layout>
    </div>
}

export default StudyPrograms;
