/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IndicatorTypeEnum,
    IndicatorTypeEnumFromJSON,
    IndicatorTypeEnumFromJSONTyped,
    IndicatorTypeEnumToJSON,
} from './IndicatorTypeEnum';
import {
    IndicatorValueTypeEnum,
    IndicatorValueTypeEnumFromJSON,
    IndicatorValueTypeEnumFromJSONTyped,
    IndicatorValueTypeEnumToJSON,
} from './IndicatorValueTypeEnum';

/**
 * 
 * @export
 * @interface IndicatorStats
 */
export interface IndicatorStats {
    /**
     * 
     * @type {IndicatorTypeEnum}
     * @memberof IndicatorStats
     */
    indicatorType?: IndicatorTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof IndicatorStats
     */
    weeklyHours?: number;
    /**
     * 
     * @type {number}
     * @memberof IndicatorStats
     */
    readonly hours?: number;
    /**
     * 
     * @type {number}
     * @memberof IndicatorStats
     */
    indicatorValue?: number;
    /**
     * 
     * @type {number}
     * @memberof IndicatorStats
     */
    indicatorCriteria?: number;
    /**
     * 
     * @type {IndicatorValueTypeEnum}
     * @memberof IndicatorStats
     */
    indicatorValueType?: IndicatorValueTypeEnum;
}

export function IndicatorStatsFromJSON(json: any): IndicatorStats {
    return IndicatorStatsFromJSONTyped(json, false);
}

export function IndicatorStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndicatorStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'indicatorType': !exists(json, 'indicatorType') ? undefined : IndicatorTypeEnumFromJSON(json['indicatorType']),
        'weeklyHours': !exists(json, 'weeklyHours') ? undefined : json['weeklyHours'],
        'hours': !exists(json, 'hours') ? undefined : json['hours'],
        'indicatorValue': !exists(json, 'indicatorValue') ? undefined : json['indicatorValue'],
        'indicatorCriteria': !exists(json, 'indicatorCriteria') ? undefined : json['indicatorCriteria'],
        'indicatorValueType': !exists(json, 'indicatorValueType') ? undefined : IndicatorValueTypeEnumFromJSON(json['indicatorValueType']),
    };
}

export function IndicatorStatsToJSON(value?: IndicatorStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'indicatorType': IndicatorTypeEnumToJSON(value.indicatorType),
        'weeklyHours': value.weeklyHours,
        'indicatorValue': value.indicatorValue,
        'indicatorCriteria': value.indicatorCriteria,
        'indicatorValueType': IndicatorValueTypeEnumToJSON(value.indicatorValueType),
    };
}

