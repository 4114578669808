/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TeachingTypeEnum {
    Course = 'Course',
    Lab = 'Lab'
}

export function TeachingTypeEnumFromJSON(json: any): TeachingTypeEnum {
    return TeachingTypeEnumFromJSONTyped(json, false);
}

export function TeachingTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeachingTypeEnum {
    return json as TeachingTypeEnum;
}

export function TeachingTypeEnumToJSON(value?: TeachingTypeEnum | null): any {
    return value as any;
}

