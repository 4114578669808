import { IndicatorApi, IndicatorDTO, IndicatorTypeEnum, IndicatorAddDTO } from "../Api";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new IndicatorApi(getAuthConfiguration());

export const getIndicator = (id: string) => {
    if (!id) {
        return {} as IndicatorDTO;
    }
    return ApiFactory().apiIndicatorGetIndicatorByIdIdGet({ id });
}

export const getIndicators = (fundamentalDomainId?: string, indicatorType?: IndicatorTypeEnum, page: number = 1, pageSize: number = 10) => {
    return ApiFactory().apiIndicatorGetAllIndicatorsGet({ page, pageSize, fundamentalDomainId, indicatorType });
}

export const getIndicatorsStats = (studyProgramId: string, planYear: number) => {
    return ApiFactory().apiIndicatorGetStatsForPlanStudyProgramIdPlanYearGet({ studyProgramId, planYear });
}

export const addIndicator = (indicatorAddDTO: IndicatorAddDTO) => {
    return ApiFactory().apiIndicatorAddIndicatorPost({ indicatorAddDTO });
}

export const updateIndicator = (id: string, indicatorAddDTO: IndicatorAddDTO) => { 
    return ApiFactory().apiIndicatorUpdateIndicatorIdPut({ id, indicatorAddDTO });
}

export const deleteIndicator = (id: string) => {
    return ApiFactory().apiIndicatorDeleteIdDelete({ id });
}