/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GeneralStats
 */
export interface GeneralStats {
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    examPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    examVerificationRatio?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    averageSemesterWeeklyHours?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    projectSubjectsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    labSubjectsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    totalCourseHours?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    totalSeminarLabProjectHours?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    totalSeminarLabProjectPracticeHours?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    examsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    verificationCount?: number;
}

export function GeneralStatsFromJSON(json: any): GeneralStats {
    return GeneralStatsFromJSONTyped(json, false);
}

export function GeneralStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneralStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'examPercentage': !exists(json, 'examPercentage') ? undefined : json['examPercentage'],
        'examVerificationRatio': !exists(json, 'examVerificationRatio') ? undefined : json['examVerificationRatio'],
        'averageSemesterWeeklyHours': !exists(json, 'averageSemesterWeeklyHours') ? undefined : json['averageSemesterWeeklyHours'],
        'projectSubjectsCount': !exists(json, 'projectSubjectsCount') ? undefined : json['projectSubjectsCount'],
        'labSubjectsCount': !exists(json, 'labSubjectsCount') ? undefined : json['labSubjectsCount'],
        'totalCourseHours': !exists(json, 'totalCourseHours') ? undefined : json['totalCourseHours'],
        'totalSeminarLabProjectHours': !exists(json, 'totalSeminarLabProjectHours') ? undefined : json['totalSeminarLabProjectHours'],
        'totalSeminarLabProjectPracticeHours': !exists(json, 'totalSeminarLabProjectPracticeHours') ? undefined : json['totalSeminarLabProjectPracticeHours'],
        'examsCount': !exists(json, 'examsCount') ? undefined : json['examsCount'],
        'verificationCount': !exists(json, 'verificationCount') ? undefined : json['verificationCount'],
    };
}

export function GeneralStatsToJSON(value?: GeneralStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'examPercentage': value.examPercentage,
        'examVerificationRatio': value.examVerificationRatio,
        'averageSemesterWeeklyHours': value.averageSemesterWeeklyHours,
        'projectSubjectsCount': value.projectSubjectsCount,
        'labSubjectsCount': value.labSubjectsCount,
        'totalCourseHours': value.totalCourseHours,
        'totalSeminarLabProjectHours': value.totalSeminarLabProjectHours,
        'totalSeminarLabProjectPracticeHours': value.totalSeminarLabProjectPracticeHours,
        'examsCount': value.examsCount,
        'verificationCount': value.verificationCount,
    };
}

