import { Tabs } from 'antd';
import FundamentalDomains from './FundamentalDomains';
import styles from "./Domains.module.scss";
import Layout from "../../Containers/Layout";
import Domains from './Domains';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

const { TabPane } = Tabs;

const TabsComponent = () => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.state !== undefined && location.state !== null ? (location.state as any).activeTab : "1");

    console.log(activeTab);
    

    return <div className={styles.container} style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}>
        <Layout>
            <Tabs 
                defaultActiveKey='1'
                activeKey={activeTab}
                onChange={(activeTab) => setActiveTab(activeTab)}>
                <TabPane tab="Domenii de studiu" key="1">
                    <Domains />
                </TabPane>
                <TabPane tab="Domenii fundamentale" key="2">
                    <FundamentalDomains />
                </TabPane>
            </Tabs>
        </Layout>
    </div>
}

export default TabsComponent;
