/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ApiExcelImportImportExcelPostRequest {
    programId?: string;
    departmentId?: string;
    file?: Blob;
}

export interface ApiExcelImportImportExcelUIPostRequest {
    programId?: string;
    file?: Blob;
}

/**
 * 
 */
export class ExcelImportApi extends runtime.BaseAPI {

    /**
     */
    async apiExcelImportImportExcelPostRaw(requestParameters: ApiExcelImportImportExcelPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.programId !== undefined) {
            queryParameters['programId'] = requestParameters.programId;
        }

        if (requestParameters.departmentId !== undefined) {
            queryParameters['departmentId'] = requestParameters.departmentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/ExcelImport/ImportExcel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiExcelImportImportExcelPost(requestParameters: ApiExcelImportImportExcelPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiExcelImportImportExcelPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiExcelImportImportExcelUIPostRaw(requestParameters: ApiExcelImportImportExcelUIPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.programId !== undefined) {
            queryParameters['programId'] = requestParameters.programId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/ExcelImport/ImportExcelUI`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiExcelImportImportExcelUIPost(requestParameters: ApiExcelImportImportExcelUIPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiExcelImportImportExcelUIPostRaw(requestParameters, initOverrides);
    }

}
