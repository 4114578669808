/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IndicatorAddDTO,
    IndicatorAddDTOFromJSON,
    IndicatorAddDTOToJSON,
    IndicatorDTOPagedResponse,
    IndicatorDTOPagedResponseFromJSON,
    IndicatorDTOPagedResponseToJSON,
    IndicatorTypeEnum,
    IndicatorTypeEnumFromJSON,
    IndicatorTypeEnumToJSON,
    Stats,
    StatsFromJSON,
    StatsToJSON,
} from '../models';

export interface ApiIndicatorAddIndicatorPostRequest {
    indicatorAddDTO?: IndicatorAddDTO;
}

export interface ApiIndicatorDeleteIdDeleteRequest {
    id: string;
}

export interface ApiIndicatorGetAllIndicatorsGetRequest {
    fundamentalDomainId?: string;
    indicatorType?: IndicatorTypeEnum;
    page?: number;
    pageSize?: number;
}

export interface ApiIndicatorGetIndicatorByIdIdGetRequest {
    id: string;
}

export interface ApiIndicatorGetStatsForPlanStudyProgramIdPlanYearGetRequest {
    studyProgramId: string;
    planYear: number;
}

export interface ApiIndicatorUpdateIndicatorIdPutRequest {
    id: string;
    indicatorAddDTO?: IndicatorAddDTO;
}

/**
 * 
 */
export class IndicatorApi extends runtime.BaseAPI {

    /**
     */
    async apiIndicatorAddIndicatorPostRaw(requestParameters: ApiIndicatorAddIndicatorPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Indicator/AddIndicator`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IndicatorAddDTOToJSON(requestParameters.indicatorAddDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiIndicatorAddIndicatorPost(requestParameters: ApiIndicatorAddIndicatorPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiIndicatorAddIndicatorPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiIndicatorDeleteIdDeleteRaw(requestParameters: ApiIndicatorDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiIndicatorDeleteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Indicator/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiIndicatorDeleteIdDelete(requestParameters: ApiIndicatorDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiIndicatorDeleteIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiIndicatorGetAllIndicatorsGetRaw(requestParameters: ApiIndicatorGetAllIndicatorsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IndicatorDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.fundamentalDomainId !== undefined) {
            queryParameters['FundamentalDomainId'] = requestParameters.fundamentalDomainId;
        }

        if (requestParameters.indicatorType !== undefined) {
            queryParameters['IndicatorType'] = requestParameters.indicatorType;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Indicator/GetAllIndicators`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IndicatorDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiIndicatorGetAllIndicatorsGet(requestParameters: ApiIndicatorGetAllIndicatorsGetRequest = {}, initOverrides?: RequestInit): Promise<IndicatorDTOPagedResponse> {
        const response = await this.apiIndicatorGetAllIndicatorsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiIndicatorGetIndicatorByIdIdGetRaw(requestParameters: ApiIndicatorGetIndicatorByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IndicatorAddDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiIndicatorGetIndicatorByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Indicator/GetIndicatorById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IndicatorAddDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiIndicatorGetIndicatorByIdIdGet(requestParameters: ApiIndicatorGetIndicatorByIdIdGetRequest, initOverrides?: RequestInit): Promise<IndicatorAddDTO> {
        const response = await this.apiIndicatorGetIndicatorByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiIndicatorGetStatsForPlanStudyProgramIdPlanYearGetRaw(requestParameters: ApiIndicatorGetStatsForPlanStudyProgramIdPlanYearGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Stats>> {
        if (requestParameters.studyProgramId === null || requestParameters.studyProgramId === undefined) {
            throw new runtime.RequiredError('studyProgramId','Required parameter requestParameters.studyProgramId was null or undefined when calling apiIndicatorGetStatsForPlanStudyProgramIdPlanYearGet.');
        }

        if (requestParameters.planYear === null || requestParameters.planYear === undefined) {
            throw new runtime.RequiredError('planYear','Required parameter requestParameters.planYear was null or undefined when calling apiIndicatorGetStatsForPlanStudyProgramIdPlanYearGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Indicator/GetStatsForPlan/{studyProgramId}/{planYear}`.replace(`{${"studyProgramId"}}`, encodeURIComponent(String(requestParameters.studyProgramId))).replace(`{${"planYear"}}`, encodeURIComponent(String(requestParameters.planYear))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatsFromJSON(jsonValue));
    }

    /**
     */
    async apiIndicatorGetStatsForPlanStudyProgramIdPlanYearGet(requestParameters: ApiIndicatorGetStatsForPlanStudyProgramIdPlanYearGetRequest, initOverrides?: RequestInit): Promise<Stats> {
        const response = await this.apiIndicatorGetStatsForPlanStudyProgramIdPlanYearGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiIndicatorUpdateIndicatorIdPutRaw(requestParameters: ApiIndicatorUpdateIndicatorIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiIndicatorUpdateIndicatorIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Indicator/UpdateIndicator/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IndicatorAddDTOToJSON(requestParameters.indicatorAddDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiIndicatorUpdateIndicatorIdPut(requestParameters: ApiIndicatorUpdateIndicatorIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiIndicatorUpdateIndicatorIdPutRaw(requestParameters, initOverrides);
    }

}
