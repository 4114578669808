import { LaboratoryApi, LaboratoryAddDTO, LaboratoryDTO } from "../Api";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new LaboratoryApi(getAuthConfiguration());

export const getLaboratory = (id: string) => {
    if (!id) {
        return {} as LaboratoryDTO;
    }
    return ApiFactory().apiLaboratoryGetLaboratoryByIdIdGet({ id });
}

export const getLaboratories = (facultyIds?: Array<string>, namePattern?: string, number?: string, page: number = 1, pageSize: number = 10, ) => {
    return ApiFactory().apiLaboratoryGetAlLaboratoriesGet({ facultyIds, page, pageSize, namePattern, number });
}

export const getLaboratoriesByUserFaculty = (facultyIds?: Array<string>, namePattern?: string, number?: string, page: number = 1, pageSize: number = 10, ) => {
    return ApiFactory().apiLaboratoryGetAlLaboratoriesByUserFacultyGet({ facultyIds, page, pageSize, namePattern, number });
}

export const addLaboratory = (laboratoryAddDTO: LaboratoryAddDTO) => {
    return ApiFactory().apiLaboratoryAddLaboratoryPost({ laboratoryAddDTO });
}

export const updateLaboratory = (id: string, laboratoryAddDTO: LaboratoryAddDTO) => { 
    return ApiFactory().apiLaboratoryUpdateLaboratoryIdPut({ id, laboratoryAddDTO });
}

export const deleteLaboratory = (id: string) => {
    return ApiFactory().apiLaboratoryDeleteIdDelete({ id });
}