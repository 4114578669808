/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DisciplineStats,
    DisciplineStatsFromJSON,
    DisciplineStatsFromJSONTyped,
    DisciplineStatsToJSON,
} from './DisciplineStats';
import {
    GeneralStats,
    GeneralStatsFromJSON,
    GeneralStatsFromJSONTyped,
    GeneralStatsToJSON,
} from './GeneralStats';
import {
    IndicatorStats,
    IndicatorStatsFromJSON,
    IndicatorStatsFromJSONTyped,
    IndicatorStatsToJSON,
} from './IndicatorStats';
import {
    YearSubjectStats,
    YearSubjectStatsFromJSON,
    YearSubjectStatsFromJSONTyped,
    YearSubjectStatsToJSON,
} from './YearSubjectStats';

/**
 * 
 * @export
 * @interface Stats
 */
export interface Stats {
    /**
     * 
     * @type {Array<YearSubjectStats>}
     * @memberof Stats
     */
    yearSubjectStats?: Array<YearSubjectStats> | null;
    /**
     * 
     * @type {Array<IndicatorStats>}
     * @memberof Stats
     */
    indicatorStats?: Array<IndicatorStats> | null;
    /**
     * 
     * @type {GeneralStats}
     * @memberof Stats
     */
    generalStats?: GeneralStats;
    /**
     * 
     * @type {Array<DisciplineStats>}
     * @memberof Stats
     */
    disciplineStats?: Array<DisciplineStats> | null;
}

export function StatsFromJSON(json: any): Stats {
    return StatsFromJSONTyped(json, false);
}

export function StatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Stats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'yearSubjectStats': !exists(json, 'yearSubjectStats') ? undefined : (json['yearSubjectStats'] === null ? null : (json['yearSubjectStats'] as Array<any>).map(YearSubjectStatsFromJSON)),
        'indicatorStats': !exists(json, 'indicatorStats') ? undefined : (json['indicatorStats'] === null ? null : (json['indicatorStats'] as Array<any>).map(IndicatorStatsFromJSON)),
        'generalStats': !exists(json, 'generalStats') ? undefined : GeneralStatsFromJSON(json['generalStats']),
        'disciplineStats': !exists(json, 'disciplineStats') ? undefined : (json['disciplineStats'] === null ? null : (json['disciplineStats'] as Array<any>).map(DisciplineStatsFromJSON)),
    };
}

export function StatsToJSON(value?: Stats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'yearSubjectStats': value.yearSubjectStats === undefined ? undefined : (value.yearSubjectStats === null ? null : (value.yearSubjectStats as Array<any>).map(YearSubjectStatsToJSON)),
        'indicatorStats': value.indicatorStats === undefined ? undefined : (value.indicatorStats === null ? null : (value.indicatorStats as Array<any>).map(IndicatorStatsToJSON)),
        'generalStats': GeneralStatsToJSON(value.generalStats),
        'disciplineStats': value.disciplineStats === undefined ? undefined : (value.disciplineStats === null ? null : (value.disciplineStats as Array<any>).map(DisciplineStatsToJSON)),
    };
}

