/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StudyPlanDTO,
    StudyPlanDTOFromJSON,
    StudyPlanDTOToJSON,
    StudyPlanDTOPagedResponse,
    StudyPlanDTOPagedResponseFromJSON,
    StudyPlanDTOPagedResponseToJSON,
} from '../models';

export interface ApiStudyPlanGetAllStudyPlansStudyProgramIdGetRequest {
    studyProgramId: string;
    page?: number;
    pageSize?: number;
}

export interface ApiStudyPlanGetStudyPlanByIdStudyPlanIdGetRequest {
    studyPlanId: string;
}

export interface ApiStudyPlanGetStudyPlanByProgramIdAndYearStudyProgramIdPlanYearGetRequest {
    studyProgramId: string;
    planYear: number;
}

export interface ApiStudyPlanUpdateStudyPlanDepartmentStudyPlanIdDepartmentIdPutRequest {
    studyPlanId: string;
    departmentId: string;
}

export interface ApiStudyPlanUpdateStudyPlanStatusStudyPlanIdDepartmentIdPutRequest {
    studyPlanId: string;
    departmentId: string;
}

/**
 * 
 */
export class StudyPlanApi extends runtime.BaseAPI {

    /**
     */
    async apiStudyPlanGetAllStudyPlansStudyProgramIdGetRaw(requestParameters: ApiStudyPlanGetAllStudyPlansStudyProgramIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StudyPlanDTOPagedResponse>> {
        if (requestParameters.studyProgramId === null || requestParameters.studyProgramId === undefined) {
            throw new runtime.RequiredError('studyProgramId','Required parameter requestParameters.studyProgramId was null or undefined when calling apiStudyPlanGetAllStudyPlansStudyProgramIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/StudyPlan/GetAllStudyPlans/{studyProgramId}`.replace(`{${"studyProgramId"}}`, encodeURIComponent(String(requestParameters.studyProgramId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudyPlanDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiStudyPlanGetAllStudyPlansStudyProgramIdGet(requestParameters: ApiStudyPlanGetAllStudyPlansStudyProgramIdGetRequest, initOverrides?: RequestInit): Promise<StudyPlanDTOPagedResponse> {
        const response = await this.apiStudyPlanGetAllStudyPlansStudyProgramIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStudyPlanGetStudyPlanByIdStudyPlanIdGetRaw(requestParameters: ApiStudyPlanGetStudyPlanByIdStudyPlanIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StudyPlanDTO>> {
        if (requestParameters.studyPlanId === null || requestParameters.studyPlanId === undefined) {
            throw new runtime.RequiredError('studyPlanId','Required parameter requestParameters.studyPlanId was null or undefined when calling apiStudyPlanGetStudyPlanByIdStudyPlanIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/StudyPlan/GetStudyPlanById/{studyPlanId}`.replace(`{${"studyPlanId"}}`, encodeURIComponent(String(requestParameters.studyPlanId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudyPlanDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiStudyPlanGetStudyPlanByIdStudyPlanIdGet(requestParameters: ApiStudyPlanGetStudyPlanByIdStudyPlanIdGetRequest, initOverrides?: RequestInit): Promise<StudyPlanDTO> {
        const response = await this.apiStudyPlanGetStudyPlanByIdStudyPlanIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStudyPlanGetStudyPlanByProgramIdAndYearStudyProgramIdPlanYearGetRaw(requestParameters: ApiStudyPlanGetStudyPlanByProgramIdAndYearStudyProgramIdPlanYearGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StudyPlanDTO>> {
        if (requestParameters.studyProgramId === null || requestParameters.studyProgramId === undefined) {
            throw new runtime.RequiredError('studyProgramId','Required parameter requestParameters.studyProgramId was null or undefined when calling apiStudyPlanGetStudyPlanByProgramIdAndYearStudyProgramIdPlanYearGet.');
        }

        if (requestParameters.planYear === null || requestParameters.planYear === undefined) {
            throw new runtime.RequiredError('planYear','Required parameter requestParameters.planYear was null or undefined when calling apiStudyPlanGetStudyPlanByProgramIdAndYearStudyProgramIdPlanYearGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/StudyPlan/GetStudyPlanByProgramIdAndYear/{studyProgramId}/{planYear}`.replace(`{${"studyProgramId"}}`, encodeURIComponent(String(requestParameters.studyProgramId))).replace(`{${"planYear"}}`, encodeURIComponent(String(requestParameters.planYear))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudyPlanDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiStudyPlanGetStudyPlanByProgramIdAndYearStudyProgramIdPlanYearGet(requestParameters: ApiStudyPlanGetStudyPlanByProgramIdAndYearStudyProgramIdPlanYearGetRequest, initOverrides?: RequestInit): Promise<StudyPlanDTO> {
        const response = await this.apiStudyPlanGetStudyPlanByProgramIdAndYearStudyProgramIdPlanYearGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStudyPlanUpdateStudyPlanDepartmentStudyPlanIdDepartmentIdPutRaw(requestParameters: ApiStudyPlanUpdateStudyPlanDepartmentStudyPlanIdDepartmentIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.studyPlanId === null || requestParameters.studyPlanId === undefined) {
            throw new runtime.RequiredError('studyPlanId','Required parameter requestParameters.studyPlanId was null or undefined when calling apiStudyPlanUpdateStudyPlanDepartmentStudyPlanIdDepartmentIdPut.');
        }

        if (requestParameters.departmentId === null || requestParameters.departmentId === undefined) {
            throw new runtime.RequiredError('departmentId','Required parameter requestParameters.departmentId was null or undefined when calling apiStudyPlanUpdateStudyPlanDepartmentStudyPlanIdDepartmentIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/StudyPlan/UpdateStudyPlanDepartment/{studyPlanId}/{departmentId}`.replace(`{${"studyPlanId"}}`, encodeURIComponent(String(requestParameters.studyPlanId))).replace(`{${"departmentId"}}`, encodeURIComponent(String(requestParameters.departmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiStudyPlanUpdateStudyPlanDepartmentStudyPlanIdDepartmentIdPut(requestParameters: ApiStudyPlanUpdateStudyPlanDepartmentStudyPlanIdDepartmentIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiStudyPlanUpdateStudyPlanDepartmentStudyPlanIdDepartmentIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiStudyPlanUpdateStudyPlanStatusStudyPlanIdDepartmentIdPutRaw(requestParameters: ApiStudyPlanUpdateStudyPlanStatusStudyPlanIdDepartmentIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.studyPlanId === null || requestParameters.studyPlanId === undefined) {
            throw new runtime.RequiredError('studyPlanId','Required parameter requestParameters.studyPlanId was null or undefined when calling apiStudyPlanUpdateStudyPlanStatusStudyPlanIdDepartmentIdPut.');
        }

        if (requestParameters.departmentId === null || requestParameters.departmentId === undefined) {
            throw new runtime.RequiredError('departmentId','Required parameter requestParameters.departmentId was null or undefined when calling apiStudyPlanUpdateStudyPlanStatusStudyPlanIdDepartmentIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/StudyPlan/UpdateStudyPlanStatus/{studyPlanId}/{departmentId}`.replace(`{${"studyPlanId"}}`, encodeURIComponent(String(requestParameters.studyPlanId))).replace(`{${"departmentId"}}`, encodeURIComponent(String(requestParameters.departmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiStudyPlanUpdateStudyPlanStatusStudyPlanIdDepartmentIdPut(requestParameters: ApiStudyPlanUpdateStudyPlanStatusStudyPlanIdDepartmentIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiStudyPlanUpdateStudyPlanStatusStudyPlanIdDepartmentIdPutRaw(requestParameters, initOverrides);
    }

}
