import {Link, useLocation} from "react-router-dom";
import {theme} from "../../theme";
import {
    isUserLogged,
    useIsAdmin,
} from '../../utils/utilFunctions';
import {useTranslation} from "react-i18next";
import { StudyCycleEnum } from "../../Api";

const HeadersLinks = () => {
    const {t} = useTranslation();
    const location = useLocation();

    return (
        <>
            <Link
                to='/'
                style={{
                    fontWeight: location.pathname === "/" ? "bold" : "normal",
                    color: window.innerWidth > 1215 ? (location.pathname === "/" ? theme.primaryColor : "black") : theme.secondColor,
                    fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                    padding: window.innerWidth > 1215 ? "0" : "5px",
                }}
            >
                {t('header.home')}
            </Link>
            {isUserLogged() ?
                (<Link
                        to={'/' + StudyCycleEnum.Bachelor + "/programe"}
                        style={{
                            fontWeight: location.pathname === ('/' + StudyCycleEnum.Bachelor + "/programe") ? "bold" : "normal",
                            color: window.innerWidth > 1215 ? (location.pathname === ('/' + StudyCycleEnum.Bachelor + "/programe") ? theme.primaryColor : "black") : theme.secondColor,
                            fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                            padding: window.innerWidth > 1215 ? "0" : "5px",
                        }}
                    >
                        Programe Licență
                </Link>)
            : null}

            {isUserLogged() ?
                (<Link
                        to={'/' + StudyCycleEnum.Master + "/programe"}
                        style={{
                            fontWeight: location.pathname === ('/' + StudyCycleEnum.Master + "/programe") ? "bold" : "normal",
                            color: window.innerWidth > 1215 ? (location.pathname === ('/' + StudyCycleEnum.Master + "/programe") ? theme.primaryColor : "black") : theme.secondColor,
                            fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                            padding: window.innerWidth > 1215 ? "0" : "5px",
                        }}
                    >
                        Programe Master
                </Link>)
            : null} 

            {isUserLogged() ?
                (<Link
                        to="/domenii"
                        style={{
                            fontWeight: location.pathname === "/domenii" ? "bold" : "normal",
                            color: window.innerWidth > 1215 ? (location.pathname === "/domenii" ? theme.primaryColor : "black") : theme.secondColor,
                            fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                            padding: window.innerWidth > 1215 ? "0" : "5px",
                        }}
                    >
                        Domenii
                </Link>)
            : null}

{isUserLogged() ?
                (<Link
                        to="/laboratoare"
                        style={{
                            fontWeight: location.pathname === "/laboratoare" ? "bold" : "normal",
                            color: window.innerWidth > 1215 ? (location.pathname === "/laboratoare" ? theme.primaryColor : "black") : theme.secondColor,
                            fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                            padding: window.innerWidth > 1215 ? "0" : "5px",
                        }}
                    >
                        Săli
                </Link>)
            : null}
                    
        </>
    );

};

export default HeadersLinks;