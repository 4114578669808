import { useState } from "react";
import { Form, Input, Select, Row, Col } from "antd";
import Layout from "../../../Containers/Layout";
import { Link, Redirect, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getShortSubject, updateShortSubject, addShortSubject } from "../../../Requests/subject-requests";
import { NOTIFICATION_TYPES, openNotification } from "../../Notifications/NotificationsUtils";
import { getUserId } from "../../../utils/utilFunctions";
import { theme } from "../../../theme";
import ConfirmationModal from "../../../Containers/ConfirmationModal";
import { AddSubjectDTO, EvaluationTypeEnum, IndicatorTypeEnum, SemesterTypeEnum, StudyCycleEnum, StudyYearEnum } from "../../../Api";

import styles from "./SubjectShortForm.module.scss";
import { getStudyProgram } from "../../../Requests/study-program-request";
import useDebounce from "../../../Hooks/debounce";
import { getDepartmentsForFaculty } from "../../../Requests/faculty-requests";

const { Option } = Select;

const SubjectShortForm = (props: any) => {
  const { t } = useTranslation();
  const currentUserId = getUserId();
  const { subjectId } = useParams<{ subjectId: string }>();
  const [redirect, setRedirect] = useState(false);
  const { programId, studyCycle } = useParams<{ programId: string, studyCycle: StudyCycleEnum }>();

  const [searchTermNameCourse, setCourseUserSearchTerm] = useState('');
	const debouncedCourseUserSearch = useDebounce(searchTermNameCourse, 1000);

  const [searchTermNameLab, setLabUserSearchTerm] = useState('');
	const debouncedLabUserSearch = useDebounce(searchTermNameLab, 1000);
  
  const [form] = Form.useForm<{
    nameRO: string | null;
    nameEN: string | null;
    studyProgramId: string; 
    departmentId: string; 
    evaluationType: EvaluationTypeEnum;
    formativeCategory: IndicatorTypeEnum;
    subjectCategory: IndicatorTypeEnum;
    code: string | null;
    courseWeeklyHours: number;
    labWeeklyHours: number;
    seminarWeeklyHours: number;
    projectWeeklyHours: number;
    selfStudyHours: number;
    tutoringHours: number;
    credits?: number;
    planYear?: number;
    studyYear?: StudyYearEnum;
    semester?: SemesterTypeEnum;

  }>();

  const openGetErrorNotification = (_error: any) => {
    openNotification(
      'Eroare',
      'Nu s-au putut prelua datele de pe server!',
      NOTIFICATION_TYPES.ERROR
    );
  };
  
  const { data: program } = useQuery(
    ["getProgram", programId], 
    () => getStudyProgram(programId)
  );
  
  const { data: departments } = useQuery(
    ['getDepartments'],
    () => {
      const faculties = program?.facultyId ? [program.facultyId] : [];
      return getDepartmentsForFaculty(faculties);
    },
    {
      enabled: !!program?.facultyId,
      onError: openGetErrorNotification,
    }
  );

  const { data: subject } = useQuery(["getSubject", subjectId], () => getShortSubject(subjectId), {
    onError: (err) => {
      openGetErrorNotification(err);
    },
    onSuccess: (data) => {
      if (data) {
        form.setFieldsValue({
          nameRO: data.nameRO,
          nameEN: data.nameEN,
          studyProgramId: data.studyProgramId,
          departmentId: data.departmentId,
          evaluationType: data.evaluationType,
          formativeCategory: data.formativeCategory,
          subjectCategory: data.subjectCategory,
          code: data.code,
          courseWeeklyHours: data.courseWeeklyHours,
          labWeeklyHours: data.labWeeklyHours,
          seminarWeeklyHours: data.courseWeeklyHours,
          projectWeeklyHours: data.labWeeklyHours,
          credits: data.credits,
          planYear: data.planYear,
          studyYear: data.studyYear,
          semester: data.semester,
        });
      }
    },
    enabled: !!subjectId,
    refetchOnWindowFocus: false
  });

  const openSaveErrorNotification = (error: any) => {
    openNotification(
      'Eroare!',
      'Nu s-a putut salva disciplina!',
      NOTIFICATION_TYPES.ERROR
    );
  };

  const handleCancel = () => {
    setRedirect(true);
  };

  const currentYear = new Date().getFullYear();
  const yearRange = Array.from({ length: 4 }, (_, i) => currentYear - i);

  const openSuccessNotificationAndRedirect = () => {
    setRedirect(true);
    openNotification(
      'Succes!',
      'Disciplina a fost salvată cu succes!',
      NOTIFICATION_TYPES.SUCCESS,
      6
    );
  };

  const handleSave = () => {
    const values = form.getFieldsValue();
    console.log(values);
    
    if (values.nameRO) {
      const subjectData: AddSubjectDTO = {
        nameRO: values.nameRO,
        nameEN: values.nameEN,
        studyProgramId: program?.id,
        departmentId: values.departmentId,
        evaluationType: values.evaluationType,
        formativeCategory: values.formativeCategory,
        subjectCategory: values.subjectCategory,
        code: values.code,
        courseWeeklyHours: values.courseWeeklyHours,
        labWeeklyHours: values.labWeeklyHours,
        seminarWeeklyHours: values.courseWeeklyHours,
        projectWeeklyHours: values.labWeeklyHours,
        credits: values.credits, 
        planYear: values.planYear, 
        studyYear: values.studyYear,
        semester: values.semester,
      };
      
      const saveAction = subjectId ? updateShortSubject(subjectId, subjectData) : addShortSubject(subjectData);

      saveAction.then(openSuccessNotificationAndRedirect).catch(openSaveErrorNotification);
    } else {
      openNotification(
        'Eroare!',
        'Vă rugăm să completați toate câmpurile obligatorii!',
        NOTIFICATION_TYPES.ERROR
      );
    }
  };

  if (redirect) {
    return <Redirect to={{ pathname: '/' + studyCycle + '/plan/' + program?.id}}></Redirect>;
  }

  return (
    <Layout>
      <div className="container">
        <Form form={form} layout="vertical">
          <Row gutter={16}><Col span={24}><span style={{ fontSize: '28px', fontWeight: 'bold' }}>FIȘA DISCIPLINEI</span></Col></Row>
          <Row gutter={16}><Col span={24}><span style={{ fontSize: '16px', fontWeight: 'bold', display: 'flex' }}>1. Date despre program</span></Col></Row>
          <div style={{
            border: "1px solid lightgrey", 
            borderRadius: "5px", 
            overflow: "hidden",
          }}>
            
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={10} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>Instituția de învățământ superior</span>
              </Col>
              <Col span={14} style={{ padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>Universitatea Națională de Știință și Tehnologie Politehnica București</span>
              </Col>
            </Row>
            
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={10} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>Facultatea</span>
              </Col>
              <Col span={14} style={{ padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>{program?.faculty?.name}</span>
              </Col>
            </Row>

            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={10} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>Departamentul</span>
              </Col>
              <Col span={14} style={{ padding: "8px 16px" }}>
                <Form.Item
                name="departmentId"
              >
                <Select
                            className={styles.antSelect}
                            maxTagCount="responsive"
                            allowClear
                            showSearch
                            style={{ width: "100%", textAlign: "left" }}
                            placeholder={"Department"}
                            filterOption={false}
                            
                        >
                            {departments?.map((department) => (
                                    <Option key={department.id} value={department.id}>
                                        {department.departmentNameRo}
                                    </Option>
                                ))
                                }
                        </Select>
                
              </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={10} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>Domeniul de studii</span>
              </Col>
              <Col span={14} style={{ padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>{program?.domainName}</span>
              </Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={10} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>Ciclul de studii</span>
              </Col>
              <Col span={14} style={{ padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>{ studyCycle === StudyCycleEnum.Bachelor ? "Licență" : "Master"}</span>
              </Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={10} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>Anul planului de invatamant</span>
              </Col>
              <Col span={14} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="planYear"
              >
                <Select style={{ width: "100%" }} placeholder="Selectați anul planului de invatamant">
                    {yearRange.map((year) => (
                    <Option key={year} value={year}>
                      {year} - {year + 1}
                    </Option>
                    ))}
                </Select>
              </Form.Item>
              </Col>
            </Row>
          </div>


          <Row gutter={16}><Col span={24}><span style={{ fontSize: '16px', fontWeight: 'bold', display: 'flex', marginTop: "15px" }}>2. Date despre disciplină</span></Col></Row>
          <div style={{
            border: "1px solid lightgrey", 
            borderRadius: "5px", 
            overflow: "hidden",
          }}>
            
            <Row gutter={16}   style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={10} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>Denumirea disciplinei (ro)(en)</span>
              </Col>
              <Col span={14} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="nameRO"
              >
                <Input placeholder="Numele disciplinei RO" />
                
              </Form.Item>

              <Form.Item
                name="nameEN"
              >
                <Input placeholder="Numele disciplinei EN" />
              </Form.Item>
              </Col>
            </Row>
            
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={4} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>Anul de studiu</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="studyYear"
              >
                <Select style={{ width: "100%" }} placeholder="Selectați anul de studii">
                  {studyCycle === "Bachelor" && (
                    <>
                      <Option value="L1">L1</Option>
                      <Option value="L2">L2</Option>
                      <Option value="L3">L3</Option>
                      <Option value="L4">L4</Option>
                    </>
                  )}
                  
                  {studyCycle === "Master" && (
                    <>
                      <Option value="M1">M1</Option>
                      <Option value="M2">M2</Option>
                    </>
                  )} 
                </Select>
              </Form.Item>
              </Col>

              <Col span={4} style={{ borderRight: "1px solid lightgrey", borderLeft: "1px solid lightgrey",  padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>Semestrul</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="semester"
              >
                <Select style={{width: "100%"}} placeholder="Selectați semestrul">
                  <Option value="S1">Semestrul 1</Option>
                  <Option value="S2">Semestrul 2</Option>
                </Select>
              </Form.Item>
              </Col>

              <Col span={4} style={{ borderRight: "1px solid lightgrey", borderLeft: "1px solid lightgrey",  padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>Tipul de evaluare</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="evaluationType"
              >
                <Select style={{width: "100%"}} placeholder="Selectați tipul de evaluare">
                  <Option value="E">Examen</Option>
                  <Option value="V">Verificare</Option>
                </Select>
              </Form.Item>
              </Col>
              
              
            </Row>

            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>

              <Col span={4} style={{ borderRight: "1px solid lightgrey", borderLeft: "1px solid lightgrey",  padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>Regimul disciplinei</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="subjectCategory"
              >
                <Select style={{ width: "100%" }} placeholder="Selectați regimul disciplinei">
                  <Option value="Mandatory">Obligatorie</Option>
                  <Option value="Optional">Opțională</Option>
                  <Option value="Facultative">Facultativă</Option>
                </Select>
              </Form.Item>
              </Col>

              <Col span={4} style={{ borderRight: "1px solid lightgrey", borderLeft: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>Tipul disciplinei</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="formativeCategory"
              >
                <Select style={{ width: "100%" }} placeholder="Selectați tipul disciplinei">
                  <Option value="Fundamental">Fundamental</Option>
                  <Option value="Specialty">Specialitate</Option>
                  <Option value="Domain">Domeniu</Option>
                  <Option value="Complementary">Complementară</Option>
                </Select>
              </Form.Item>
              </Col>

              
              <Col span={4} style={{ borderRight: "1px solid lightgrey", borderLeft: "1px solid lightgrey",  padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>Codul disciplinei</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="code"
              >
                <Input/>
              </Form.Item>
              </Col>

              
            </Row>
          </div>

          <Row gutter={16}><Col span={24}><span style={{ fontSize: '16px', fontWeight: 'bold', display: 'flex', marginTop: "15px" }}>3. Timpul total estimat (ore pe semestru al activităților didactice)</span></Col></Row>
          <div style={{
            border: "1px solid lightgrey", 
            borderRadius: "5px", 
            overflow: "hidden",
          }}>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              

              <Col span={3} style={{ borderRight: "1px solid lightgrey", borderLeft: "1px solid lightgrey",  padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>Număr de ore curs</span>
              </Col>
              <Col span={3} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="courseWeeklyHours"
              >
                <Input/>
              </Form.Item>
              </Col>

              <Col span={3} style={{ borderRight: "1px solid lightgrey", borderLeft: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>Număr de ore laborator</span>
              </Col>
              <Col span={3} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="labWeeklyHours"
              >
                <Input/>
              </Form.Item>
              </Col>

              <Col span={3} style={{ borderRight: "1px solid lightgrey", borderLeft: "1px solid lightgrey",  padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>Număr de ore seminar</span>
              </Col>
              <Col span={3} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="seminarWeeklyHours"
              >
                <Input/>
              </Form.Item>
              
              </Col>

              <Col span={3} style={{ borderRight: "1px solid lightgrey", borderLeft: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>Număr de ore proiect</span>
              </Col>
              <Col span={3} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="projectWeeklyHours"
              >
                <Input/>
              </Form.Item>
              </Col>
            </Row>

            
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={21} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>Numărul de credite</span>
              </Col>
              <Col span={3} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="credits"
              >
                <Input/>
                </Form.Item>
              </Col>
            </Row>
          </div>
          
           <div className={styles.secondLine} style={{ marginTop: "10px" }}>
              <div className={styles.firstColumn} style={{ display: "flex", width: "250px", justifyContent: "space-between " }}>
                <ConfirmationModal
                  nameButton={t('internships.addInternshipForm.cancel')}
                  modalText={t('internships.cancelMessage')}
                  titleButton={''}
                  handleFunction={handleCancel}
                  iconButton={null}
                  shapeButton={''}
                  typeButton={''}
                  disabledProp={false}
                  styleProp={{ background: theme.green, color: theme.white, marginRight: '10%', marginTop: '0%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                />
                <ConfirmationModal
                  nameButton={subjectId ? t('internships.addInternshipForm.updateButton') : t('internships.addInternshipForm.saveButton')}
                  modalText={t('internships.saveMessage')}
                  titleButton={''}
                  handleFunction={handleSave}
                  iconButton={null}
                  shapeButton={''}
                  typeButton={''}
                  disabledProp={false}
                  styleProp={{ background: theme.secondColor, color: theme.white, marginRight: '10%', marginTop: '0%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                />
              </div>
            </div>                    
        </Form>
      </div>
    </Layout>
  );
};

export default SubjectShortForm;