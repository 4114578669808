/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignLaboratoresDTO
 */
export interface AssignLaboratoresDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignLaboratoresDTO
     */
    laboratoryIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AssignLaboratoresDTO
     */
    subjectId?: string;
}

export function AssignLaboratoresDTOFromJSON(json: any): AssignLaboratoresDTO {
    return AssignLaboratoresDTOFromJSONTyped(json, false);
}

export function AssignLaboratoresDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignLaboratoresDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'laboratoryIds': !exists(json, 'laboratoryIds') ? undefined : json['laboratoryIds'],
        'subjectId': !exists(json, 'subjectId') ? undefined : json['subjectId'],
    };
}

export function AssignLaboratoresDTOToJSON(value?: AssignLaboratoresDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'laboratoryIds': value.laboratoryIds,
        'subjectId': value.subjectId,
    };
}

